import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiArrowRight, mdiFilter } from '@mdi/js';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Grid, { GridItem } from '../../components/layout/Grid';
import CampoRadio from '../../components/Formulario/CampoRadio';
import Subtitulo from '../../components/tipografia/Subtitulo';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import CampoData from '../../components/Formulario/CampoData';
import Botao from '../../components/Botao';
import AlertBox from '../../components/layout/AlertBox';
import VBox from '../../components/layout/VBox';
import axios from 'axios';
import CampoMultiSeletor from '@/components/Formulario/CampoMultiSeletor';
import CampoArquivo from '@/components/Formulario/CampoArquivo';
import HBox from '@/components/layout/HBox';
import CampoCheckbox from '@/components/Formulario/CampoCheckbox';

type CanalOptions = {
  label: string;
  value: string;
  placeholder?: string;
  elem: ({ value, onChange }: any) => JSX.Element;
};

type Props = {
  onCompleted?: () => void;
  onError?: () => void;
};

export const FormCampanha = ({ onCompleted, onError }: Props) => {
  const [step, setStep] = useState(0);
  const [filter, setFilter] = useState<any>(null);
  const [canGoNextStep, setCanGoNextStep] = useState(false);
  const [canGoPreviousStep, setCanGoPreviousStep] = useState(true);

  const queryCanais = useQuery(
    ['canal'],
    async () => {
      const res = await fetch('/api/canal/box');
      return res.json();
    },
    {
      enabled: step === 0,
    },
  );

  const queryMsgProgramadas = useQuery(
    ['mensagem_programada'],
    async () => {
      const res = await fetch('/api/mensagem_programada/box');
      return res.json();
    },
    {
      enabled: step === 1,
    },
  );

  const msgPrgOptions =
    queryMsgProgramadas.data?.result?.map((it: any) => ({
      id: it.id,
      label: it.nome,
    })) ?? [];

  const [origem, setOrigem] = useState('cliente_grupo');
  const [msgProgramadaId, setMsgProgramadaId] = useState<string | null>(null);
  const [campoCanalClienteId, setCampoCanalClienteId] = useState<string | null>(
    null,
  );
  const [associacoes, setAssociacoes] = useState<
    Record<string, { tipo: 'fixo' | 'campo'; valor: string }>
  >({});
  const [arquivo, setArquivo] = useState<[string, string]>(); // [nome, conteudo]
  const [analiseArquivo, setAnaliseArquivo] = useState({
    campos: [],
    amostras: [],
    qtdContatos: 0,
  });

  const [camposOptions, setCamposOptions] = useState<
    { id: string; label: string }[]
  >([]);

  const msgProgramada = queryMsgProgramadas.data?.result?.find(
    (msg: any) => msg.id?.toString() == msgProgramadaId?.toString(),
  );
  const msgProgramadaParams = [];
  let m = null;
  const regexTpl = /{{[^}]+?}}/g;
  while ((m = regexTpl.exec(msgProgramada?.conteudo))) {
    msgProgramadaParams.push(m[0].replace(/[{}]/g, ''));
  }
  //const msgProgramadaParams = msgProgramada?.conteudo?.replace(/{{[^}]+}}/g, (match) => match.replace(/[{}]/g, ''))?.split(',') ?? []

  const qClienteGrupos = useQuery(
    'cliente_grupo/box',
    async () => {
      const res = await axios.get('/api/cliente_grupo/box');
      return res.data;
    },
    {
      enabled: origem === 'cliente_grupo',
    },
  );
  const gruposOptions =
    qClienteGrupos.data?.result?.map((it: any) => ({
      id: it.id,
      label: `${it.nome} (${it.total_clientes} contatos)`,
    })) ?? [];

  const qClientes = useQuery(
    'cliente/box',
    async () => {
      const res = await axios.get('/api/cliente/box');
      return res.data;
    },
    {
      enabled: origem === 'cliente',
    },
  );
  const clientesOptions =
    qClientes.data?.result?.map((it: any) => ({
      id: it.id,
      label: it.nome,
    })) ?? [];

  const qCustomize = useQuery('cliente/meta', async () => {
    const res = await axios.get('/api/cliente/meta');
    return res.data.result;
  });

  const analiseMutator = useMutation(
    (data: any) => {
      const fd = new FormData();
      fd.append('file', data.file, data.file.name);

      return fetch('/api/campanha_mensagem/analise', {
        method: 'POST',
        body: fd,
      }).then(res => res.json());
    },
    {
      onSuccess: (data, vars) => {
        setArquivo([vars.file.name, vars.file]);
        setAnaliseArquivo(data);
      },
      onSettled: () => {
        console.log('onSettled');
        analiseMutator.reset();
      },
    },
  );

  const executeMutator = useMutation(
    (data: any) => {
      const fd = new FormData();
      fd.append('associacoes', JSON.stringify(data.associacoes));
      fd.append('mensagem_programada_id', data.mensagem_programada_id);
      fd.append('campo_canal_cliente_id', data.campo_canal_cliente_id);
      fd.append('origem', data.origem);
      fd.append('grupo_id', data.grupo_id);
      fd.append('cliente_id', data.cliente_id);
      if (data.file) fd.append('file', data.file, data.file.name);

      return axios.post('/api/campanha_mensagem/execute', fd).then(res => res.data);
    },
    {
      onSuccess: (data, vars) => {
        onCompleted?.();
      },
      onError: (err, vars) => {
        onError?.();
      }
    },
  );

  const handleFileChange = (files: FileList | null) => {
    analiseMutator.mutate({ file: files?.[0] });
  };

  const handleExecute = async () => {
    if (executeMutator.isLoading) {
      return;
    }

    console.log('executar campanha');
    executeMutator.mutateAsync({
      associacoes,
      mensagem_programada_id: msgProgramadaId,
      campo_canal_cliente_id: campoCanalClienteId,
      origem,
      grupo_id: filter?.grupo_id,
      cliente_id: filter?.cliente_id,
      file: arquivo?.[1],
    });
    setStep(3);
  };

  useEffect(() => {
    if (step == 0) {
      setCanGoNextStep(
        (origem === 'cliente' && filter?.cliente_id?.length > 0) ||
        (origem === 'cliente_grupo' && filter?.grupo_id?.length > 0) ||
        (origem === 'upload' && analiseArquivo?.qtdContatos > 0),
      );
    } else if (step == 1) {
      setCanGoNextStep(
        msgProgramadaId != null &&
        msgProgramadaId.toString().length > 0 &&
        (msgProgramadaParams.length === 0 ||
          (associacoes &&
            Object.values(associacoes).length > 0 &&
            Object.values(associacoes).every(
              it => it != null && it.valor.length > 0,
            ))) &&
        ((origem === 'upload' &&
          campoCanalClienteId != null &&
          campoCanalClienteId.toString().length > 0) ||
          origem !== 'upload'),
      );
    }
  }, [
    step,
    analiseArquivo?.qtdContatos,
    msgProgramadaId,
    campoCanalClienteId,
    origem,
    filter,
    associacoes,
    msgProgramadaParams,
  ]);

  useEffect(() => {
    if (origem === 'upload')
      setCamposOptions(
        analiseArquivo?.campos?.map(campo => ({
          id: campo,
          label: campo,
        })),
      );
    if (['cliente', 'cliente_grupo'].includes(origem))
      setCamposOptions([
        { id: 'nome', label: 'Nome do cliente' },
        { id: 'contatos.valor', label: 'Contato' },
        ...(qCustomize.data?.map((it: any) => ({
          id: it.nome,
          label: it.rotulo,
        })) ?? []),
        { id: '[[fixo]]', label: '(Texto fixo)' },
      ]);
  }, [origem, filter, analiseArquivo, qCustomize.data]);

  return (
    <>
      <Grid>
        <GridItem sm={12} md={4}>
          <div
            style={{
              padding: '1rem 2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom:
                step >= 0 ? '2px solid #8CFF8C' : '2px solid #103668',
              color: step >= 0 ? '#333' : '#CCC',
            }}
          >
            1. Origem
          </div>
        </GridItem>

        <GridItem sm={12} md={4}>
          <div
            style={{
              padding: '1rem 2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom:
                step >= 1 ? '2px solid #8CFF8C' : '2px solid #103668',
              color: step >= 1 ? '#333' : '#CCC',
            }}
          >
            2. Mensagem
          </div>
        </GridItem>

        <GridItem sm={12} md={4}>
          <div
            style={{
              padding: '1rem 2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom:
                step >= 2 ? '2px solid #8CFF8C' : '2px solid #103668',
              color: step >= 2 ? '#333' : '#CCC',
            }}
          >
            3. Revisão
          </div>
        </GridItem>
      </Grid>

      {step === 0 && (
        <Grid>
          <GridItem sm={12}>
            <VBox>
              <label>De onde virão os dados?</label>
              <CampoRadio
                id="origem-1"
                name="origem"
                label="De um grupo de clientes cadastrados na Terachat"
                checked={origem == 'cliente_grupo'}
                onChange={() => setOrigem('cliente_grupo')}
              />
              <CampoRadio
                id="origem-3"
                name="origem"
                label="De um arquivo que farei upload"
                checked={origem == 'upload'}
                onChange={() => setOrigem('upload')}
              />
              <CampoRadio
                id="origem-2"
                name="origem"
                label="De clientes específicos, cadastrados na Terachat"
                checked={origem == 'cliente'}
                onChange={() => setOrigem('cliente')}
              />
            </VBox>
          </GridItem>

          {origem == 'cliente_grupo' && (
            <GridItem sm={12}>
              <VBox>
                <Subtitulo>Grupos de clientes</Subtitulo>
                <CampoMultiSeletor
                  instanceId="grupo_id"
                  value={filter?.grupo_id ?? ''}
                  onChange={v => setFilter({ ...filter, grupo_id: v })}
                  options={gruposOptions}
                  placeholder="Selecione..."
                />
              </VBox>
            </GridItem>
          )}

          {origem == 'cliente' && (
            <GridItem sm={12}>
              <VBox>
                <Subtitulo>Clientes</Subtitulo>
                <CampoMultiSeletor
                  instanceId="cliente_id"
                  value={filter?.cliente_id ?? ''}
                  onChange={v => setFilter({ ...filter, cliente_id: v })}
                  options={clientesOptions}
                  placeholder="Selecione..."
                />
              </VBox>
            </GridItem>
          )}

          {origem == 'upload' && (
            <GridItem sm={12}>
              <VBox>
                <Subtitulo>Upload de arquivo</Subtitulo>

                <CampoArquivo
                  instanceId='upload_file'
                  onChange={handleFileChange}
                  disabled={analiseMutator.isLoading}
                  accept=".csv"
                  style={{ width: '100%' }}
                />

                {analiseMutator.isLoading && (
                  <div>Aguarde. Validando arquivo...</div>
                )}

                {!analiseMutator.isLoading && analiseArquivo && (
                  <div>
                    <div>
                      Contatos encontrados: {analiseArquivo.qtdContatos}
                    </div>
                  </div>
                )}

                <AlertBox variant="info" style={{ width: '550px' }}>
                  <p>
                    Informe um arquivo CSV com os contatos e as informações
                    relevantes para preencher a mensagem a ser enviada.
                  </p>
                  <p>
                    O arquivo CSV deve ter, no mínimo, o contato; Por exemplo, o
                    número de telefone.
                  </p>
                </AlertBox>
              </VBox>
            </GridItem>
          )}
        </Grid>
      )}

      {step === 1 && (
        <Grid>
          <GridItem sm={12}>
            <VBox>
              <label>Qual mensagem será enviada?</label>
              <CampoSeletor
                instanceId='mensagem_programada_id'
                value={msgProgramadaId ?? undefined}
                onChange={setMsgProgramadaId}
                options={msgPrgOptions}
                placeholder="Selecione uma mensagem"
              />

              <div
                style={{
                  fontSize: '1.2rem',
                  color: '#103668',
                  textAlign: 'center',
                  padding: '1rem',
                  border: '1px solid #103668',
                  borderRadius: '0.5rem',
                  backgroundColor: 'var(--tc-color-gray-100)',
                }}
              >
                {msgProgramadaId?.toString()?.length ? (
                  msgProgramada?.conteudo
                ) : (
                  <span style={{ color: 'gray', fontStyle: 'italic' }}>
                    Nenhuma mensagem selecionada.
                  </span>
                )}
              </div>

              <hr />

              {msgProgramadaId?.toString()?.length ? (
                msgProgramadaParams?.length > 0 && camposOptions.length > 0 ? (
                  <div>
                    <div>
                      <p>
                        Esta mensagem possui trechos que podem ser alterados
                        conforme os dados dos clientes.
                      </p>
                      <p>
                        Altere as associações conforme desejar para alterar a
                        mensagem final.
                      </p>
                    </div>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        {origem === 'upload' && (
                          <tr>
                            <td style={{ width: '50%' }}>
                              <b>Telefone</b>
                            </td>
                            <td style={{ width: '50%' }}>
                              <CampoSeletor
                                instanceId='canal_cliente_id'
                                options={camposOptions}
                                value={campoCanalClienteId ?? ''}
                                onChange={setCampoCanalClienteId}
                              />
                            </td>
                          </tr>
                        )}
                        {msgProgramadaParams?.map((param: string, idx) => (
                          <tr key={idx}>
                            <td style={{ width: '50%' }}>
                              <b>{param}</b>
                            </td>
                            <td style={{ width: '50%' }}>
                              {associacoes[param]?.tipo === 'fixo' ? (
                                <HBox>
                                  <div style={{ width: 'auto' }}>
                                    <CampoCheckbox
                                      title="Desmarque para associar com um campo"
                                      text=""
                                      value={
                                        associacoes[param]?.tipo === 'fixo'
                                      }
                                      onChange={v =>
                                        setAssociacoes({
                                          ...associacoes,
                                          [param]: { tipo: 'campo', valor: '' },
                                        })
                                      }
                                    />
                                  </div>
                                  <div style={{ width: '100%' }}>
                                    <CampoTexto
                                      placeholder="Digite aqui o texto..."
                                      value={associacoes[param]?.valor ?? ''}
                                      onChange={v =>
                                        setAssociacoes({
                                          ...associacoes,
                                          [param]: { tipo: 'fixo', valor: v },
                                        })
                                      }
                                    />
                                  </div>
                                </HBox>
                              ) : (
                                <CampoSeletor
                                  options={camposOptions}
                                  value={associacoes[param]?.valor ?? ''}
                                  onChange={v => {
                                    if (v === '[[fixo]]') {
                                      setAssociacoes({
                                        ...associacoes,
                                        [param]: { tipo: 'fixo', valor: '' },
                                      });
                                    } else {
                                      setAssociacoes({
                                        ...associacoes,
                                        [param]: { tipo: 'campo', valor: v as string },
                                      });
                                    }
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>
                    {origem === 'upload' && (
                      <>
                        <p>
                          De quais campos do seu arquivo CSV virão as
                          informações a seguir?
                        </p>
                        <table style={{ width: '100%' }}>
                          <thead>
                            <tr>
                              <th>Info. p/ notificação</th>
                              <th>Campo informação</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ width: '50%' }}>
                                <b>Telefone</b>
                              </td>
                              <td style={{ width: '50%' }}>
                                <CampoSeletor
                                  instanceId='canal_cliente_id'
                                  options={camposOptions}
                                  value={campoCanalClienteId ?? ''}
                                  onChange={setCampoCanalClienteId}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                )
              ) : null}

              {msgProgramadaParams?.length > 0 &&
                analiseArquivo.amostras.length > 0 && (
                  <div>
                    <p>Amostras de mensagens que serão enviadas:</p>

                    {analiseArquivo?.amostras?.map((amostra, idx) => {
                      if (!amostra) return null;
                      const msg = msgProgramada?.conteudo?.replace(
                        /{{[^}]+}}/g,
                        (match: any) => {
                          const param = match.replace(/[{}]/g, '');
                          if (associacoes[param]?.tipo === 'fixo')
                            return associacoes[param]?.valor;
                          return amostra?.[associacoes[param]?.valor];
                        },
                      );

                      return (
                        <div
                          key={idx}
                          style={{
                            fontSize: '1.2rem',
                            color: '#103668',
                            textAlign: 'center',
                            padding: '0.5rem 1rem',
                            backgroundColor: '#0001',
                            margin: '0.25rem',
                          }}
                        >
                          {msg}
                        </div>
                      );
                    })}
                  </div>
                )}
            </VBox>
          </GridItem>
        </Grid>
      )}

      {step == 2 && (
        <div>
          <Subtitulo>Revisão</Subtitulo>

          <p style={{ width: '100%', textAlign: 'center' }}>
            As notificações ocorrerão por este canal:
          </p>

          <div
            style={{
              fontSize: '1.2rem',
              color: '#103668',
              textAlign: 'center',
              padding: '1rem',
            }}
          >
            <p data-testid="preview-nome-canal">{msgProgramada.canal?.nome}</p>
          </div>

          <p style={{ width: '100%', textAlign: 'center' }}>
            A notificação tem o seguinte padrão:
          </p>

          <div
            style={{
              fontSize: '1.2rem',
              color: '#103668',
              textAlign: 'center',
              padding: '1rem',
              border: '1px solid #103668',
              borderRadius: '0.5rem',
              backgroundColor: 'var(--tc-color-gray-100)',
            }}
            data-testid="preview-mensagem"
          >
            {msgProgramada?.conteudo}
          </div>

          <p style={{ width: '100%', textAlign: 'center' }}>
            Quantidade de contatos que receberão esta notificação:
          </p>

          <div
            style={{
              fontSize: '1.2rem',
              color: '#103668',
              textAlign: 'center',
              padding: '1rem',
            }}
            data-testid="preview-qtd-contatos"
          >
            {origem === 'upload' && analiseArquivo.qtdContatos}
            {origem === 'cliente' && filter?.cliente_id?.length}
            {origem === 'cliente_grupo' && filter?.grupo_id?.length}
          </div>

          <p style={{ width: '100%', textAlign: 'center' }}>
            Ao clicar em Próximo, o envio das notificações terá início. Você
            poderá acompanhar o estado dos envios na tela de campanhas.
          </p>
        </div>
      )}

      {step === 3 && (
        <div>
          <Subtitulo>Execução</Subtitulo>

          <p style={{ width: '100%', textAlign: 'center' }}>
            As notificações estão sendo enviadas neste momento.
          </p>
          <p style={{ width: '100%', textAlign: 'center' }}>
            Acompanhe o progresso na tela de campanhas.
          </p>

          {analiseArquivo?.amostras?.map((amostra, idx) => {
            if (!amostra) return null;
            const msg = msgProgramada?.conteudo?.replace(
              /{{[^}]+}}/g,
              (match: any) => {
                const param = match.replace(/[{}]/g, '');
                if (associacoes[param]?.tipo === 'fixo')
                  return associacoes[param]?.valor;
                return amostra?.[associacoes[param]?.valor];
              },
            );

            return (
              <div
                key={idx}
                style={{
                  color: '#103668',
                  textAlign: 'center',
                  backgroundColor: '#0001',
                  margin: '0.25rem',
                }}
              >
                {msg}
              </div>
            );
          })}
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Botao
          variant="secondary"
          type="button"
          disabled={!canGoPreviousStep || step <= 0 || step >= 3}
          onClick={() => setStep(step - 1)}
          style={{ visibility: step <= 0 || step >= 3 ? 'hidden' : null }}
          icon={mdiArrowLeft}
        >
          Anterior
        </Botao>
        {step < 2 && (
          <Botao
            type="button"
            disabled={!canGoNextStep || step >= 3}
            onClick={() => setStep(step + 1)}
            icon={mdiArrowRight}
          >
            Próximo
          </Botao>
        )}

        {step === 2 && (
          <Botao
            type="button"
            disabled={!canGoNextStep}
            onClick={() => handleExecute()}
            icon={mdiArrowRight}
          >
            Executar
          </Botao>
        )}
      </div>
    </>
  );
};
