import CampoMultiSeletor from '../../components/Formulario/CampoMultiSeletor';
import Grid, { GridItem } from '../../components/layout/Grid';
import axios from 'axios';
import { useQuery } from 'react-query';

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
};

export const FiltroEstadoMensagem = ({
  value,
  onChange,
}: Props) => {
  const options = [
    { id: 'em_processamento', label: 'Em processamento' },
    { id: 'processada', label: 'Processada' },
    { id: 'lida', label: 'Lida' },
    { id: 'erro', label: 'Erro' },
  ];


  return (
    <CampoMultiSeletor
      noSearch
      label="Estado da mensagem"
      placeholder="(Qualquer)"
      value={value}
      onChange={onChange}
      options={options}
    />
  );
};
