import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import {
  mdiAccountCreditCard,
  mdiContentSave,
  mdiEye,
  mdiLoading,
  mdiMagnify,
  mdiOfficeBuilding,
  mdiPen,
  mdiPlus,
  mdiStar,
  mdiTrashCan,
} from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import axios from 'axios';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import { useDebounce } from '../chat/useDebounce';
import { toast } from 'react-toastify';
import Modal from '../../components/layout/Modal';
import { FormCliente } from './FormCliente';
import Grid, { GridItem } from '../../components/layout/Grid';
import CampoMultiSeletor from '../../components/Formulario/CampoMultiSeletor';
import Badge from '../../components/layout/Badge';
import { parseISO, format, set } from 'date-fns';
import { Tooltip } from '../../components/layout/Tooltip';
import CampoNumero from '../../components/Formulario/CampoNumero';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import IconeCanal from '../../components/layout/IconeCanal';
import AlertBox from '../../components/layout/AlertBox';
import Marcadores from '@/components/layout/Marcadores';
import CampoCheckbox from '@/components/Formulario/CampoCheckbox';
import { CampoPersonalizacao } from '@/components/Formulario/CampoPersonalizacao';
import Subtitulo from '@/components/tipografia/Subtitulo';
import Card from '@/components/layout/Card';
import Conversas from '../chat/Conversas';
import Mensagens from '../chat/Mensagens';
import Mensagem from '../chat/Mensagem';
import { ChatMensagemDto } from '@tera/shared/src/dto/ChatMensagemDto';
import CampoMultiTexto from '@/components/Formulario/CampoMultiTexto';
import CampoMemo from '@/components/Formulario/CampoMemo';
import ViewCliente from './ViewCliente';
import { useNavigate } from 'react-router-dom';

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type Props = {};

const TextoPersonalizacao = ({ type, value }: any) => {
  switch (type) {
    case 'data':
      return format(parseISO(value), 'dd/MM/yyyy');
    case 'booleano':
      return value ? 'Sim' : 'Não';
    case 'lista':
      return value && Array.isArray(value) ? value.join(', ') : value ?? '-';
    default:
      return value;
  }
};

const Page = ({ }: Props) => {
  const navigate = useNavigate();

  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [clientInfo, setClientInfo] = React.useState<any>(null);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const [filterStatus, setFilterStatus] = React.useState<string[]>([]);
  const [filterClienteGrupo, setFilterClienteGrupo] = React.useState<string[]>(
    [],
  );

  const [showImportModal, setShowImportModal] = React.useState(false);
  const [importResults, setImportResults] = React.useState<any>(null);

  const [ddd, setDdd] = React.useState<string | null>(null);
  const [clienteEstado, setClienteEstado] = React.useState<string | null>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = React.useState<File | null>(null);

  const importMutator = useMutation(async () => {
    if (!file) {
      alert('Nenhum arquivo selecionado.');
      return;
    }

    setImportResults(null);

    const formData = new FormData();
    formData.append('ddd', ddd!);
    formData.append('file', file as Blob);
    formData.append('cliente_estado', clienteEstado!);

    const extension = file!.name.split('.').pop();
    if (!['vcf', 'csv'].includes(extension!)) {
      alert('Formato de arquivo inválido.');
      return;
    }

    try {
      const response = await axios.post(`/api/cliente/upload/${extension}`, formData);

      setImportResults(response.data);

      queryList.refetch();
    } catch (error: any) {
      console.error(error);

      if (error.response?.data?.fieldErrors) {
        alert(error.response.data.msg ?? 'Erro ao importar arquivo');
      }
      throw error;
    } finally {
      fileInputRef.current!.value = '';
      setFile(null);
    }
  });

  const handleImportStart = async () => {
    importMutator.mutate();
  };

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const qTags = useQuery('tags', async () => {
    const result = await axios.get('/api/marcador/box');
    return result.data?.result;
  });

  const qGrupos = useQuery('cliente_grupo/box', async () => {
    const { data } = await axios.get('/api/cliente_grupo/box');
    return data?.result ?? [];
  });
  const gruposOptions = qGrupos.data?.result?.map((it: any) => ({
    id: it.id,
    label: it.nome,
  }));

  const [showCustomizeModal, setShowCustomizeModal] = React.useState(false);
  const qCustomize = useQuery(
    'cliente/meta',
    async () => {
      const res = await axios.get('/api/cliente/meta', {
        withCredentials: true,
      });

      return res.data.result;
    },
    {
      onSuccess: data => {
        setEditCustomizeObject(data);
      },
      refetchOnWindowFocus: false,
    },
  );

  const headersMeta =
    qCustomize.data
      ?.filter((it: any) => it.listavel)
      ?.map((it: any) => ({
        label: it.rotulo,
        render: (row: any) => {
          return (
            <TextoPersonalizacao
              type={it.tipo}
              value={row.metadados?.[it.nome] ?? '-'}
            />
          );
        },
      })) ?? [];

  const headers: DataTableHeader[] = useMemo(
    () => [
      { label: 'Nome', width: '25%', column: 'nome' },
      {
        label: 'Situação',
        width: '15%',
        render: (row: any) => {
          switch (row.estado) {
            case 'ativo':
              return (
                <Tooltip
                  position="bottom"
                  text="Recebe campanhas e notificações"
                >
                  <Badge style={{ width: 'fit-content' }} variant="success">
                    Ativo
                  </Badge>
                </Tooltip>
              );

            case 'inativo':
              return (
                <Tooltip position="bottom" text="Não interage com o sistema">
                  <Badge style={{ width: 'fit-content' }} variant="secondary">
                    Inativo
                  </Badge>
                </Tooltip>
              );
            case 'bloqueado':
              return (
                <Tooltip
                  position="bottom"
                  text="Não recebe campanhas nem notificações"
                >
                  <Badge style={{ width: 'fit-content' }} variant="danger">
                    Bloqueado
                  </Badge>
                </Tooltip>
              );
            case 'bloqueado_campanha':
              return (
                <Tooltip position="bottom" text="Recebe apenas notificações">
                  <Badge style={{ width: 'fit-content' }} variant="warning">
                    Bloq Campanhas
                  </Badge>
                </Tooltip>
              );
            default:
              return (
                <Badge style={{ width: 'fit-content' }} variant="secondary">
                  {row.estado}
                </Badge>
              );
          }
        },
      },
      { label: 'Grupo', width: '25%', render: (row: any) => row.grupo?.nome },
      { label: 'Empresa', width: '25%', column: 'empresa' },
      {
        label: 'Marcadores',
        width: '10%',
        render: (row: any) => (
          <div>
            <Marcadores ids={row.marcadores?.map((it: any) => it.id)} />
          </div>
        ),
      },
      {
        label: 'Criado em',
        width: '30%',
        render: (row: any) => (
          <span style={{ whiteSpace: 'nowrap' }}>
            {format(parseISO(row.data_criacao), 'dd/MM/yyyy HH:mm')}
          </span>
        ),
      },
      ...headersMeta,
      {
        label: 'Ações',
        width: '100px',
        stickRight: true,
        alignRight: true,
        render: (row: any) => {
          return (
            <HBox gap="8px">
              <Botao
                title="Ver"
                variant="none-info"
                icon={mdiEye}
                onClick={() => setClientInfo(row)}
              />
              <Botao
                title="Editar"
                variant="none-primary"
                icon={mdiPen}
                onClick={() => setEditObject(row)}
              />
              <BotaoPerigoso
                buttonProps={{ title: 'Apagar' }}
                variant="none-danger"
                icon={mdiTrashCan}
                onClick={() => handleDelete(row.id)}
              />
            </HBox>
          );
        },
      },
    ],
    [qTags.data, headersMeta],
  );

  const queryList = useInfiniteQuery({
    queryKey: ['cliente'],
    queryFn: async ({ pageParam }) => {
      const filter: Record<string, any> = {};

      if (filterStatus.length > 0) {
        filter['estado'] = ['in', filterStatus];
      }

      if (filterClienteGrupo.length > 0) {
        filter['grupo_id'] = ['in', filterClienteGrupo.map(Number)];
      }

      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam ?? '',
        fi: JSON.stringify(filter),
      });

      return await axios.get<ResponseList>(
        `/api/cliente?${params.toString()}`,
        {
          withCredentials: true,
        },
      );
    },
    getNextPageParam: lastPage => lastPage.data.cursor ?? null,
  });

  const changeMutator = useMutation(
    async () => {
      if (!editObject) return;

      setFieldErrors(null);
      if (editObject.id) {
        return await axios.put(`/api/cliente/${editObject.id}`, editObject, {
          withCredentials: true,
        });
      } else {
        return await axios.post(`/api/cliente`, editObject, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);

        toast(!editObject.id ? 'Contato adicionado com sucesso' : 'Contato alterado com sucesso', {
          toastId: !editObject.id ? 'add-success' : 'edit-success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: !editObject.id ? 'add-error' : 'edit-error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/cliente/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Contato apagado com sucesso', {
          toastId: 'del-success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar o contato.', {
          toastId: 'del-error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate();
  }, [changeMutator]);

  const handleDelete = (id: number) => {
    deleteMutator.mutate(id);
  };

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({});
  };

  const handleImportModal = () => {
    setShowImportModal(true);
    importMutator.reset();
  };

  const [editCustomizeObject, setEditCustomizeObject] =
    React.useState<any>(null);
  const [customizeFieldErrors, setCustomizeFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const customizeMutator = useMutation(
    async () => {
      if (!editCustomizeObject) return;

      setCustomizeFieldErrors(null);

      return await axios.post(`/api/cliente/meta`, editCustomizeObject, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        setCustomizeFieldErrors(null);
        setEditCustomizeObject(null);
        queryList.refetch();
        qCustomize.refetch();

        toast('Dados alterados com sucesso', {
          toastId: 'customize-success',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setCustomizeFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'customize-error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const newCustomizeObj = () => ({
    nome: '',
    tipo: 'texto',
    valor_padrao: '',
    obrigatorio: false,
    filtravel: true,
    listavel: true,
  });

  const handleCustomizeModal = () => {
    setShowCustomizeModal(true);
    customizeMutator.reset();
  };

  const handleCloseCustomizeModal = () => {
    setShowCustomizeModal(false);
    setEditCustomizeObject([]);
    setCustomizeFieldErrors(null);
  };

  const handleChangeCustomization = () => {
    customizeMutator.mutateAsync();
  };

  const queryFlatList =
    queryList.data?.pages?.map(p => p.data.result)?.flat() ?? [];
  const total =
    queryList.data?.pages?.[0]?.data?.total ?? 0;
  const editMode = !!editObject?.id && editObject.id > 0;

  const handleClientInfoClose = () => {
    setClientInfo(null);
    queryList.refetch();
  };
  return (
    <LayoutUsuario>
      <Modal
        size="lg"
        ignoreOverlayClick
        title="Personalizar contatos"
        show={showCustomizeModal}
        onClose={handleCloseCustomizeModal}
        onCancel={handleCloseCustomizeModal}
        onConfirm={handleChangeCustomization}
      >
        <VBox>
          <HBox stretch>
            <p>Todos os contatos receberão os campos abaixo.</p>
            <Botao
              variant="outline-primary"
              icon={mdiPlus}
              onClick={() =>
                setEditCustomizeObject([
                  ...(editCustomizeObject ?? []),
                  newCustomizeObj(),
                ])
              }
            >
              Adicionar campo
            </Botao>
          </HBox>

          <table style={{ width: '100%' }}>
            <tr>
              <th>Rótulo</th>
              <th>Tipo</th>
              <th>Valor padrão</th>
              <th>Obrigatório?</th>
              <th>Filtrável?</th>
              <th>Listável?</th>
              <th>Ações</th>
            </tr>
            <tr>
              <td colSpan={7}>
                <hr />
              </td>
            </tr>
            {editCustomizeObject?.map((obj: any, idx: number) => (
              <tr>
                <td>
                  <CampoTexto
                    value={obj.rotulo}
                    onChange={v =>
                      setEditCustomizeObject(
                        editCustomizeObject?.map((it: any, i: number) =>
                          i === idx ? { ...it, rotulo: v } : it,
                        ),
                      )
                    }
                    error={customizeFieldErrors?.[`${idx}.rotulo`]}
                  />
                </td>
                <td>
                  <CampoSeletor
                    noSearch
                    options={[
                      { id: 'texto', label: 'Texto' },
                      { id: 'numero', label: 'Número' },
                      { id: 'data', label: 'Data' },
                      { id: 'booleano', label: 'Booleano' },
                      { id: 'lista', label: 'Lista' },
                    ]}
                    value={obj.tipo}
                    onChange={v =>
                      setEditCustomizeObject(
                        editCustomizeObject?.map((it: any, i: number) =>
                          i === idx ? { ...it, tipo: v } : it,
                        ),
                      )
                    }
                    error={customizeFieldErrors?.[`${idx}.tipo`]}
                  />
                </td>
                <td>
                  <CampoPersonalizacao
                    type={obj.tipo}
                    onTypeChanged={() =>
                      setEditCustomizeObject(
                        editCustomizeObject?.map((it: any, i: number) =>
                          i === idx ? { ...it, valor_padrao: null } : it,
                        ),
                      )
                    }
                    value={obj.valor_padrao}
                    onChange={(v: any) =>
                      setEditCustomizeObject(
                        editCustomizeObject?.map((it: any, i: number) =>
                          i === idx ? { ...it, valor_padrao: v } : it,
                        ),
                      )
                    }
                    error={customizeFieldErrors?.[`${idx}.valor_padrao`]}
                  />
                </td>
                <td>
                  <CampoCheckbox
                    text="Obrigatório"
                    value={obj.obrigatorio}
                    onChange={v =>
                      setEditCustomizeObject(
                        editCustomizeObject?.map((it: any, i: number) =>
                          i === idx ? { ...it, obrigatorio: v } : it,
                        ),
                      )
                    }
                    error={customizeFieldErrors?.[`${idx}.obrigatorio`]}
                  />
                </td>
                <td>
                  <CampoCheckbox
                    text="Filtrável"
                    value={obj.filtravel}
                    onChange={v =>
                      setEditCustomizeObject(
                        editCustomizeObject?.map((it: any, i: number) =>
                          i === idx ? { ...it, filtravel: v } : it,
                        ),
                      )
                    }
                    error={customizeFieldErrors?.[`${idx}.filtravel`]}
                  />
                </td>
                <td>
                  <CampoCheckbox
                    text="Listável"
                    value={obj.listavel}
                    onChange={v =>
                      setEditCustomizeObject(
                        editCustomizeObject?.map((it: any, i: number) =>
                          i === idx ? { ...it, listavel: v } : it,
                        ),
                      )
                    }
                    error={customizeFieldErrors?.[`${idx}.listavel`]}
                  />
                </td>
                <td>
                  <HBox style={{ justifyContent: 'flex-end' }}>
                    <BotaoPerigoso
                      variant="none-danger"
                      icon={mdiTrashCan}
                      onClick={() =>
                        setEditCustomizeObject(
                          editCustomizeObject?.filter(
                            (it: any, i: number) => i !== idx,
                          ),
                        )
                      }
                    />
                  </HBox>
                </td>
              </tr>
            ))}
          </table>
        </VBox>
      </Modal>

      <Modal
        instanceId='modal-cliente'
        ignoreOverlayClick
        title={editMode ? 'Editar contato' : 'Novo contato'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
      >
        <FormCliente
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
      </Modal>

      <Modal
        //hideClose={importMutator.isLoading}
        show={showImportModal}
        title="Importar contatos"
        onClose={() => {
          setImportResults(null);
          setShowImportModal(false);
        }}
        onConfirm={() => {
          setImportResults(null);
          formRef.current!.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true }),
          );
        }}
        confirmProps={{
          label: 'Iniciar importação',
          disabled:
            importMutator.isLoading ||
            !fileInputRef.current?.value?.length ||
            !ddd?.length,
        }}
        cancelProps={{
          label: 'Fechar',
          disabled: importMutator.isLoading,
        }}
      >
        <VBox>
          <form
            ref={formRef}
            onSubmit={e => {
              e.preventDefault();
              handleImportStart();
            }}
          >
            <Grid>
              <GridItem sm={12} md={3}>
                <CampoNumero
                  label="DDD"
                  value={ddd ?? ''}
                  onChange={v => setDdd(v)}
                  disabled={importMutator.isLoading}
                  error={!ddd ? 'Campo necessário' : !/^\d\d$/.test(ddd) ? 'DDD inválido' : undefined}
                />
              </GridItem>
              <GridItem sm={12} md={3}>
                <CampoSeletor
                  noSearch
                  label="Estado padrão"
                  value={clienteEstado ?? undefined}
                  onChange={setClienteEstado}
                  //disabled={importMutator.isLoading}
                  options={[
                    { label: 'Ativo', id: 'ativo' },
                    { label: 'Bloqueado', id: 'bloqueado' },
                    {
                      label: 'Bloqueado (campanhas)',
                      id: 'bloqueado_campanha',
                    },
                  ]}
                  error={!clienteEstado ? 'Campo necessário' : undefined}
                />
              </GridItem>
              <GridItem sm={12} md={6}>
                <label>Arquivo</label>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".vcf,.csv"
                  className={styles.fileInput}
                  onChange={handleFileChange}
                  disabled={importMutator.isLoading}
                  style={{
                    color: fileInputRef.current?.value
                      ? 'green'
                      : 'var(--tc-color-danger)',
                  }}
                />
              </GridItem>
            </Grid>
          </form>

          {importMutator.isIdle ? (
            <>
              <p>
                Para importar arquivos CSV, a primeira linha do arquivo deve
                conter o cabeçalho.
              </p>
              <p>
                Os campos reconhecidos são: <b>nome</b>, <b>empresa</b>,{' '}
                <b>telefone</b> e <b>email</b>
              </p>
              <p>Exemplo de conteúdo do arquivo CSV:</p>
              <pre
                style={{
                  border: '1px solid #DDD',
                  padding: '.5rem',
                  backgroundColor: '#EEE',
                }}
              >
                nome,telefone
                <br />
                João da Silva,11999998888
                <br />
                Maria da Silva,11999998888
                <br />
              </pre>
            </>
          ) : importMutator.isLoading ? (
            <>
              <p>Aguarde. Este processo pode levar alguns minutos.</p>
              <p>Enquanto isto, por favor, não recarregue esta página.</p>
            </>
          ) : importMutator.isSuccess ? (
            <>
              <AlertBox variant="success">
                <p style={{ textAlign: 'center', width: '100%' }}>
                  A importação foi concluída!
                </p>

                <p style={{ textAlign: 'center', width: '100%' }}>
                  Contatos importados: {importResults?.length?.success}
                </p>
              </AlertBox>

              {importResults?.length?.error > 0 && (
                <>
                  <p style={{ textAlign: 'center', width: '100%' }}>
                    Os seguintes contatos não puderam ser importados:
                  </p>

                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'start' }}>Nome</th>
                        <th style={{ textAlign: 'start' }}>Contato</th>
                      </tr>
                    </thead>
                    <tbody>
                      {importResults?.clientesErro?.map(
                        (cli: any, idx: number) => (
                          <tr key={idx}>
                            <td>{cli?.nome ?? '(vazio)'}</td>
                            <td>
                              {cli?.contatos?.map((c: any, idx1: number) => (
                                <div key={idx1}>
                                  <IconeCanal
                                    tipo={c?.tipo}
                                    style={{ height: '16px' }}
                                  />
                                  {c?.valor}
                                </div>
                              )) ?? '(vazio)'}
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </>
          ) : importMutator.isError ? (
            <>
              <p>Ocorreu um erro ao tentar importar os arquivos.</p>

              <p>
                Tente novamente. Se o problema persistir, contate nosso suporte
                técnico.
              </p>
            </>
          ) : (
            <>
              <p>Ocorreu um erro desconhecido.</p>

              <p>
                Tente novamente. Se o problema persistir, contate nosso suporte
                técnico.
              </p>
            </>
          )}
        </VBox>
      </Modal>

      <Modal
        size="md"
        title="Dados do contato"
        show={Boolean(clientInfo)}
        hideControls
        onClose={handleClientInfoClose}
        onCancel={handleClientInfoClose}
        onConfirm={handleClientInfoClose}
      >
        <ViewCliente
          value={clientInfo}
          onEdit={id => {
            setClientInfo(null);
            setEditObject(queryFlatList.find(it => it.id === id));
          }}
          onViewChatMessages={id => {
            navigate(`/chat?uid=${id}`);
          }}
        />
      </Modal>

      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Contatos</Titulo>
            <Descricao>Gerencie aqui os contatos de seus clientes.</Descricao>
          </VBox>
          <HBox gap="12px" className={styles.headerAcoes}>
            <Botao
              variant="outline-primary"
              icon={mdiStar}
              onClick={handleCustomizeModal}
            >
              Personalizar...
            </Botao>
            <Botao
              variant="outline-primary"
              icon={mdiPlus}
              onClick={handleImportModal}
            >
              Importar contatos
            </Botao>
            <Botao
              variant="primary"
              icon={mdiPlus}
              onClick={handleOpenModalNewObject}
            >
              Novo contato
            </Botao>
          </HBox>
        </HBox>

        {/* Filtros */}
        <Grid>
          <GridItem sm={12} lg={6}>
            <CampoTexto
              prepend={
                <Icon
                  path={mdiMagnify}
                  size={1}
                  color="var(--tc-color-gray-500)"
                />
              }
              placeholder="Pesquisar"
              value={search}
              onChange={setSearch}
            />
          </GridItem>
          <GridItem sm={12} lg={3}>
            <CampoMultiSeletor
              placeholder="Filtrar por situação"
              options={[
                { id: 'ativo', label: 'Ativos' },
                {
                  id: 'bloqueado_campanha',
                  label: 'Bloqueados para campanhas',
                },
                { id: 'bloqueado', label: 'Bloqueados' },
              ]}
              value={filterStatus}
              onChange={setFilterStatus}
            />
          </GridItem>
          <GridItem sm={12} lg={3}>
            <CampoMultiSeletor
              placeholder="Filtrar por grupo"
              options={gruposOptions}
              value={filterClienteGrupo}
              onChange={setFilterClienteGrupo}
            />
          </GridItem>
        </Grid>

        <div>
          <Botao variant="primary" onClick={() => queryList.refetch()}>
            Aplicar
          </Botao>
        </div>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryFlatList?.length ?? '0'} de {total ?? '0'}{' '}
            resultados.
          </div>
        )}

        <DataTable
          isLoading={queryList.isFetching}
          cabecalhos={headers}
          linhas={queryFlatList ?? []}
          hasMoreData={queryList.hasNextPage}
          onLoadMore={async () => queryList.fetchNextPage()}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
