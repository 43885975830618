import CampoSeletor from '@/components/Formulario/CampoSeletor';
import axios from 'axios';
import { useQuery } from 'react-query';

type Props = {
  value: number;
  onChange: (v: number) => void;
  label?: string;
  error?: string | undefined;
  placeholder?: string;
  instanceId?: string;
};
export const CampoSeletorFunil = ({
  value,
  onChange,
  label,
  error,
  placeholder,
  instanceId,
}: Props) => {
  const q = useQuery('funil_venda/box', async () => {
    const result = await axios.get('/api/funil_venda/box');
    return result.data.result;
  });
  const options = q.data?.map((c: any) => ({ id: c.id, label: c.nome })) ?? [];

  return (
    <CampoSeletor
      instanceId={instanceId}
      options={options}
      value={value?.toString()}
      onChange={v => onChange(+v!)}
      label={label}
      error={error}
      placeholder={placeholder}
    />
  );
};
