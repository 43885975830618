import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import { mdiMagnify, mdiPlus, mdiStar } from '@mdi/js';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import Grid, { GridItem } from '../../components/layout/Grid';
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import Modal from '@/components/layout/Modal';
import { FormVenda } from './FormVenda';
import { VendaDto } from '@tera/shared/src/types/VendaDto';
import { Raia } from './Raia';
import { CampoSeletorUsuarios } from './CampoSeletorUsuarios';
import { ModalImportarArquivo } from './ModalImportarArquivo';
import { useAuth } from '@/lib/useAuth';
import { FormMetadados } from '@/components/modelos/FormMetadados';
import { useDebounce } from '../chat/useDebounce';
import { Link } from 'react-router-dom';
import { CampoSeletorFunil } from './CampoSeletorFunil';

const reorder = (
  value: Record<string, VendaDto[]>,
  startDid: string,
  endDid: string,
  startIndex: number,
  endIndex: number,
): Record<string, VendaDto[]> => {
  // TODO enviar as atualizações ao backend
  const listA = value[startDid];
  const listB = value[endDid];

  if (startDid === endDid) {
    const result = Array.from(listA);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return {
      ...value,
      [startDid]: result,
    };
  } else {
    const result = Array.from(listA);
    const [removed] = result.splice(startIndex, 1);

    const resultB = Array.from(listB);
    resultB.splice(endIndex, 0, removed);

    return {
      ...value,
      [startDid]: result,
      [endDid]: resultB,
    };
  }
};

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type Props = {};

const Page = ({ }: Props) => {
  const { user } = useAuth();

  const [search, setSearch] = React.useState<string>('');
  const [filter, setFilter] = React.useState<any>({});
  const debouncedSearch = useDebounce(search, 500);

  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [selectedFunil, setSelectedFunil] = React.useState<any>();
  useEffect(() => {
    if (selectedFunil) {
      setFilter({ ...filter, id_funil: ['eq', selectedFunil] });
    } else {
      setFilter({ ...filter, id_funil: null });
    }
  }, [selectedFunil])

  const qResumo = useQuery({
    queryKey: ['atividade/resumo'],
    queryFn: async () => {
      const res = await axios.get('/api/atividade/resumo', {
        withCredentials: true,
      });

      return res.data.result;
    },
  })

  const qFunil = useQuery({
    queryKey: ['funil_venda', selectedFunil],
    queryFn: async () => {
      console.warn('selectedFunil', selectedFunil)
      const result = await axios.get<any>(
        !selectedFunil ? `/api/funil_venda/padrao` : `/api/funil_venda/${selectedFunil}`,
        {
          withCredentials: true,
        },
      );
      return result.data;
    },
    onSuccess: (data) => {
      if (!selectedFunil && data.result)
        setSelectedFunil(data.result.id);
    },
  });

  const sortedEtapas = qFunil.data?.result?.etapas?.sort((a: any, b: any) => a.indice - b.indice);

  const transicoesAvancar = qFunil.data?.result?.transicoes?.reduce((acc: any, cur: any) => {
    if (!acc[cur.id_etapa_origem]) {
      acc[cur.id_etapa_origem] = [];
    }
    const etapa = qFunil.data?.result?.etapas.find((e: any) => e.id === cur.id_etapa_destino);

    acc[cur.id_etapa_origem].push({
      id: etapa.id,
      label: etapa.nome
    });

    return acc;
  }, {});


  const transicoesVoltar = qFunil.data?.result?.transicoes?.reduce((acc: any, cur: any) => {
    if (!acc[cur.id_etapa_destino]) {
      acc[cur.id_etapa_destino] = [];
    }
    const etapa = qFunil.data?.result?.etapas.find((e: any) => e.id === cur.id_etapa_origem);

    acc[cur.id_etapa_destino].push({
      id: etapa.id,
      label: etapa.nome
    });

    return acc;
  }, {});

  const queryList = useQuery({
    queryKey: ['venda', selectedFunil],
    queryFn: async ({ pageParam }) => {
      filter.id_funil = ['eq', selectedFunil];

      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam,
        fi: JSON.stringify(filter),
      });

      const result = await axios.get<ResponseList>(
        `/api/venda?${params.toString()}`,
        {
          withCredentials: true,
        },
      );
      return result.data;
    },
    enabled: !!selectedFunil
  });

  const vendasByStatus = useMemo(() => {
    if (!qFunil.data?.result || !queryList.data?.result)
      return {} as Record<string, VendaDto[]>;

    const v = qFunil.data?.result?.etapas?.reduce(
      (acc: any, cur: any) => {
        const status = cur.nome;
        if (!acc[status]) {
          acc[status] = [];
        }

        return acc;
      },
      {} as Record<string, VendaDto[]>,
    ) ?? {};

    for (const venda of queryList.data?.result ?? []) {
      console.warn(venda)

      if (!venda?.etapa)
        continue;

      if (!v[venda.etapa.nome]) {
        v[venda.etapa.nome] = [];
      }
      v[venda.etapa.nome].push(venda);
    }

    return v;
  }, [qFunil.data?.result, queryList.data?.result]);

  const changeMutator = useMutation(
    async (item: any) => {
      if (!item) return;

      setFieldErrors(null);
      if (item.id) {
        return await axios.put(`/api/venda/${item.id}`, item, {
          withCredentials: true,
        });
      } else {
        return await axios.post(`/api/venda`, item, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/venda/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Venda apagada com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar a venda.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate(editObject);
  }, [changeMutator, editObject]);

  const handleChangeItem = async (item: any) => {
    await changeMutator.mutate(item);

  }

  const handleDelete = (id: number) => {
    deleteMutator.mutate(id);
  };

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({
      valor_total: 0,
    });
  };

  const [showImportModal, setShowImportModal] = React.useState(false);

  const handleImportModal = () => {
    setShowImportModal(true);
  };

  const reorderMutator = useMutation(
    async (data: any) => {
      const result = await axios.post('/api/venda/reorder', data, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar reordenar a venda.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const [editCustomizeObject, setEditCustomizeObject] =
    React.useState<any>(null);
  const [customizeFieldErrors, setCustomizeFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const customizeMutator = useMutation(
    async () => {
      if (!editCustomizeObject) return;

      setCustomizeFieldErrors(null);

      return await axios.post(`/api/venda/meta`, editCustomizeObject, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        setCustomizeFieldErrors(null);
        setEditCustomizeObject(null);
        queryList.refetch();
        qCustomize.refetch();
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setCustomizeFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const [showCustomizeModal, setShowCustomizeModal] = React.useState(false);
  const qCustomize = useQuery(
    'venda/meta',
    async () => {
      const res = await axios.get('/api/venda/meta', {
        withCredentials: true,
      });

      return res.data.result;
    },
    {
      onSuccess: (data: any) => {
        setEditCustomizeObject(data);
      },
      refetchOnWindowFocus: false,
    },
  );

  const handleCustomizeModal = () => {
    setShowCustomizeModal(true);
    customizeMutator.reset();
  };

  const handleCloseCustomizeModal = () => {
    setShowCustomizeModal(false);
    setEditCustomizeObject([]);
    setCustomizeFieldErrors(null);
  };

  const handleChangeCustomization = () => {
    customizeMutator.mutateAsync();
  };

  return (
    <LayoutUsuario>
      <Modal
        size='lg'
        ignoreOverlayClick
        title={editObject?.id > 0 ? 'Editar venda' : 'Nova venda'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
      >
        <FormVenda
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
      </Modal>

      <Modal
        size="lg"
        ignoreOverlayClick
        title="Personalizar venda"
        show={showCustomizeModal}
        onClose={handleCloseCustomizeModal}
        onCancel={handleCloseCustomizeModal}
        onConfirm={handleChangeCustomization}
      >
        <p>Todos os produtos receberão os campos abaixo.</p>

        <FormMetadados
          value={editCustomizeObject}
          onChange={setEditCustomizeObject}
          fieldErrors={customizeFieldErrors}
        />
      </Modal>

      <ModalImportarArquivo
        show={showImportModal}
        onConfirm={() => {
          queryList.refetch();
        }}
        onClose={() => {
          setShowImportModal(false);
        }}
      />

      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Vendas</Titulo>
            <Descricao>Gerencie aqui suas vendas e clientes</Descricao>
          </VBox>
          <HBox gap="12px" className={styles.headerAcoes}>
            <Botao
              variant="outline-primary"
              icon={mdiStar}
              onClick={handleCustomizeModal}
            >
              Personalizar...
            </Botao>
            <Botao
              variant="outline-primary"
              icon={mdiPlus}
              onClick={handleImportModal}
            >
              Importar...
            </Botao>
            <Botao
              variant="primary"
              icon={mdiPlus}
              onClick={handleOpenModalNewObject}
            >
              Nova
            </Botao>
          </HBox>
        </HBox>

        {/* Filtros */}
        <Grid>
          <GridItem sm={12} lg={4}>
            <CampoTexto
              prepend={
                <Icon
                  path={mdiMagnify}
                  size={1}
                  color="var(--tc-color-gray-500)"
                />
              }
              placeholder="Pesquisar"
              value={search}
              onChange={setSearch}
            />
          </GridItem>
          <GridItem sm={12} lg={4}>
            <CampoSeletorFunil
              instanceId='filter-funil'
              value={selectedFunil}
              onChange={setSelectedFunil}
              placeholder="Buscar por funis"
            />
          </GridItem>
          {user?.papel !== 'USUARIO' && (
            <GridItem sm={12} lg={4}>
              <CampoSeletorUsuarios
                value={filter?.id_usuario_vendedor?.[1]}
                onChange={v =>
                  setFilter({ ...filter, id_usuario_vendedor: ['in', v] })
                }
                placeholder="Buscar por vendedores"
              />
            </GridItem>
          )}
        </Grid>

        <div>
          <Botao variant="primary" onClick={() => queryList.refetch()}>
            Aplicar
          </Botao>
        </div>

        {qResumo.data?.['today'] && (
          <Link to={`/atividade`} style={{ textDecoration: 'none' }}>
            <div style={{ textAlign: 'center', fontSize: '1.25rem', color: 'var(--tc-color-primary)' }}>
              Você tem {qResumo.data['today'].length == 1 ? `1 atividade` : `${qResumo.data['today'].length} atividades`} para hoje: {qResumo.data['today'].join(', ')}.
            </div>
          </Link>
        )}

        {qResumo.data?.['tomorrow'] && (
          <Link to={`/atividade`} style={{ textDecoration: 'none' }}>
            <div style={{ textAlign: 'center', color: 'var(--tc-color-primary)' }}>
              Amanhã você tem {qResumo.data['tomorrow'].length == 1 ? `1 atividade` : `${qResumo.data['tomorrow'].length} atividades`}: {qResumo.data['tomorrow'].join(', ')}.
            </div>
          </Link>
        )}
        <HBox style={{ overflowX: 'auto', alignItems: 'flex-start' }}>
          {sortedEtapas?.map((step: any) => (
            <>
              <Raia
                key={step.id}
                nome={step.nome}
                titulo={step.title}
                corTitulo={step.tipo === 'ganho' ? 'var(--tc-color-success)' : step.tipo === 'perda' ? 'var(--tc-color-warning)' : '#333'}
                value={vendasByStatus[step.nome]}
                transicoesAvancar={transicoesAvancar[step.id]}
                transicoesVoltar={transicoesVoltar[step.id]}
                disableControls={reorderMutator.isLoading || changeMutator.isLoading || queryList.isLoading}
                onChange={handleChangeItem}
                onEdit={setEditObject}
                onDelete={venda => handleDelete(venda.id)}
              />
            </>
          ))}
        </HBox>
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
