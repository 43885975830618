const LOG_SERVER_URL = "/api/logs";
import { getSessionId } from "./../lib/sessionRecorder";

export function logError(error: Error, info: any = null) {
    const payload = {
        level: 'error',
        sessionId: getSessionId(),
        message: error.message || "Erro desconhecido",
        stack: error.stack || "",
        componentStack: info ? info.componentStack : null,
        userAgent: navigator.userAgent,
        timestamp: new Date().toISOString(),
    };

    fetch(LOG_SERVER_URL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    }).catch(console.error);
}