import React, { useEffect, useLayoutEffect } from 'react';
import styles from './LayoutUsuario.module.css';
import {
  mdiAccountBox,
  mdiAccountClock,
  mdiAccountEdit,
  mdiAccountGroup,
  mdiApi,
  mdiBullhorn,
  mdiCalendar,
  mdiCalendarAccount,
  mdiCardAccountDetails,
  mdiCardAccountPhone,
  mdiChartAreaspline,
  mdiChartBarStacked,
  mdiCreation,
  mdiCurrencyBrl,
  mdiFilter,
  mdiHistory,
  mdiInvoiceImport,
  mdiMenu,
  mdiMenuOpen,
  mdiMessage,
  mdiMessageBookmark,
  mdiPhone,
  mdiSchool,
  mdiSitemap,
  mdiStar,
  mdiTag,
  mdiTextBoxEdit,
  mdiTrophyAward,
  mdiWrenchCog,
  mdiZipBox,
} from '@mdi/js';
import { useAuth } from '../../lib/useAuth';
import Icon from '@mdi/react';
import Botao from '../Botao';
import { rolesAdmin, rolesManager, rolesUser } from '@tera/shared/src/lib/auth';
import NavCategorias from './NavCategorias';
import NavItens from './NavItens';
import NavItem from './NavItem';
import { ModalAlterarSenha } from './ModalAlterarSenha';
import { Socket, io } from 'socket.io-client';
import { toast } from 'react-toastify';
import HBox from '../layout/HBox';
import { useReward } from 'react-rewards';

import { socket } from './socket';

type NavCagetoria = {
  label: string;
  icon: string;
  itens: Navegacao[];
};

type Navegacao = {
  label: string;
  icon: string;
  roles: string[];
  path: string;
  important?: boolean;
};

const navegacao: NavCagetoria[] = [
  {
    label: 'Atendimento',
    icon: mdiMessage,
    itens: [
      {
        label: 'Chats',
        icon: mdiMessage,
        roles: rolesUser,
        path: '/chat',
        important: true,
      },
      {
        label: 'Filas de atendimento',
        icon: mdiAccountClock,
        roles: rolesManager,
        path: '/fila_atendimento',
      },
    ],
  },
  {
    label: 'CRM',
    icon: mdiStar,
    itens: [
      {
        label: 'Contatos',
        important: true,
        icon: mdiCardAccountPhone,
        roles: rolesManager,
        path: '/cliente',
      },
      {
        label: 'Vendas',
        icon: mdiStar,
        roles: rolesUser,
        path: '/venda',
      },
      {
        label: 'Minhas atividades',
        icon: mdiAccountClock,
        roles: rolesUser,
        path: '/atividade',
      },
      {
        label: 'Produtos',
        icon: mdiZipBox,
        roles: rolesManager,
        path: '/produto',
      },
      {
        label: 'Grupos de contatos',
        icon: mdiCardAccountDetails,
        roles: rolesUser,
        path: '/cliente_grupo',
      },
      {
        label: 'Funil de vendas',
        icon: mdiFilter,
        roles: rolesAdmin,
        path: '/funil_venda',
      },
    ],
  },
  {
    label: 'Gestão',
    icon: mdiWrenchCog,
    itens: [
      {
        label: 'Fluxos de atendimento',
        icon: mdiSitemap,
        roles: rolesManager,
        path: '/dialogo',
      },
      {
        label: 'Integrações',
        icon: mdiApi,
        roles: rolesManager,
        path: '/integracao_api',
      },
      {
        label: 'Base de conhecimento',
        icon: mdiSchool,
        roles: rolesManager,
        path: '/base_conhecimento',
      },
      {
        label: 'Agentes de IA',
        icon: mdiCreation,
        roles: rolesManager,
        path: '/ia_agente',
      },
      {
        label: 'Canais de atendimento',
        icon: mdiPhone,
        roles: rolesManager,
        path: '/canal',
      },
      {
        label: 'Modelos de mensagem',
        icon: mdiTextBoxEdit,
        roles: rolesManager,
        path: '/mensagem_programada',
      },
      {
        label: 'Atalhos de mensagem',
        icon: mdiMessageBookmark,
        roles: rolesManager,
        path: '/atalho_mensagem',
      },
      {
        label: 'Marcadores',
        icon: mdiTag,
        roles: rolesManager,
        path: '/marcador',
      },
      {
        label: 'Campanhas',
        icon: mdiBullhorn,
        roles: rolesManager,
        path: '/campanha_mensagem',
      },
      {
        label: 'Agendas',
        icon: mdiCalendarAccount,
        roles: rolesManager,
        path: '/agenda',
      },
      {
        label: 'Usuários',
        icon: mdiAccountBox,
        roles: rolesManager,
        path: '/usuario',
      },
      {
        label: 'Grupos / Departamentos',
        icon: mdiAccountGroup,
        roles: rolesManager,
        path: '/usuario_grupo',
      },
      {
        label: 'Calendário',
        icon: mdiCalendar,
        roles: rolesManager,
        path: '/calendario',
      },
    ],
  },
  {
    label: 'Relatórios',
    icon: mdiChartBarStacked,
    itens: [
      {
        label: 'Mensagens e atendimentos',
        icon: mdiChartAreaspline,
        roles: rolesManager,
        path: '/relatorios',
      },
      {
        label: 'Históricos de mensagens',
        icon: mdiHistory,
        roles: rolesManager,
        path: '/historico_mensagens',
      },
      {
        label: 'Vendas',
        icon: mdiStar,
        roles: rolesManager,
        path: '/relatorios/vendas',
      },
    ],
  },
  {
    label: 'Financeiro',
    icon: mdiCurrencyBrl,
    itens: [
      {
        label: 'Faturas',
        icon: mdiCurrencyBrl,
        roles: rolesManager,
        path: '/fatura',
      },
    ],
  },
];

const navPerfil = [
  {
    label: 'Avalie-nos!',
    icon: mdiStar,
    roles: rolesManager,
    path: '/chat',
  },
  {
    label: 'Meu perfil',
    icon: mdiAccountEdit,
    roles: rolesManager,
    path: '/perfil',
  },
  {
    label: 'Sair',
    icon: mdiInvoiceImport,
    roles: rolesManager,
    path: '/logout',
  },
];

const LayoutUsuario = ({ children }: { children: React.ReactNode }) => {
  const rolesAdmin = ['ADMIN'];
  const rolesManager = [...rolesAdmin, 'GESTOR'];
  const rolesUser = [...rolesManager, 'USUARIO'];

  const { user, setUser } = useAuth();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [mouseOverCats, setMouseOverCats] = React.useState(false);
  const [mouseOverItens, setMouseOverItens] = React.useState(false);
  const [selected, setSelected] = React.useState<string | null>(
    navegacao[0].label,
  );

  const [showModalAlterarSenha, setShowModalAlterarSenha] = React.useState(user?.trocar_senha_proximo_login);

  useLayoutEffect(() => {
    navigator.serviceWorker
      .register('/service-worker.js')
  }, []);

  useEffect(() => {
    if (!menuOpen) setSelected(null);
  }, [menuOpen]);

  // Fecha o menu de itens quando o mouse não estiver sobre categorias ou itens
  useEffect(() => {
    if (!mouseOverCats && !mouseOverItens) setSelected(null);
  }, [mouseOverCats, mouseOverItens]);

  const { reward, isAnimating } = useReward('add-achievement', 'confetti', { angle: 270, startVelocity: 20, spread: 180 });

  useEffect(() => {
    console.log('socket init /notifications');
    socket.on('achievement', (data) => {
      console.log('Achievement unlocked!', data)

      try {
        new Audio('/sounds/conquista.mp3').play();
      } catch (e) {
        console.warn('Erro ao tocar som de conquista', e);
      }

      toast(<div style={{ textAlign: 'center' }}>
        <HBox style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
          <Icon path={mdiTrophyAward} size={1.5} style={{ marginRight: '8px' }} />
          {data.titulo}
        </HBox>
        <div>Nova conquista desbloqueada!</div>
      </div>, {
        position: 'top-right',
        theme: 'tutorial',
      })

      setTimeout(() => {
        reward();
      }, 350);
    });

    return () => {
      socket.off('achievement');
    }
  }, [])


  if (!user) return null;

  return (
    <div className={styles.container}>
      <ModalAlterarSenha
        show={showModalAlterarSenha}
        onChanged={(updUser) => {
          setShowModalAlterarSenha(false);
          setUser(updUser);
        }} />
      <div
        className={[styles.sidebar, menuOpen ? styles.open : null].join(' ')}
      >
        <div className={[styles.logobar, 'font-terachat'].join(' ')}>
          <Botao
            className={styles.btnMenu}
            variant="transparent"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <Icon path={menuOpen ? mdiMenuOpen : mdiMenu} size={1.5} />
          </Botao>
          <img src="/logo.png" alt="Logo Terachat" style={{ height: '32px' }} />
          <span>
            <b>Tera</b>chat
          </span>
        </div>

        <div style={{ width: '100%', position: 'relative' }}>
          <NavCategorias
            value={navegacao}
            selected={selected}
            onSelect={v =>
              setSelected(
                selected === v.label && mouseOverCats ? null : v.label, //mouseOverCats permite que usuários de touch devices possam clicar duas vezes para fechar o menu de itens
              )
            }
            setIsOver={setMouseOverCats}
          />
          {selected != null && (
            <NavItens
              value={navegacao}
              selected={selected}
              setSelected={setSelected}
              setIsOver={setMouseOverItens}
            />
          )}
        </div>

        <hr
          style={{
            margin: '4 16px',
            border: 'none',
            borderBottom: '1px solid var(--tc-color-brand-primary)',
          }}
        />

        <div className={styles.containerPerfil}>
          {navPerfil.map(item => (
            <NavItem value={item} />
          ))}
        </div>
      </div>
      <div className={styles.contents}>{children}</div>
    </div>
  );
};

export default LayoutUsuario;
