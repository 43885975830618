import CampoTexto from '../../components/Formulario/CampoTexto';
import Grid, { GridItem } from '../../components/layout/Grid';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import CampoMemo from '@/components/Formulario/CampoMemo';
import Botoes from '@/components/Botoes';
import Botao from '@/components/Botao';
import { mdiAlert, mdiContentCopy, mdiCopyleft, mdiLock, mdiLockOff, mdiTrashCanOutline } from '@mdi/js';
import CampoData from '@/components/Formulario/CampoData';
import { format, parse } from 'date-fns';
import Icon from '@mdi/react';
import HBox from '@/components/layout/HBox';
import { Tooltip } from '@/components/layout/Tooltip';
import { useQuery } from 'react-query';
import axios from 'axios';
import React, { useEffect } from 'react';
import CampoNumero from '@/components/Formulario/CampoNumero';
import VBox from '@/components/layout/VBox';
import { PopupCenter } from '@/lib/popup';

export const FormAgenda = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {

  const [tipoResponsavel, setTipoResponsavel] = React.useState<'usuario' | 'grupo'>(value?.grupo_id ? 'grupo' : 'usuario');
  const newObj = !value?.id || value?.id < 0;

  useEffect(() => {
    if (value?.grupo_id) {
      setTipoResponsavel('grupo')
    } else {
      setTipoResponsavel('usuario')
    }

  }, [value])

  const qUsuario = useQuery({
    queryKey: 'usuario/box',
    queryFn: async () => {
      const res = await axios.get('/api/usuario/box');
      return res.data;
    }
  })
  const usuarioOptions = qUsuario.data?.result?.map((it: any) => ({
    id: it.id,
    label: it.nome,
    group: it.grupo?.nome
  })) ?? [];

  const qGrupo = useQuery({
    queryKey: 'usuario_grupo/box',
    queryFn: async () => {
      const res = await axios.get('/api/usuario_grupo/box');
      return res.data;
    }
  })

  const grupoOptions = qGrupo.data?.result?.map((it: any) => ({
    id: it.id,
    label: it.nome,
  })) ?? [];

  const tipoOptions = [
    { id: 'usuario', label: 'Usuário' },
    { id: 'grupo', label: 'Grupo' },
  ]
  return (
    <Grid>
      <GridItem sm={12} md={3}>
        <Grid>
          <div style={{ position: 'absolute', height: 0 }}>
            <input
              type="text"
              name="username"
              style={{
                height: 0,
                width: 0,
                border: 'none',
                outline: 'none',
                margin: 0,
                padding: 0,
              }}
            />
            <input
              type="password"
              name="password"
              style={{
                height: 0,
                width: 0,
                border: 'none',
                outline: 'none',
                margin: 0,
                padding: 0,
              }}
            />
          </div>
          <GridItem sm={12}>
            <CampoTexto
              instanceId='titulo'
              value={value?.titulo ?? ''}
              onChange={v => onChange({ ...value, titulo: v })}
              label="Título"
              error={fieldErrors?.titulo}
            />
          </GridItem>
          <GridItem sm={4}>
            <CampoSeletor
              instanceId='tipo_responsavel'
              noSearch
              value={tipoResponsavel}
              onChange={(v) => setTipoResponsavel(v as any)}
              label="Responsável"
              options={tipoOptions ?? []}
              placeholder='Selecione'
              error={fieldErrors?.tipo_responsavel}
            />
          </GridItem>
          <GridItem sm={8}>
            {(tipoResponsavel == 'usuario') && (<CampoSeletor
              instanceId='usuario_id'
              value={value?.usuario_id}
              onChange={v => onChange({ ...value, usuario_id: v, grupo_id: null })}
              label="Usuário"
              options={usuarioOptions ?? []}
              error={fieldErrors?.usuario_id}
            />)}
            {(tipoResponsavel == 'grupo') && (<CampoSeletor
              instanceId='grupo_id'
              value={value?.grupo_id}
              onChange={v => onChange({ ...value, grupo_id: v, usuario_id: null })}
              label="Grupo / Departamento"
              options={grupoOptions ?? []}
              error={fieldErrors?.grupo_id}
            />)}
          </GridItem>
          <GridItem sm={12} md={6}>
            <CampoNumero
              instanceId='antecedencia_min'
              value={value?.antecedencia_min?.toString()}
              onChange={v => onChange({ ...value, antecedencia_min: parseInt(v) })}
              label="Antecedência mínima"
              append="minutos"
              error={fieldErrors?.antecedencia_min}
            />
            <p style={{ color: 'var(--tc-color-gray-400)', fontStyle: 'italic' }}>0 = desabilitado</p>
          </GridItem>
          <GridItem sm={12} md={6}>
            <CampoNumero
              instanceId='antecedencia_max'
              value={value?.antecedencia_max ? (value.antecedencia_max * 60 * 24).toString() : '0'}
              onChange={v => onChange({ ...value, antecedencia_max: parseInt(v) / 60 / 24 })}
              label="Antecedência máxima"
              append="dias"
              error={fieldErrors?.antecedencia_max}
            />
            <p style={{ color: 'var(--tc-color-gray-400)', fontStyle: 'italic' }}>0 = desabilitado</p>
          </GridItem>
          <GridItem sm={12}>
            <CampoTexto
              instanceId='local'
              value={value?.local ?? ''}
              onChange={v => onChange({ ...value, local: v })}
              label="Local"
              error={fieldErrors?.local}
            />
          </GridItem>
          <GridItem sm={12}>
            <CampoMemo
              instanceId='obs'
              value={value?.obs ?? ''}
              onChange={v => onChange({ ...value, obs: v })}
              label="Observações"
              error={fieldErrors?.obs}
            />
          </GridItem>
          <GridItem sm={12}>
            <CampoTexto
              type='tel'
              instanceId="avisar_minutos"
              value={value?.avisar_minutos}
              onChange={v => {
                onChange({
                  ...value, avisar_minutos: v
                });
              }}
              label="Avisar"
              append="min.&nbsp;antes"
              max={60 * 24}
              min={0}
            />
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem sm={12} md={9}>
        <div>Horários</div>
        <Botao variant='outline-primary' onClick={() => {
          onChange({
            ...value,
            horarios: [
              ...value?.horarios ?? [],
              { horario_inicio: '08:00', horario_fim: '18:00', capacidade: 1, duracao_minutos: 30, seg: false, ter: false, qua: false, qui: false, sex: false, sab: false, dom: false },
            ],
          });
        }}>Adicionar</Botao>

        {value?.horarios?.map((h: any, i: number) => {
          const hft = parse(h.horario_fim.substring(0, 5), 'HH:mm', new Date()).getTime()
          const hit = parse(h.horario_inicio.substring(0, 5), 'HH:mm', new Date()).getTime()
          const hasGap = (((hft - hit) / 1000 / 60) % h.duracao_minutos) !== 0;
          const sugestoesFim = [
            new Date(hit + (Math.floor((hft - hit) / 1000 / 60 / h.duracao_minutos) * h.duracao_minutos * 60 * 1000)),
            //next time slot
            new Date(hit + (Math.floor((hft - hit) / 1000 / 60 / h.duracao_minutos) * h.duracao_minutos * 60 * 1000) + h.duracao_minutos * 60 * 1000),
          ];

          return (
            <Grid key={i}>
              <GridItem sm={11}>
                <Grid>
                  <GridItem sm={12}>
                    <label>Dias da semana</label>
                    <Botoes
                      options={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                      value={[
                        h?.dom,
                        h?.seg,
                        h?.ter,
                        h?.qua,
                        h?.qui,
                        h?.sex,
                        h?.sab,
                      ]}
                      onChange={v => {
                        onChange({
                          ...value, horarios: [
                            ...value?.horarios?.slice(0, i),
                            { ...h, dom: v[0], seg: v[1], ter: v[2], qua: v[3], qui: v[4], sex: v[5], sab: v[6] },
                            ...value?.horarios?.slice(i + 1),
                          ]
                        })
                      }}
                    />
                  </GridItem>
                  <GridItem sm={12} md={6} lg={3}>
                    <CampoData
                      type="time"
                      instanceId='horario_inicio'
                      value={h.horario_inicio}
                      onChange={v => {
                        onChange({
                          ...value, horarios: [
                            ...value?.horarios?.slice(0, i),
                            { ...h, horario_inicio: v },
                            ...value?.horarios?.slice(i + 1),
                          ]
                        });
                      }}
                      label='Início'
                    />
                  </GridItem>
                  <GridItem sm={12} md={6} lg={3}>
                    <HBox style={{ alignItems: 'center', justifyContent: "center" }}>
                      <CampoData
                        type="time"
                        instanceId='horario_fim'
                        value={h.horario_fim}
                        onChange={v => {
                          onChange({
                            ...value, horarios: [
                              ...value?.horarios?.slice(0, i),
                              { ...h, horario_fim: v },
                              ...value?.horarios?.slice(i + 1),
                            ]
                          });
                        }}
                        label='Fim'
                      />
                      {hasGap && <Tooltip position='top' text={'Este horário não está alinhado com a duração da sessão. Sugestões: ' + sugestoesFim.map((s: Date) => s && format(s, 'HH:mm')).join(', ')}><Icon path={mdiAlert} size={1} color='red' /></Tooltip>}
                    </HBox>
                  </GridItem>
                  <GridItem sm={12} md={6} lg={3}>
                    <CampoTexto
                      type='tel'
                      instanceId="duracao_minutos"
                      value={h.duracao_minutos}
                      onChange={v => {
                        onChange({
                          ...value, horarios: [
                            ...value?.horarios?.slice(0, i),
                            { ...h, duracao_minutos: v },
                            ...value?.horarios?.slice(i + 1),
                          ]
                        });
                      }}
                      label="Dur. sessão"
                      append="min."
                      max={99}
                      min={0}
                    />
                  </GridItem>
                  <GridItem sm={12} md={6} lg={3}>
                    <CampoTexto
                      type='tel'
                      instanceId="capacidade"
                      value={h.capacidade}
                      onChange={v => {
                        onChange({
                          ...value, horarios: [
                            ...value?.horarios?.slice(0, i),
                            { ...h, capacidade: v },
                            ...value?.horarios?.slice(i + 1),
                          ]
                        });
                      }}
                      label="Capacidade"
                      append={h.capacidade == 1 ? "pessoa" : "pessoas"}
                      max={99}
                      min={0}
                    />
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem sm={1}>
                <Botao variant='outline-danger' onClick={() => {
                  onChange({
                    ...value,
                    horarios: value?.horarios?.filter((_, j) => j !== i),
                  });
                }}
                  icon={mdiTrashCanOutline}></Botao>

                <Botao variant='outline-secondary' onClick={() => {
                  const newHorario = { ...h }
                  onChange({
                    ...value,
                    horarios: [
                      ...value?.horarios,
                      newHorario,
                    ],
                  });
                }}
                  icon={mdiContentCopy}></Botao>
              </GridItem>
            </Grid>
          )
        })}
      </GridItem>
    </Grid>
  );
};
