import React from 'react';
import styles from './ConteudoImagem.module.css';
import Modal from '../../../components/layout/Modal';

export const ConteudoImagem = ({ value }: { value: string }) => {
    const [showImg, setShowImg] = React.useState<boolean>(false);

    const path = value.split('/');
    const filename = path[path.length - 1];
    const dir = path.slice(0, path.length - 1).join('/');

    const result = [dir, encodeURIComponent(filename)].join('/');

    return (
        <>
            <Modal title="Imagem" show={showImg} onClose={() => setShowImg(false)} hideControls>
                <div className={[styles.fullImgContainer, 'rr-block'].join(' ')}>
                    <img className={styles.fullImg} src={`/api/assets${result}`} alt="imagem" />
                </div>
            </Modal>

            <div className={styles.container} onClick={() => setShowImg(true)}>
                <img className={[styles.img, 'rr-block'].join(' ')} src={`/api/assets${result}`} alt="imagem" />
            </div>
        </>
    )
}