import HBox from '@/components/layout/HBox';
import VBox from '@/components/layout/VBox';
import Subtitulo from '@/components/tipografia/Subtitulo';
import { mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { VendaDto } from '@tera/shared/src/types/VendaDto';
import { Droppable } from 'react-beautiful-dnd';
import { CardVenda } from './CardVenda';

const numFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const getListStyle = (isDraggingOver: boolean, canDrop: boolean) => ({
  background: isDraggingOver ? 'lightblue' : canDrop ? undefined : '#DDD',
  minHeight: '200px',
});

type RaiaProps = {
  nome: string;
  titulo: string;
  corTitulo?: string;
  value: VendaDto[];
  transicoesAvancar: any[];
  transicoesVoltar: any[];
  disableControls?: boolean;
  onChange: (value: VendaDto) => void;
  onEdit: (venda: VendaDto) => void;
  onDelete: (venda: VendaDto) => void;
};

export const Raia = ({
  nome,
  titulo,
  corTitulo,
  value,
  transicoesAvancar,
  transicoesVoltar,
  disableControls,
  onChange,
  onEdit,
  onDelete,
}: RaiaProps) => {
  return (
    <VBox
      style={{
        border: '1px solid #DDD',
        maxWidth: '300px',
        padding: '4px',
        minHeight: '100%',
        height: 'auto',
      }}
    >
      <VBox style={{ maxHeight: '80px' }}>
        <HBox stretch>
          <Subtitulo style={{ color: corTitulo }}>{nome}</Subtitulo>
          <Icon
            path={mdiChevronRight}
            size={2}
            color="var(--tc-color-gray-400)"
          />
        </HBox>
        <p>
          {numFormatter.format(
            value
              ?.map(v => +v.valor_total)
              ?.reduce((acc: number, cur: number) => acc + cur, 0) ?? 0,
          )}
        </p>
      </VBox>
      <VBox
      >
        {value?.map((v: VendaDto, index: number) => (
          <CardVenda
            key={v.id}
            venda={v}
            transicoesAvancar={transicoesAvancar}
            transicoesVoltar={transicoesVoltar}
            disableControls={disableControls}
            index={index}
            isFirst={index === 0}
            isLast={index === value.length - 1}
            onChange={onChange}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </VBox>
    </VBox>
  );
};
