import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import { mdiCloud, mdiCloudAlert, mdiCloudCheck, mdiCloudMinus, mdiCloudPlus, mdiCloudRefresh, mdiCloudSync, mdiGoogle, mdiLoading, mdiLock, mdiLockMinus, mdiLockOff, mdiLockPlus, mdiLockReset, mdiMagnify, mdiPen, mdiPlus, mdiTrashCan } from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import axios from 'axios';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import { useDebounce } from '../chat/useDebounce';
import { toast } from 'react-toastify';
import Modal from '../../components/layout/Modal';
import { FormAgenda } from './FormAgenda';
import Grid, { GridItem } from '@/components/layout/Grid';
import { useAuth } from '@/lib/useAuth';
import { AgendaIntegracaoTipo, AgendaIntegracaoTipoDescritor } from '@tera/shared/src/dto/AgendaDto';
import CampoSeletor from '@/components/Formulario/CampoSeletor';
import { PopupCenter } from '@/lib/popup';
import { queryClient } from '@/queryClient';

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type usuarioPageProps = {};

const usuarioPage = ({ }: usuarioPageProps) => {
  const { user } = useAuth();

  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const [valueIntegracao, setValueIntegracao] = React.useState<any>(null);
  const handleCloseModalIntegracao = () => setValueIntegracao(null);
  const [autenticandoOauth2, setAutenticandoOauth2] = React.useState(false);

  const [calendarios, setCalendarios] = React.useState<any[]>([]);


  const qCalendarios = useQuery({
    queryKey: ['agenda/calendarios'],
    queryFn: async () => {
      const res = await axios.get(`/api/agenda/${valueIntegracao.id}/calendars`, {
        withCredentials: true,
      });
      return res.data;
    },
    enabled: Boolean(valueIntegracao?.id),
  })

  const handleIntegracao = useCallback(() => {
    if (valueIntegracao?.integracao_tipo === AgendaIntegracaoTipo.GOOGLE) {
      handleGCalSync(valueIntegracao.id);
      setAutenticandoOauth2(true);
    } else if (valueIntegracao?.integracao_tipo === AgendaIntegracaoTipo.ZOHO) {
      handleZohoSync(valueIntegracao.id);
      setAutenticandoOauth2(true);
    }
  }, [valueIntegracao])

  const handleDeleteIntegracao = useCallback(async () => {
    if (!valueIntegracao) return;

    axios.delete(`/api/agenda/${valueIntegracao?.id}/token`).then(() => {
      queryList.refetch();
      setValueIntegracao(null);
    });
  }, [valueIntegracao])

  const handleOpenModalIntegracaoNewObject = (value: any) => {
    setValueIntegracao({
      id: value?.id,
      integracao_tipo: value?.integracao_tipo,
      integracao_agenda_id: value?.integracao_agenda_id,
      titulo: value?.titulo,
      integracao_token_presente: !!value?.integracao_token_presente
    });
  };

  const handleGCalSync = async (id: number) => {
    const response = await axios.get(`/api/agenda/gogl/auth/url`)

    const url = response.data.result;

    if (url) {
      const authWindow = PopupCenter(url, 'Google Calendar', 600, 600);
      const handler = async (event: any) => {
        if (event.origin !== window.location.origin) {
          console.warn('different origin', event.origin, window.location.origin)
        }

        if (event.data?.type === "oauth_code") {
          console.log("Código OAuth recebido:", event.data.code);

          const res = await axios.post(`/api/agenda/${id}/auth/token`, { code: event.data.code, type: 'gogl' });
          queryClient.invalidateQueries('agenda/calendarios');

          setValueIntegracao((prev: any) => ({ ...prev, integracao_token_presente: true, integracao_agenda_id: res.data.integracao_agenda_id }));

          window.removeEventListener("message", handler);

          setAutenticandoOauth2(false);
          queryClient.invalidateQueries(['agenda'])
        }
        return true
      }
      const unloadHandler = () => {
        setAutenticandoOauth2(false);
        return true
      }
      window.addEventListener("message", handler);
      authWindow!.addEventListener("unload", unloadHandler)
    }
  }

  const handleZohoSync = async (id: number) => {
    const response = await axios.get(`/api/agenda/zoho/auth/url`)

    const url = response.data.result;

    if (url) {
      const authWindow = PopupCenter(url, 'Zoho Calendar', 600, 600);
      const handler = async (event: any) => {
        if (event.origin !== window.location.origin) {
          console.warn('different origin', event.origin, window.location.origin)
        }

        if (event.data?.type === "oauth_code") {
          console.log("Código OAuth recebido:", event.data.code);

          const res = await axios.post(`/api/agenda/${id}/auth/token`, { code: event.data.code, type: 'zoho' });
          queryClient.invalidateQueries('agenda/calendarios');
          setValueIntegracao((prev: any) => ({ ...prev, integracao_token_presente: true, integracao_agenda_id: res.data.integracao_agenda_id }));

          window.removeEventListener("message", handler);

          setAutenticandoOauth2(false);
          queryClient.invalidateQueries(['agenda'])
        }

        return true
      }

      const unloadHandler = () => {
        setAutenticandoOauth2(false);
        return true
      }
      window.addEventListener("message", handler);
      authWindow!.addEventListener("unload", unloadHandler)
    }
  }

  const integracaoAgendaIdMutator = useMutation({
    mutationFn: async (v: any) => {
      await axios.put(`/api/agenda/${valueIntegracao.id}`, { integracao_agenda_id: v });
    },
    onSuccess: (data, vars) => {
      setValueIntegracao({ ...valueIntegracao, integracao_agenda_id: vars });
    }
  })

  const handleChangeIntegracaoAgendaId = useCallback(async (v: any) => {
    if (!valueIntegracao) return;

    integracaoAgendaIdMutator.mutate(v);
  }, [valueIntegracao])

  const headers: DataTableHeader[] = [
    { label: 'Título', width: '50%', column: 'titulo' },
    {
      label: 'Ações',
      width: '100px',
      stickRight: true,
      alignRight: true,
      render: (row: any) => {
        return (
          <HBox gap="8px" style={{ justifyContent: 'flex-end' }}>
            <Botao
              title="Editar"
              variant="none-primary"
              icon={mdiPen}
              onClick={() => setEditObject(row)}
            />
            <BotaoPerigoso
              buttonProps={{ title: 'Apagar' }}
              variant="none-danger"
              icon={mdiTrashCan}
              onClick={() => handleDelete(row.id)}
            />
          </HBox>
        );
      },
    },
  ];

  const dias = ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'];

  const headersAdm: DataTableHeader[] = [
    { label: 'Título', width: '20%', column: 'titulo' },
    { label: 'Usuário responsável', width: '25%', render: (row) => row.usuario?.nome ?? '--' },
    { label: 'Grupo responsável', width: '25%', render: (row) => row.grupo?.nome ?? '--' },
    { label: 'Integração', width: '10%', render: (row) => AgendaIntegracaoTipoDescritor[row.integracao_tipo] ?? '--' },
    {
      label: 'Horários', width: '25%', render: (row) =>
        row.horarios
          ?.map((h: any) => <div>
            {[h.seg, h.ter, h.qua, h.qui, h.sex, h.sab, h.dom].map((it, idx) => it ? dias[idx] : null).filter(Boolean).join(' ')}, {h.horario_inicio.substring(0, 5)}-{h.horario_fim.substring(0, 5)}
          </div>) ?? '--'
    },
    {
      label: 'Ações',
      width: '100px',
      stickRight: true,
      alignRight: true,
      render: (row: any) => {
        return (
          <HBox gap="8px" style={{ justifyContent: 'flex-end' }}>
            <Botao
              title="Editar"
              variant="none-primary"
              icon={mdiPen}
              onClick={() => setEditObject(row)}
            />
            <Botao
              title="Integração com agendas externas"
              variant="none-info"
              icon={mdiCloud}
              onClick={() => handleOpenModalIntegracaoNewObject(row)}
            />
            <BotaoPerigoso
              buttonProps={{ title: 'Apagar' }}
              variant="none-danger"
              icon={mdiTrashCan}
              onClick={() => handleDelete(row.id)}
            />
          </HBox>
        );
      },
    },
  ];

  const queryList = useInfiniteQuery({
    queryKey: ['agenda', debouncedSearch],
    queryFn: async ({ pageParam }) => {
      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam,
      });

      return await axios.get<ResponseList>(
        `/api/agenda?${params.toString()}`,
        {
          withCredentials: true,
        },
      );
    },
    getNextPageParam: lastPage => lastPage.data.cursor ?? null,
  });

  const changeMutator = useMutation(
    async () => {
      if (!editObject) return;

      setFieldErrors(null);
      if (editObject.id) {
        return await axios.put(
          `/api/agenda/${editObject.id}`,
          editObject,
          {
            withCredentials: true,
          },
        );
      } else {
        return await axios.post(`/api/agenda`, editObject, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);

        if (!editObject.id) {
          toast('Agenda adicionada com sucesso', {
            toastId: 'add-success',
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast('Agenda alterada com sucesso', {
            toastId: 'edit-success',
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
        }
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'add-error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/agenda/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Agenda apagada com sucesso', {
          toastId: 'del-success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar a agenda.', {
          toastId: 'del-error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate();
  }, [changeMutator]);

  const handleDelete = (id: number) => {
    deleteMutator.mutate(id);
  };

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({});
  };

  const queryFlatList =
    queryList.data?.pages?.map(p => p.data.result)?.flat() ?? [];
  const total =
    queryList.data?.pages?.[queryList.data.pages.length - 1]?.data?.total ?? 0;
  const editMode = !!editObject?.id && editObject.id > 0;

  const integracaoOptions = Object.entries(AgendaIntegracaoTipoDescritor).map(([id, label]) => ({ id, label }))

  const integracaoAgendaOptions: any[] = []

  return (
    <LayoutUsuario>
      <Modal
        hideControls
        closeable={!autenticandoOauth2}
        instanceId='modal-integracao'
        title="Integrar agenda"
        show={valueIntegracao != null}
        onClose={handleCloseModalIntegracao}
        onCancel={handleCloseModalIntegracao}
        onConfirm={handleCloseModalIntegracao}
        ignoreOverlayClick={autenticandoOauth2}
      >
        <CampoTexto
          readOnly
          disabled
          instanceId='agenda_titulo'
          label="Agenda"
          defaultValue={valueIntegracao?.titulo ?? '--'}
        />
        {!valueIntegracao?.integracao_token_presente && (
          <VBox>
            <CampoSeletor
              noSearch
              instanceId='integracao'
              value={valueIntegracao?.integracao_tipo}
              onChange={v => setValueIntegracao({ ...valueIntegracao, integracao_tipo: v })}
              label="Integrar com"
              placeholder='Selecione uma opção'
              options={integracaoOptions}
              error={fieldErrors?.integracao_tipo}
              disabled={autenticandoOauth2}
            />
            <Botao
              variant='outline-primary'
              onClick={handleIntegracao}
              disabled={!valueIntegracao?.integracao_tipo || autenticandoOauth2}
            >
              Autenticar
            </Botao>
          </VBox>
        )}
        {valueIntegracao?.integracao_token_presente && (
          <>
            <label>Integrado com</label>
            <VBox>
              <CampoTexto
                readOnly
                disabled
                instanceId='integracao_tipo'
                defaultValue={integracaoOptions.find(it => it.id === valueIntegracao?.integracao_tipo)?.label ?? '--'}
              />
              <CampoSeletor
                noSearch
                instanceId='integracao_agenda_id'
                value={valueIntegracao?.integracao_agenda_id}
                onChange={handleChangeIntegracaoAgendaId}
                label="Calendário"
                placeholder='Selecione uma opção'
                options={qCalendarios.data?.result}
                error={fieldErrors?.integracao_agenda_id}
                disabled={!valueIntegracao?.integracao_tipo || autenticandoOauth2}
              />
              {integracaoAgendaIdMutator.isLoading && (
                <HBox style={{ color: 'var(--tc-color-gray-500)', fontStyle: 'italic' }}>
                  <Icon path={mdiCloudRefresh} size={0.8} />
                  Salvando...
                </HBox>
              )}
              {integracaoAgendaIdMutator.isSuccess && (
                <HBox style={{ color: 'var(--tc-color-gray-500)', fontStyle: 'italic' }}>
                  <Icon path={mdiCloudCheck} size={0.8} />
                  Salvo!
                </HBox>
              )}
              {integracaoAgendaIdMutator.isError && (
                <HBox style={{ color: 'var(--tc-color-gray-500)', fontStyle: 'italic' }}>
                  <Icon path={mdiCloudAlert} size={0.8} />
                  Erro
                </HBox>
              )}
              <HBox>
                <Botao variant='outline-primary' icon={mdiLock} onClick={handleIntegracao}>
                  Reautenticar
                </Botao>
                <BotaoPerigoso
                  variant='outline-danger'
                  icon={mdiLockOff}
                  onClick={handleDeleteIntegracao}
                  confirmation={<>
                    <p>As agendas não serão mais sincronizadas, e os eventos desta agenda serão removidos.</p>
                    <p>Tem certeza que deseja desconectar?</p>
                  </>}
                >
                  Desconectar
                </BotaoPerigoso>
              </HBox>
            </VBox>
          </>
        )}
      </Modal>
      <Modal
        size='lg'
        instanceId='modal-usuario-agenda'
        ignoreOverlayClick
        title={editMode ? 'Editar agenda' : 'Nova agenda'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
      >
        <FormAgenda
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
      </Modal>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <Grid>
          <GridItem sm={12} md={6}>
            <VBox>
              <Titulo>Agendas</Titulo>
              <Descricao>
                Gerencie aqui as agendas disponíveis de seus usuários e grupos para atendimento
              </Descricao>
            </VBox>
          </GridItem>
          <GridItem sm={12} md={6}>
            <HBox gap="12px" className={styles.headerAcoes}>
              <Botao
                variant="primary"
                icon={mdiPlus}
                onClick={handleOpenModalNewObject}
              >
                Nova
              </Botao>
            </HBox>
          </GridItem>
        </Grid>

        {/* Filtros */}
        <HBox>
          <CampoTexto
            prepend={
              <Icon
                path={mdiMagnify}
                size={1}
                color="var(--tc-color-gray-500)"
              />
            }
            placeholder="Pesquisar"
            value={search}
            onChange={setSearch}
          />
        </HBox>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryFlatList?.length ?? '0'} de {total ?? '0'}{' '}
            resultados.
          </div>
        )}

        <DataTable
          isLoading={queryList.isFetching}
          cabecalhos={user?.papel === 'USUARIO' ? headers : headersAdm}
          linhas={queryFlatList ?? []}
          hasMoreData={queryList.hasNextPage}
          onLoadMore={async () => queryList.fetchNextPage()}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default usuarioPage;
