export const AchievementsKeys = {
    FAST_TRIGGER: 'fastTrigger',            //
    IM_HERE: 'imHere',                      //
    CHAT_MARATHON: 'chatMarathon',          //
    SOLUTION_EXPERT: 'solutionExpert',      //
    CHAT_EXPERT: 'chatExpert',              //
    CHAT_GURU: 'chatGuru',                  //
    PATRIOT_SAVIOR: 'patriotSavior',        //
    IMMEDIATE_ANSWER: 'immediateAnswer',    //
    GRADE_A_PLUS: 'gradeAPlus',             //
    FIVE_STARS: 'fiveStars',                //
    POP_STAR: 'popStar',                    //
    CONSTELATION: 'constelation',           //
} as const

export const achievements: Record<string, any> = {
    fastTrigger: {
        titulo: 'Rápido no gatilho',
        descricao: 'Você atendeu o cliente em menos de 5 segundos',
        icone: 'mdi-speedometer',
        limiarMax: 5000,
        unidade: 'ms'
    },
    imHere: {
        titulo: 'Presente!',
        descricao: 'Você esteve presente em mais de 20 dias no último mês',
        icone: 'mdi-calendar-check',
        limiarMin: 20,
    },
    chatMarathon: {
        titulo: 'Maratonista do chat',
        descricao: 'Você atendeu 50 clientes em um único dia',
        icone: 'mdi-account-multiple',
        limiarMin: 50,
    },
    solutionExpert: {
        titulo: 'Especialista em soluções',
        descricao: 'Você fechou 100 chats como solucionados',
        icone: 'mdi-check-circle',
        limiarMin: 100,
    },
    chatExpert: {
        titulo: 'Especialista em chat',
        descricao: 'Você atendeu 1.000 clientes',
        icone: 'mdi-account-multiple',
        limiarMin: 1000,
    },
    chatGuru: {
        titulo: 'Guru do chat',
        descricao: 'Você atendeu 10.000 clientes',
        icone: 'mdi-account-multiple',
        limiarMin: 10000,
    },
    patriotSavior: {
        titulo: 'Salvador da pátria',
        descricao: 'Assumiu o atendimento de um colega e solucionou o caso',
        icone: 'mdi-account-multiple',
        limiarMin: 1,
    },
    immediateAnswer: {
        titulo: 'Resposta relâmpago',
        descricao: 'Você respondeu ao cliente em menos de 10 segundos após abrir atendimento',
        icone: 'mdi-speedometer',
        limiarMax: 10000,
        unidade: 'ms'
    },
    gradeAPlus: {
        titulo: 'Nota 1000',
        descricao: 'Mantenha uma média de 90% de satisfação nos últimos 3 meses para mais de 30 atendimentos',
        icone: 'mdi-star',
        limiarMin: 90,
        unidade: '%'
    },
    constelation: {
        titulo: 'Constelação de estrelas',
        descricao: 'Alcande um total de 1.000 avaliações com pontuação máxima (100%)',
        icone: 'mdi-star',
        limiarMin: 1000
    },
    fiveStars: {
        titulo: '5 estrelas',
        descricao: 'Alcande um total de 20 avaliações com pontuação máxima (100%)',
        icone: 'mdi-star',
        limiarMin: 20
    },
    popStar: {
        titulo: 'Pop Star',
        descricao: 'Receba 5 estrelas de 10 clientes diferentes em um único dia',
        icone: 'mdi-star',
        limiarMin: 10
    },
}