import axios from "axios";

export function subscribeUserToPush(pubKey: string) {
    return navigator.serviceWorker
        .register('/service-worker.js')
        .then(function (reg) {
            let serviceWorker;
            if (reg.installing) {
                serviceWorker = reg.installing;
                // console.log('Service worker installing');
            } else if (reg.waiting) {
                serviceWorker = reg.waiting;
                serviceWorker?.postMessage({ type: "SKIP_WAITING" })

                // console.log('Service worker installed & waiting');
            } else if (reg.active) {
                serviceWorker = reg.active;
                // console.log('Service worker active');
            }

            if (serviceWorker) {
                console.log('sw current state', serviceWorker.state);
                if (serviceWorker.state === 'activated') {
                    //If push subscription wasnt done yet have to do here
                    console.log('sw already activated - Do watever needed here');

                    const subscribeOptions = {
                        userVisibleOnly: true,
                        applicationServerKey: pubKey,
                    };

                    reg.pushManager
                        .subscribe(subscribeOptions)
                        .then(function (pushSubscription) {
                            axios.post('/api/notificacao', { token: pushSubscription })
                                .then(res => {
                                    console.log(res);
                                });
                        });
                }
                serviceWorker.addEventListener('statechange', function (e: any) {
                    console.log('sw statechange : ', e.target.state);
                    if (e.target.state === 'activated') {
                        // use pushManger for subscribing here.
                        console.log(
                            'Just now activated. now we can subscribe for push notification',
                        );
                        const subscribeOptions = {
                            userVisibleOnly: true,
                            applicationServerKey: pubKey,
                        };

                        reg.pushManager
                            .subscribe(subscribeOptions)
                            .then(function (pushSubscription) {
                                axios.post('/api/notificacao', { token: pushSubscription })
                                    .then(res => {
                                        console.log(res);
                                    });
                            });
                    }
                });
            }
        });
}