import portProps from '@tera/shared/src/fluxoConfig/ports/string'

type Props = {
  value: string | null
  onChange: (value: string) => void
}

const Port = ({ value, onChange }: Props) => {
  const taAdjustHeight = (elem: HTMLTextAreaElement) => {
    const ta = elem;
    ta.style.height = '0';
    ta.style.height = ta.scrollHeight + 2 + 'px';
  }

  return <textarea
    style={{ width: '100%' }}
    value={value ?? ''}
    onChange={(e) => {
      onChange(e.target.value);
      taAdjustHeight(e.target);
    }}
  />
}

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />
  }
}

export default port