import { useEffect } from "react";

const OAuthCallback = () => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get("code");

        if (code) {
            console.log("Código OAuth obtido:", code);

            // Envia o código OAuth para a janela pai
            if (!window.opener) {
                console.error('Janela pai não encontrada');
            }

            window.opener.postMessage({ type: "oauth_code", code }, "*");

            // Fecha a janela após enviar o código
            window.close();
        }
    }, []);

    return <p>Processando autenticação...</p>;
};

export default OAuthCallback;
