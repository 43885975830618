import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback, useMemo } from 'react';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import Icon from '@mdi/react';
import { mdiCalendarCheck, mdiCalendarClock, mdiCalendarHeart, mdiCalendarOutline, mdiCalendarRemove, mdiCheckBold, mdiCheckboxBlankOutline, mdiClockOutline, mdiGoogle, mdiMagnify, mdiPen, mdiPlus, mdiSync, mdiTrashCan } from '@mdi/js';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import { useDebounce } from '../chat/useDebounce';
import { toast } from 'react-toastify';
import Modal from '../../components/layout/Modal';
import { format, parse, startOfWeek, getDay, addDays, isBefore, isAfter } from 'date-fns';
import { FormAtividade } from './FormAtividade';

import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import ptBR from 'date-fns/locale/pt-BR'

import styles from './page.module.css';
import { subscribeUserToPush } from '@/lib/webpush';
import { queryClient } from '@/queryClient';
import Grid, { GridItem } from '@/components/layout/Grid';
import CampoTexto from '@/components/Formulario/CampoTexto';
import CampoMultiSeletor from '@/components/Formulario/CampoMultiSeletor';

const pubKey = import.meta.env.VITE_PUB_KEY;

const locales = {
  'pt-BR': ptBR,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

const CustomEvent = ({ event }: any) => {
  return (
    <HBox>
      {event.estado === 'pendente' && <Icon path={mdiCalendarClock} size={0.75} color='var(--tc-color-gray-400' />}
      {event.estado === 'confirmada' && <Icon path={mdiCalendarHeart} size={0.75} color='var(--tc-color-success-500' />}
      {event.estado === 'concluida' && <Icon path={mdiCalendarCheck} size={0.75} color='var(--tc-color-success-500' />}
      {event.estado === 'cancelada' && <Icon path={mdiCalendarRemove} size={0.75} color='var(--tc-color-danger-500' />}
      <span>{event.titulo}</span>
    </HBox>
  );
};

const today = new Date().toISOString().split('T')[0];
const tomorrow = addDays(new Date(), 1).toISOString().split('T')[0];

type Props = {};

const Page = ({ }: Props) => {
  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [filter, setFilter] = React.useState<Record<string, any>>({});
  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  async function askPermission() {
    Notification.requestPermission().then(function (result) {
      // setNotificationStatus(result);
      if (result !== 'granted') {
        console.warn("We weren't granted permission.");
      } else {
        subscribeUserToPush(pubKey);
      }
    });
  }

  const qAgendas = useQuery({
    queryKey: ['agenda/box'],
    queryFn: async () => {
      const res = await axios.get('/api/agenda/box');
      return res.data;
    }
  })

  const agendasOptions = useMemo(() => qAgendas.data?.result?.map((agenda: any) => ({
    id: agenda.id,
    label: agenda.titulo
  })), [qAgendas.data])

  const queryList = useQuery({
    queryKey: ['atividade'],
    queryFn: async () => {
      const params = new URLSearchParams({
        txt: debouncedSearch,
        fi: JSON.stringify(filter),
      });

      const r = await axios.get<ResponseList>(
        `/api/atividade?${params.toString()}`,
        {
          withCredentials: true,
        },
      );

      return r.data;
    },
  });

  const qtdAgendas = queryList.data?.result?.reduce((acc, atividade) => {
    if (atividade.id_agenda) {
      acc[atividade.id_agenda] = (acc[atividade.id_agenda] ?? 0) + 1;
    }
    return acc
  }, {});
  const totalAgendas = qtdAgendas ? Object.keys(qtdAgendas).length : 0;

  const eventos = useMemo(() => queryList.data?.result?.map(atividade => ({
    ...atividade,
    data_evento: format(new Date(atividade.data_evento), 'yyyy-MM-dd\'T\'HH:mm:ss'),
    data_fim_evento: format(new Date(atividade.data_fim_evento), 'yyyy-MM-dd\'T\'HH:mm:ss'),
    inicio_evento: new Date(atividade.data_evento),
    fim_evento: new Date(atividade.data_fim_evento),
  })), [queryList.data]);

  const changeMutator = useMutation(
    async (data: any) => {
      if (!data) return;

      setFieldErrors(null);
      if (data.id) {
        return await axios.put(
          `/api/atividade/${data.id}`,
          data,
          {
            withCredentials: true,
          },
        );
      } else {
        return await axios.post(`/api/atividade`, data, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: (data, vars) => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);
        toast(vars.id > 0 ? 'Atividade alterada com sucesso' : 'Atividade adicionada com sucesso', {
          toastId: 'sucsess',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });

        if (vars?.avisar_minutos > 0) {
          askPermission();
        }
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/atividade/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Atividade apagada com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        setEditObject(null);
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar a atividade.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate(editObject);
  }, [changeMutator]);

  const handleDone = useCallback(async (id: number) => {
    await changeMutator.mutate({ id, estado: 'concluida' });
  }, [changeMutator]);

  const handleRemove = useCallback(async (id: number) => {
    await deleteMutator.mutate(id);
  }, [deleteMutator]);

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({ sync: true });
  };

  const syncMutator = useMutation({
    mutationFn: async () => {
      await axios.post(`/api/agenda/sync`, {
        agendas: filter?.agendas?.[1]   //  agendas: ['in', [1, 2, 3]]...
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries('atividade');
    }
  })

  const handleSync = async () => {
    syncMutator.mutate();
  }

  const getEventBackgroundColor = (event: any) => {
    if (new Date(event.data_evento) < new Date(today))
      return 'var(--tc-color-gray-200)';

    if (event.id_agenda)
      return 'var(--tc-color-primary-100)';
    if (event.integracao_event_id)
      return 'var(--tc-color-tutorial-100)';
    return 'var(--tc-color-info-100)';
  }

  const getEventBorderColor = (event: any) => {
    if (new Date(event.data_evento) < new Date(today))
      return 'var(--tc-color-gray-300)';

    if (event.id_agenda)
      return 'var(--tc-color-primary-300)';
    if (event.integracao_event_id)
      return 'var(--tc-color-tutorial-300)';
    return 'var(--tc-color-info-300)';
  }

  const getEventColor = (event: any) => {
    return 'var(--tc-color-gray-800)'
  }

  return (
    <LayoutUsuario>
      <Modal
        instanceId='modal-atividade'
        title={editObject?.id > 0 ? 'Editar atividade' : 'Nova atividade'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
      >
        <FormAtividade
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
        {editObject?.id > 0 && (
          <div style={{ textAlign: 'end' }}>
            <BotaoPerigoso
              icon={mdiTrashCan}
              onClick={() => deleteMutator.mutate(editObject?.id)}
              disabled={editObject?.id <= 0}
              variant='danger'
              confirmation={editObject.integracao_event_id
                ? `Tem certeza? A atividade será apagada do calendário externo também.`
                : `Deseja realmente apagar esta atividade?`
              }>
              Apagar
            </BotaoPerigoso>
          </div>
        )}
      </Modal>

      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Minhas atividades</Titulo>
            <Descricao>
              Gerencie aqui suas atividades.
            </Descricao>
          </VBox>
          <HBox gap="12px" className={styles.headerAcoes}>
            <Botao
              variant='outline-info'
              icon={mdiSync}
              onClick={handleSync}
              disabled={syncMutator.isLoading}
              title={syncMutator.isLoading ? 'Sincronizando...' : 'Sincronizar agendas externas'}
            ></Botao>
            <Botao
              variant="primary"
              icon={mdiPlus}
              onClick={handleOpenModalNewObject}
            >
              Nova
            </Botao>
          </HBox>
        </HBox>

        {/* Filtros */}
        <Grid>
          <GridItem sm={12} lg={6}>
            <CampoTexto
              prepend={
                <Icon
                  path={mdiMagnify}
                  size={1}
                  color="var(--tc-color-gray-500)"
                />
              }
              placeholder="Pesquisar"
              value={search}
              onChange={setSearch}
            />
          </GridItem>

          <GridItem sm={12} lg={6}>
            <CampoMultiSeletor
              prepend={
                <Icon
                  path={mdiCalendarOutline}
                  size={1}
                  color="var(--tc-color-gray-500)"
                />
              }
              placeholder="Filtrar Agendas"
              value={filter.id_agenda?.[1]}
              onChange={(v) => setFilter({ ...filter, id_agenda: ['in', v] })}
              options={agendasOptions}
            />
          </GridItem>
        </Grid>

        <div>
          <Botao variant="primary" onClick={() => queryList.refetch()}>
            Aplicar
          </Botao>
        </div>
        {!queryList.isFetching && (
          <div>
            Encontrados {queryList.data?.result?.length ?? '0'} resultados de {totalAgendas ?? '0'} agendas.
          </div>
        )}

        {/* {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryList.data?.result?.length ?? '0'}{' '}
            resultados.
          </div>
        )} */}

        <Calendar
          style={{ height: 'calc(100% - 68px)' }}
          culture='pt-BR'
          messages={{
            today: 'Hoje',
            previous: 'Anterior',
            next: 'Próximo',
            month: 'Mês',
            week: 'Semana',
            day: 'Dia',
            agenda: 'Agenda',
            date: 'Data',
            time: 'Hora',
            event: 'Evento',
            allDay: 'Dia inteiro',
            work_week: 'Semana de trabalho',
            showMore: total => `+${total} mais`,
          }}
          localizer={localizer}
          events={eventos}
          startAccessor="inicio_evento"
          endAccessor="fim_evento"
          titleAccessor="titulo"
          onRangeChange={(range) => {
            console.log('onRangeChange', range);
          }}
          onSelecting={(range) => {
            console.log('onSelecting', range);
            return true
          }}
          onSelectEvent={(event) => {
            console.log('onSelectEvent', event);
            setEditObject({ ...event, sync: true });
          }}
          onSelectSlot={(slotInfo) => {
            console.log('onSelectSlot', slotInfo);
          }}
          defaultView='week'
          enableAutoScroll
          scrollToTime={new Date()}
          components={{
            event: CustomEvent
          }}
          eventPropGetter={(event) => {
            return {
              style: {
                backgroundColor: getEventBackgroundColor(event),
                borderColor: getEventBorderColor(event),
                color: getEventColor(event),
                borderStyle: event.id_grupo ? 'dotted' : 'solid',
                borderWidth: '2px',
              }
            }
          }}
        />

      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
