import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import { mdiLoading, mdiMagnify, mdiPen, mdiPlus, mdiTrashCan } from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery, useMutation } from 'react-query';
import axios from 'axios';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import { useDebounce } from '../chat/useDebounce';
import { toast } from 'react-toastify';
import Modal from '../../components/layout/Modal';
import Grid, { GridItem } from '@/components/layout/Grid';
import { FormFunilVenda } from './FormFunilVenda';
import { queryClient } from '@/queryClient';

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type usuarioPageProps = {};

const usuarioPage = ({ }: usuarioPageProps) => {
  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);
  const [showEditModal, setShowEditModal] = React.useState(false);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const headers: DataTableHeader[] = [
    { label: 'Nome', width: '50%', column: 'nome' },
    {
      label: 'Ações',
      width: '100px',
      stickRight: true,
      alignRight: true,
      render: (row: any) => {
        return (
          <HBox gap="8px" style={{ justifyContent: 'flex-end' }}>
            <Botao
              title="Editar"
              variant="none-primary"
              icon={mdiPen}
              onClick={() => {
                setEditObject(row)
                setShowEditModal(true)
              }}
            />
            <BotaoPerigoso
              buttonProps={{ title: 'Apagar' }}
              variant="none-danger"
              icon={mdiTrashCan}
              onClick={() => handleDelete(row.id)}
            />
          </HBox>
        );
      },
    },
  ];

  const queryList = useInfiniteQuery({
    queryKey: ['funil_venda', debouncedSearch],
    queryFn: async ({ pageParam }) => {
      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam,
      });

      return await axios.get<ResponseList>(
        `/api/funil_venda?${params.toString()}`,
        {
          withCredentials: true,
        },
      );
    },
    getNextPageParam: lastPage => lastPage.data.cursor ?? null,
  });

  const changeMutator = useMutation(
    async () => {
      if (!editObject) return;

      setFieldErrors(null);
      if (editObject.id) {
        return await axios.put(
          `/api/funil_venda/${editObject.id}`,
          { ...editObject },
          {
            withCredentials: true,
          },
        );
      } else {
        return await axios.post(`/api/funil_venda`, { ...editObject }, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setFieldErrors(null);
        queryClient.invalidateQueries(['funil_venda']);
        setEditObject(null);
        setShowEditModal(false);

        if (!editObject.id) {
          toast('Funil adicionado com sucesso', {
            toastId: 'add-success',
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast('Funil alterado com sucesso', {
            toastId: 'edit-success',
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
        }
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'add-error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/funil_venda/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Funil apagado com sucesso', {
          toastId: 'del-success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        queryClient.invalidateQueries(['funil_venda']);
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar o funil.', {
          toastId: 'del-error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['funil_venda']);
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate();
  }, [changeMutator]);

  const handleDelete = (id: number) => {
    deleteMutator.mutate(id);
  };

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
    setShowEditModal(false);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({});
    setShowEditModal(true);
  };

  const queryFlatList =
    queryList.data?.pages?.map(p => p.data.result)?.flat() ?? [];
  const total =
    queryList.data?.pages?.[queryList.data.pages.length - 1]?.data?.total ?? 0;
  const editMode = !!editObject?.id && editObject.id > 0;

  return (
    <LayoutUsuario>
      <Modal
        size='lg'
        instanceId='modal-funil-venda'
        ignoreOverlayClick
        title={editMode ? 'Editar funil' : 'Novo funil'}
        show={showEditModal}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
      >
        <FormFunilVenda
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
      </Modal>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <Grid>
          <GridItem sm={12} md={6}>
            <VBox>
              <Titulo>Funis de venda</Titulo>
              <Descricao>
                Gerencie seus funis de venda aqui
              </Descricao>
            </VBox>
          </GridItem>
          <GridItem sm={12} md={6}>
            <HBox gap="12px" className={styles.headerAcoes}>
              <Botao
                variant="primary"
                icon={mdiPlus}
                onClick={handleOpenModalNewObject}
              >
                Novo
              </Botao>
            </HBox>
          </GridItem>
        </Grid>

        {/* Filtros */}
        <HBox>
          <CampoTexto
            prepend={
              <Icon
                path={mdiMagnify}
                size={1}
                color="var(--tc-color-gray-500)"
              />
            }
            placeholder="Pesquisar"
            value={search}
            onChange={setSearch}
          />
        </HBox>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryFlatList?.length ?? '0'} de {total ?? '0'}{' '}
            resultados.
          </div>
        )}

        <DataTable
          isLoading={queryList.isFetching}
          cabecalhos={headers}
          linhas={queryFlatList ?? []}
          hasMoreData={queryList.hasNextPage}
          onLoadMore={async () => queryList.fetchNextPage()}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default usuarioPage;
