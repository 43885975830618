import React from 'react';
import styles from './FooterBloqueado.module.css';
import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';

type Props = {};

const FooterBloqueado = ({ }: Props) => {
    return (
        <div className={styles[`footer-status-bloqueado`]}>
            <div style={{ fontWeight: '600', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
                <Icon path={mdiCheckCircle} size="20px" />
                Cliente bloqueado
            </div>
            <div>
                Não é possível interagir com o cliente neste momento.
            </div>
        </div>
    )
};

export default FooterBloqueado;