import Icon from '@mdi/react';
import stylesBotao from './Botao.module.css';
import styles from './Botoes.module.css';
import HBox from './layout/HBox';
import { Link } from 'react-router-dom';

export type BotaoVariants =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'warning'
  | 'danger'
  | 'transparent'

const Botoes = ({
  options,
  value,
  onChange,
  variant = 'primary',
  disabled,
  ...props
}: Readonly<{
  options: string[];
  value: boolean[];
  onChange: (value: boolean[]) => void;
  variant?: BotaoVariants;
  disabled?: boolean;
  [key: string]: any;
}>) => {
  return (
    <HBox gap={0}>
      {options.map((option, idx) => {
        const selected = value[idx]
        return (
          <button
            className={[
              stylesBotao.button,
              styles.buttonGroup,
              stylesBotao[selected ? variant : `outline-${variant}`],
              idx === 0 ? styles.first : null,
              idx === options.length - 1 ? styles.last : null,
              disabled ? stylesBotao.disabled : null,
            ].join(' ')}
            disabled={disabled ?? false}
            type="button"
            onClick={() => {
              const newValue = [...value];
              newValue[idx] = !newValue[idx];
              onChange(newValue);
            }}
            {...props}
          >
            {option}
          </button>
        )
      })}
    </HBox>
  );
};
export default Botoes;
