import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "appointment",
  label: "Agenda",
  description: "Agenda",
  category: "Domínio",
  inputs() {
    return [{ type: "domainAppointment", name: "domainAppointment", label: "Agenda" }];
  },
  outputs() {
    return [
      { type: "number", name: "number", label: "ID" },
      { type: "string", name: "string", label: "Título" },
      { type: "number", name: "usuario_id", label: "ID usuário" },
      { type: "number", name: "grupo_id", label: "ID grupo" },
      { type: "string", name: "local", label: "Local" },
    ];
  },
  async resolveOutputs(inputs) {
    return {
      number: inputs.domainAppointment?.id,
      string: inputs.domainAppointment?.titulo,
      usuario_id: inputs.domainAppointment?.usuario_id,
      grupo_id: inputs.domainAppointment?.grupo_id,
      local: inputs.domainAppointment?.local,
    };
  },
};

export default instance;
