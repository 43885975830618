import React from 'react';
import styles from './TextoTelefone.module.css';
import { parseTelefone } from '../../lib/util';

type TextoTelefoneProps = {
    formatar?: boolean;
    value: string;
    ddd?: string;
    className?: string;
};

const TextoTelefone = ({ formatar, value, ddd, className }: TextoTelefoneProps) => {
    let telefone = value
    if (formatar) {
        telefone = parseTelefone(telefone, ddd)
    }

    return (
        <span className={[styles.container, className].join(' ')}>{telefone}</span>
    );
};

export default TextoTelefone;