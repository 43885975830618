import { record } from "rrweb";

const SESSION_SERVER_URL = window.location.protocol + '//' + window.location.host + "/api/logs/session";
console.warn('Session server url', SESSION_SERVER_URL);

let events: any[] = [];
let sessionId = `sess_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
let start = Date.now();

function reset() {
    start = Date.now();
    events = [];
}

export function startRecording() {
    start = Date.now();

    record({
        emit(event: any) {
            events.push(event);
        },
    });

    window.addEventListener('beforeunload', (e) => {
        sendEvents()
    });

    window.addEventListener('keypress', (event) => {
        record.addCustomEvent('keypress', {});
    })

    window.addEventListener('mousedown', (event) => {
        record.addCustomEvent('mousedown', {});
    })

    window.addEventListener("error", (event) => {
        record.addCustomEvent('error', {
            message: event?.error?.message || 'Erro desconhecido',
            stack: event?.error?.stack || '',
        });
    });

    window.addEventListener("unhandledrejection", (event) => {
        record.addCustomEvent('error-async', {
            message: event?.reason?.message || 'Rejeição não tratada',
            stack: event?.reason?.stack || '',
        });
    });
}

setInterval(sendEvents, 10000);

function sendEvents() {
    if (events.length === 0) return;

    const data = { sessionId, events, start, end: Date.now() };

    console.log("session data size", JSON.stringify(data).length, events.length, events.map((e) => JSON.stringify(e).length));

    // if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    //     setTimeout(() =>
    //         navigator.serviceWorker.controller?.postMessage({ type: "sess", payload: data })
    //     );
    //     reset();
    // } else {
    //     console.warn('No service worker, sending events via fetch');
    fetch(SESSION_SERVER_URL, {
        method: 'POST',
        body: new Blob([JSON.stringify(data)], { type: 'application/json' }),
        credentials: 'include',
    }).then(() => {
        reset();
    }).catch((err) => {
        console.error('Error sending events', err);
    });
    // }
}

export function getSessionId() {
    return sessionId;
}
