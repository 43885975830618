import styles from './FooterAtendimento.module.css';
import HBox from '../../../components/layout/HBox';
import Modal from '../../../components/layout/Modal';
import React, { useCallback, useEffect, useRef } from 'react';
import Botao from '../../../components/Botao';
import {
  mdiAccountGroup,
  mdiAttachment,
  mdiCircle,
  mdiInformationSlabCircle,
  mdiMessageBookmark,
  mdiMicrophone,
  mdiSendVariant,
  mdiTagPlus,
  mdiTrashCan,
} from '@mdi/js';
import { useDropzone } from 'react-dropzone';
import ArquivoAnexo from '../ArquivoAnexo';
import Icon from '@mdi/react';

import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';
import BotaoDropdown from '../../../components/BotaoDropdown';
import Titulo from '../../../components/tipografia/Titulo';
import VBox from '../../../components/layout/VBox';
import Subtitulo from '../../../components/tipografia/Subtitulo';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import CampoSeletor from '@/components/Formulario/CampoSeletor';
import { toast } from 'react-toastify';
import AlertBox from '@/components/layout/AlertBox';

type AtalhosBoxProps = {
  atalhosPessoais: any[];
  atalhosGlobais: any[];
  onSelectAtalho: (atalho: string) => void;
};

const AtalhosBox = ({
  atalhosGlobais,
  atalhosPessoais,
  onSelectAtalho,
}: AtalhosBoxProps) => {
  return (
    <div style={{ width: '50vw', color: 'var(--tc-color-gray-800)' }}>
      <p>
        <Icon
          path={mdiInformationSlabCircle}
          size="16px"
          color="var(--tc-color-primary)"
        />{' '}
        Para editar, vá em &quot;Atalhos de Mensagens&quot; do menu principal
      </p>
      <HBox style={{ height: '100%', alignItems: 'flex-start' }}>
        <VBox>
          <Subtitulo>Atalhos pessoais</Subtitulo>
          {atalhosPessoais?.map((atalho: any, idx: number) => (
            <button
              key={idx}
              type="button"
              className={styles.atalhoContainer}
              title={atalho.conteudo}
              onClick={() => {
                onSelectAtalho(atalho.conteudo);
              }}
            >
              <span>{atalho.atalho}</span>

              <HBox style={{ width: 'auto' }}>
                {atalho.grupo && (
                  <span>
                    <Icon
                      path={mdiAccountGroup}
                      size="16px"
                      color="var(--tc-color-gray-500)"
                    />
                  </span>
                )}
                {idx < 9 && (
                  <span>
                    <span className={styles.atalhoBadge}>Ctrl+{idx + 1}</span>
                  </span>
                )}
              </HBox>
            </button>
          ))}
        </VBox>
        <VBox>
          <Subtitulo>Atalhos gerais</Subtitulo>
          {atalhosGlobais?.map((atalho: any, idx: number) => (
            <button
              key={idx}
              type="button"
              className={styles.atalhoContainer}
              title={atalho.conteudo}
              onClick={() => {
                onSelectAtalho(atalho.conteudo);
              }}
            >
              {atalho.atalho}{' '}
              {idx < 9 && (
                <span className={styles.atalhoBadge}>Alt+{idx + 1}</span>
              )}
            </button>
          ))}
        </VBox>
      </HBox>
    </div>
  );
};

type FooterAtendimentoProps = {
  chatId: number;
  chatTags?: string[];
  onChatAddTag: (chatId: number, tagId: number) => void;
  onSend: (mensagem: string, anexos: File[]) => void;
  isSending: boolean;
  onSendAudio: (audio: Blob) => void;
};

const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const FooterAtendimento = ({
  chatId,
  chatTags,
  onChatAddTag,
  onSend,
  isSending,
  onSendAudio,
}: FooterAtendimentoProps) => {
  const [mensagem, setMensagem] = React.useState<string>('');
  const [showAttachments, setShowAttachments] = React.useState<boolean>(false);
  const [attachments, setAttachments] = React.useState<File[]>([]);
  const [recording, setRecording] = React.useState<boolean>(false);
  const [recordProgress, setRecordProgress] = React.useState<string>('00:00');

  const waveformRef = React.useRef<HTMLDivElement>(null);
  const wavesurfer = React.useRef<WaveSurfer | null>(null);
  const record = React.useRef<any>(null);

  const cancelled = React.useRef<boolean>(false);

  const [showAtalhos, setShowAtalhos] = React.useState<boolean>(false);

  const queryAtalhos = useQuery(['atalho'], async () => {
    const response = await axios.get('/api/chat_mensagem_atalho/box');

    return response.data;
  });

  const qTags = useQuery(['tags'], async () => {
    const response = await axios.get('/api/marcador/box');
    return response.data?.result;
  });

  const optionsTags = qTags.data
    ?.filter(
      (it: any) =>
        ['qualquer', 'chat'].includes(it.local) && !chatTags?.includes(it.nome),
    )
    ?.map((it: any) => ({
      id: it.id,
      label: it.nome,
    }));

  const atalhosPessoais = queryAtalhos.data?.result
    ?.filter((it: any) => !it.global)
    ?.sort((a: any, b: any) => a.indice - b.indice);
  const atalhosGlobais = queryAtalhos.data?.result
    ?.filter((it: any) => !!it.global)
    ?.sort((a: any, b: any) => a.indice - b.indice);

  React.useEffect(() => {
    if (!recording) return;

    if (waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        dragToSeek: true,
        normalize: false,
        waveColor: '#6f8090',
        progressColor: '#2f8719',
        cursorColor: '#2f8719',
        barWidth: 2,
        barGap: 4,
        barRadius: 2,
        height: 40,
        cursorWidth: 16,
      });

      record.current = wavesurfer.current.registerPlugin(
        RecordPlugin.create({
          scrollingWaveform: false,
          renderRecordedAudio: false,
        }),
      );
      record.current.on('record-end', (blob: Blob) => {
        if (cancelled.current) {
          cancelled.current = false;
          return;
        }

        // const recordedUrl = URL.createObjectURL(blob)
        // console.log('recorded', recordedUrl)
        onSendAudio(blob);
        setRecording(false);
      });

      record.current.on('record-progress', (time: number) => {
        console.log('progress', time);
        const formattedTime = [
          Math.floor((time % 3600000) / 60000), // minutes
          Math.floor((time % 60000) / 1000), // seconds
        ]
          .map(v => (v < 10 ? '0' + v : v))
          .join(':');

        setRecordProgress(formattedTime);
      });
    }
    const wavesurferInstance = wavesurfer.current;
    return () => {
      wavesurferInstance?.destroy();
    };
  }, [recording]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      for (const file of acceptedFiles) {
        if (file.size > MAX_FILE_SIZE) {
          toast('O tamanho máximo de arquivo é 10MB', {
            toastId: 'error',
            position: 'top-center',
            type: 'warning',
            autoClose: 5000,
          });
          return;
        }
      }

      setAttachments([...attachments, ...acceptedFiles]);
      setShowAttachments(false);
    },
    [attachments],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const campoMensagemRef = React.useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (!isSending) {
      setMensagem('');
      campoMensagemRef.current?.focus();
      setAttachments([]);
      setShowAttachments(false);
    }
  }, [isSending]);

  const handlePaste = (e: React.ClipboardEvent) => {
    const items: DataTransferItemList = e.clipboardData?.items;
    if (!items) return;

    const _files: File[] = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === 'file') {
        const _file = item.getAsFile();
        if (_file && _file.size > MAX_FILE_SIZE) {
          toast('O tamanho máximo de arquivo é 10MB', {
            toastId: 'error',
            position: 'top-center',
            type: 'warning',
            autoClose: 5000,
          });
          return;
        }

        _files.push(item.getAsFile()!);
      }
    }

    if (_files.length) {
      setAttachments(p => [...p, ..._files]);
      e.preventDefault();
    }
  };

  const recAudio = () => {
    // if (record.current.isRecording() || record.current.isPaused()) {
    //     record.current.stopRecording()
    //     setRecording(false)
    //     return
    // }

    cancelled.current = false;
    setRecording(true);
    // reset the wavesurfer instance

    // get selected device
    RecordPlugin.getAvailableAudioDevices().then(devices => {
      if (devices?.length) {
        const deviceId = devices[0].deviceId;

        record.current.startRecording({ deviceId }).then(() => {
          console.log('start recording');
        });
      }

      // devices.forEach((device) => {
      //   const option = document.createElement('option')
      //   option.value = device.deviceId
      //   option.text = device.label || device.deviceId
      //   micSelect.appendChild(option)
      // })
    });
  };

  const cancelRecording = () => {
    cancelled.current = true;
    record.current.stopRecording();
    setRecording(false);
  };

  const sendAudio = () => {
    record.current.stopRecording();
  };

  const dragTimerRef = useRef(null);
  const dragOverEventListener = (e: DragEvent) => {
    const dt = e.dataTransfer;
    if (
      dt?.types &&
      (dt.types.indexOf
        ? dt.types.indexOf('Files') !== -1
        : dt.types.includes('Files'))
    ) {
      console.log('dragover');
      setShowAttachments(true);
      if (dragTimerRef.current) window.clearTimeout(dragTimerRef.current);
    }
  };

  useEffect(() => {
    window.addEventListener('dragover', dragOverEventListener);
    return () => {
      window.removeEventListener('dragover', dragOverEventListener);
    };
  }, []);

  const [showAddTag, setShowAddTag] = React.useState<boolean>(false);
  const [selectedTagId, setSelectedTagId] = React.useState<string>('');

  return (
    <div className={styles[`footer-status-em_atendimento`]}>
      <Modal
        show={showAddTag}
        onClose={() => setShowAddTag(false)}
        onCancel={() => setShowAddTag(false)}
        onConfirm={() => {
          onChatAddTag(chatId, +selectedTagId);
          setShowAddTag(false);
        }}
        title="Adicionar marcador ao chat"
        confirmProps={{
          label: 'Adicionar',
        }}
      >
        <VBox>
          <CampoSeletor
            options={optionsTags?.filter(
              (it: any) => !chatTags?.includes(it.nome),
            )}
            value={selectedTagId?.toString()}
            onChange={(value: string) => setSelectedTagId(value!)}
            placeholder="Selecione um marcador"
          />
          <AlertBox variant="info">
            O marcador será adicionado permanentemente ao chat e ficará visível
            aos outros atendentes.
          </AlertBox>
        </VBox>
      </Modal>

      <Modal
        hideControls
        show={!recording && showAtalhos}
        title="Atalhos de Mensagens"
        onClose={() => setShowAtalhos(false)}
        onCancel={() => setShowAtalhos(false)}
      >
        <AtalhosBox
          atalhosPessoais={atalhosPessoais}
          atalhosGlobais={atalhosGlobais}
          onSelectAtalho={m => {
            setMensagem(mensagem + m);
            campoMensagemRef.current?.focus();
            setShowAtalhos(false);
          }}
        />
      </Modal>

      <Modal
        ignoreOverlayClick
        hideControls
        show={showAttachments}
        title="Enviar anexos"
        onClose={() => setShowAttachments(false)}
      >
        <div style={{ height: '33vh', width: '100%' }} {...getRootProps()}>
          <input {...getInputProps()} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              border: '2px dashed blue',
              cursor: 'pointer',
            }}
          >
            {isDragActive ? (
              <p style={{ margin: 'auto auto' }}>Solte os arquivos aqui!</p>
            ) : (
              <div style={{ margin: 'auto auto' }}>
                Arraste os arquivos para cá ou clique para selecionar
              </div>
            )}
          </div>
        </div>
      </Modal>

      <form
        onSubmit={e => {
          e.preventDefault();
          onSend(mensagem, attachments);
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {attachments.map((it, idx) => (
            <ArquivoAnexo
              key={`file-${idx}`}
              onRemove={() =>
                setAttachments(attachments.filter((f, i) => i !== idx))
              }
              arquivo={it}
            />
          ))}
        </div>
        <HBox gap="8px">
          {recording ? (
            <>
              <div style={{ flex: 1 }}></div>
              <Botao
                variant="none-secondary"
                icon={mdiTrashCan}
                onClick={cancelRecording}
              />
              <div>{recordProgress}</div>
              <Icon
                path={mdiCircle}
                color="var(--tc-color-danger)"
                size={0.75}
                className={styles.blink}
              />
              <div ref={waveformRef} className={styles.waveform}></div>
              <Botao
                variant="none-primary"
                icon={mdiSendVariant}
                disabled={isSending}
                onClick={sendAudio}
              ></Botao>
            </>
          ) : (
            <>
              <Botao
                noArrow
                variant="none-primary"
                icon={mdiTagPlus}
                disabled={isSending}
                position="top-left"
                onClick={() => setShowAddTag(true)}
              />
              <Botao
                noArrow
                variant="none-primary"
                icon={mdiMessageBookmark}
                disabled={isSending}
                position="top-left"
                onClick={() => setShowAtalhos(true)}
              />
              <textarea
                id="mensagem"
                ref={campoMensagemRef}
                className='rr-block'
                style={{
                  border: 'none',
                  outline: 'none',
                  resize: 'none',
                  width: '100%',
                }}
                placeholder="Digite sua mensagem. Shift+Enter para nova linha. Ctrl+1-9 para atalhos."
                value={mensagem}
                onChange={e => setMensagem(e.target.value)}
                disabled={isSending}
                rows={mensagem.split('\n').length || 1}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    if (!e.shiftKey) {
                      e.preventDefault();
                      (e.target as HTMLInputElement).form?.dispatchEvent(
                        new Event('submit', {
                          bubbles: true,
                          cancelable: true,
                        }),
                      );
                    }
                  } else {
                    if (e.ctrlKey && e.key >= '1' && e.key <= '9') {
                      e.preventDefault();
                      e.stopPropagation();
                      const idx = parseInt(e.key) - 1;
                      const atalho = atalhosPessoais?.[idx];
                      if (atalho) {
                        setMensagem(mensagem + atalho.conteudo);
                      }
                    } else if (e.altKey && e.key >= '1' && e.key <= '9') {
                      e.preventDefault();
                      e.stopPropagation();
                      const idx = parseInt(e.key) - 1;
                      const atalho = atalhosGlobais?.[idx];
                      if (atalho) {
                        setMensagem(mensagem + atalho.conteudo);
                      }
                    } else {
                      e.stopPropagation();
                    }
                  }
                }}
                onPaste={handlePaste}
              />
              {!mensagem.length && (
                <Botao
                  variant="none-primary"
                  icon={mdiMicrophone}
                  disabled={isSending}
                  onClick={recAudio}
                ></Botao>
              )}
              <Botao
                variant="none-primary"
                icon={mdiAttachment}
                disabled={isSending}
                onClick={() => setShowAttachments(true)}
              ></Botao>
              {(mensagem.length > 0 || attachments.length > 0) && (
                <Botao
                  data-testid="btn-enviar"
                  type="submit"
                  variant="none-primary"
                  icon={mdiSendVariant}
                  disabled={isSending}
                ></Botao>
              )}
            </>
          )}
        </HBox>
      </form>
    </div>
  );
};
