import Botao from '@/components/Botao';
import CampoSeletor from '@/components/Formulario/CampoSeletor';
import HBox from '@/components/layout/HBox';
import { mdiPlus } from '@mdi/js';
import axios from 'axios';
import { useQuery } from 'react-query';

type CampoSeletorClienteProps = {
  value: number;
  onChange: (v: number) => void;
  onNovoCliente?: () => void;
  label: string;
  error: string | undefined;
};
export const CampoSeletorCliente = ({
  value,
  onChange,
  onNovoCliente,
  label,
  error,
}: CampoSeletorClienteProps) => {
  const q = useQuery('cliente/box', async () => {
    const result = await axios.get('/api/cliente/box');
    return result.data.result;
  });
  const options = q.data?.map((c: any) => ({ id: c.id, label: c.nome })) ?? [];

  return (
    <HBox style={{ alignItems: 'flex-end' }}>
      <CampoSeletor
        options={options}
        value={value?.toString()}
        onChange={v => onChange(+v!)}
        label={label}
        error={error}
      />
      {onNovoCliente && (
        <Botao
          variant="outline-primary"
          title="Novo Cliente"
          onClick={onNovoCliente}
          icon={mdiPlus}
        />
      )}
    </HBox>
  );
};
