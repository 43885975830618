import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "appointmentsList",
  label: "Listar horários",
  description: "Lista os horários disponíveis de uma agenda",
  category: "Agendas",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "number", name: "id_agenda", label: "ID agenda" },
      { type: "datetime", name: "data", label: "Data início" },
      { type: "number", name: "qtd_horarios", label: "Qtd. horários", defaultValue: 3 },
    ];
  },
  outputs() {
    return [
      { type: "list", name: "list", label: "Horários" },
      { type: "route", name: "route", label: "Saída fluxo" },
    ];
  },
  async resolveOutputs() {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: "Listar horários agenda", indent });

    const next = nextNodeId(nodeId, "route");
    if (next) {
      stack.push({ id: next, indent: indent });
    }
  },
};

export default instance;
