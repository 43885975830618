const themes = {
  common: {
    ports: {
      route: {
        color: "#f80",
      },
      string: {
        color: "#080",
      },
      number: {
        color: "#08f",
      },
      api: {
        color: "#088",
      },
      boolean: {
        color: "#f5a623",
      },
      list: {
        color: "#888",
      },
      datetime: {
        color: "#880",
      },
      object: {
        color: "#444",
      },
    },
    connections: {
      route: {
        color: "#f80",
      },
      string: {
        color: "#080",
      },
      number: {
        color: "#08f",
      },
      api: {
        color: "#088",
      },
      boolean: {
        color: "#f5a623",
      },
      list: {
        color: "#888",
      },
      datetime: {
        color: "#880",
      },
      object: {
        color: "#444",
      },
    },
    nodes: {
      start: {
        title: {
          background: "#080",
        },
      },
      end: {
        title: {
          background: "purple",
          color: "white",
        },
      },
      endWon: {
        title: {
          background: "#3F3",
          color: "white",
        },
      },
      endLost: {
        title: {
          background: "#F33",
          color: "white",
        },
      },
      apiCallV2: {
        title: {
          background: "#008",
          color: "white",
        },
      },
    },
  },
};

export default themes;
