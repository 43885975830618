import Grid, { GridItem } from '../../components/layout/Grid';
import CampoDinheiro from '@/components/Formulario/CampoDinheiro';
import CampoMemo from '@/components/Formulario/CampoMemo';
import { CampoSeletorCliente } from './CampoSeletorCliente';
import { CampoSeletorUsuario } from './CampoSeletorUsuario';
import { CampoSeletorProduto } from './CampoSeletorProduto';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { CampoPersonalizacao } from '@/components/Formulario/CampoPersonalizacao';
import { format, parse } from 'date-fns';
import CampoSeletor from '@/components/Formulario/CampoSeletor';
import Modal from '@/components/layout/Modal';
import { FormCliente } from '../cliente/FormCliente';
import { useState } from 'react';
import { queryClient } from '@/queryClient';
import { toast } from 'react-toastify';

export const FormVenda = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  const qCustomize = useQuery('venda/meta', async () => {
    const res = await axios.get('/api/venda/meta', {
      withCredentials: true,
    });

    return res.data.result;
  });

  const qFunil = useQuery('funil', async () => {
    const res = await axios.get('/api/funil_venda/box', {
      withCredentials: true,
    });

    return res.data.result;
  });

  const qEtapas = useQuery('funil/etapas', async () => {
    const res = await axios.get(`/api/funil_venda/${value?.id_funil}/etapas`, {
      withCredentials: true,
    });

    return res.data.result;
  }, {
    enabled: !!value?.id_funil,
  });

  const sortedEtapas = qEtapas.data?.sort((a: any, b: any) => a.indice - b.indice);

  const [objCliente, setObjCliente] = useState<any>(null);
  const [objClienteFieldErrors, setObjClienteFieldErrors] = useState<Record<string, any>>({});

  const handleOpen = () => {
    setObjCliente({});
  }

  const handleClose = () => {
    setObjCliente(null);
  }

  const changeMutator = useMutation(
    async () => {
      if (!objCliente) return;

      setObjClienteFieldErrors({});
      if (objCliente.id) {
        return await axios.put(`/api/cliente/${objCliente.id}`, objCliente, {
          withCredentials: true,
        });
      } else {
        return await axios.post(`/api/cliente`, objCliente, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setObjClienteFieldErrors({});
        queryClient.invalidateQueries('cliente/box');
        queryClient.invalidateQueries('cliente');
        setObjCliente(null);

        toast(!objCliente.id ? 'Contato adicionado com sucesso' : 'Contato alterado com sucesso', {
          toastId: !objCliente.id ? 'add-success' : 'edit-success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setObjClienteFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: !objCliente.id ? 'add-error' : 'edit-error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const handleConfirm = () => {
    changeMutator.mutate();
  }

  return (
    <>
      <Modal
        title="Adicionar cliente"
        show={objCliente != null}
        onClose={handleClose}
        onConfirm={handleConfirm}
      >
        <FormCliente value={objCliente} onChange={setObjCliente} fieldErrors={objClienteFieldErrors} />
      </Modal>
      <Grid>
        <GridItem sm={12} md={8}>
          <h4>Geral</h4>
          <Grid>
            <div style={{ position: 'absolute', height: 0 }}>
              <input
                type="text"
                name="username"
                style={{
                  height: 0,
                  width: 0,
                  border: 'none',
                  outline: 'none',
                  margin: 0,
                  padding: 0,
                }}
              />
              <input
                type="password"
                name="password"
                style={{
                  height: 0,
                  width: 0,
                  border: 'none',
                  outline: 'none',
                  margin: 0,
                  padding: 0,
                }}
              />
            </div>

            <GridItem sm={12} md={6}>
              <CampoSeletorCliente
                value={value?.id_cliente ?? ''}
                onChange={v => onChange({ ...value, id_cliente: v })}
                label="Cliente"
                error={fieldErrors?.id_cliente}
                onNovoCliente={handleOpen}
              />
            </GridItem>

            <GridItem sm={12} md={6}>
              <CampoSeletorProduto
                value={value?.id_produto ?? ''}
                onChange={(v, product_value) =>
                  onChange({
                    ...value,
                    id_produto: v
                  })
                }
                label="Produto"
                error={fieldErrors?.id_produto}
              />
            </GridItem>

            <GridItem sm={12} md={9}>
              <CampoDinheiro
                value={value?.valor_total ?? ''}
                onChange={v => onChange({ ...value, valor_total: v })}
                label="Valor total"
                error={fieldErrors?.valor_total}
              />
            </GridItem>

            <GridItem sm={12} md={4}>
              <CampoSeletorUsuario
                value={value?.id_usuario_vendedor ?? ''}
                onChange={v => onChange({ ...value, id_usuario_vendedor: v })}
                label="Vendedor"
                error={fieldErrors?.id_usuario_vendedor}
              />
            </GridItem>

            <GridItem sm={12} md={4}>
              <CampoSeletor
                value={value?.id_funil}
                onChange={v => onChange({ ...value, id_funil: +v! })}
                label="Funil de vendas"
                error={fieldErrors?.id_funil}
                options={qFunil.data?.map((it: any) => ({ id: it.id, label: it.nome }))}
              />
            </GridItem>

            <GridItem sm={12} md={4}>
              <CampoSeletor
                value={value?.id_etapa}
                onChange={v => onChange({ ...value, id_etapa: +v! })}
                label="Etapa atual da venda"
                error={fieldErrors?.id_etapa}
                options={sortedEtapas?.map((it: any) => ({ id: it.id, label: it.nome }))}
              />
            </GridItem>

            {qCustomize.data?.length > 0 && (
              <GridItem sm={12}>
                <h4 style={{ marginTop: '12px' }}>Campos personalizados</h4>
                <Grid>
                  {qCustomize.data.map((it: any, idx: number) => (
                    <GridItem
                      sm={12}
                      md={
                        qCustomize.data?.length - 1 === idx && idx % 2 === 0 ? 12 : 6
                      }
                    >
                      <CampoPersonalizacao
                        label={it.rotulo}
                        type={it.tipo}
                        value={
                          value?.metadados?.[it.nome] != null
                            ? value?.metadados?.[it.nome]
                            : it.valor_padrao ?? ''
                        }
                        onChange={(v: any) => {
                          onChange({
                            ...value,
                            metadados: {
                              ...value.metadados,
                              [it.nome]: v,
                            },
                          });
                        }}
                        // options={it.opcoes?.map((it: any) => ({ id: it, label: it }))}
                        error={fieldErrors?.[`metadados.${it.nome}`]}
                      />
                    </GridItem>
                  ))}
                </Grid>
              </GridItem>
            )}

            <GridItem sm={12}>
              <CampoMemo
                value={value?.obs ?? ''}
                onChange={v => onChange({ ...value, obs: v })}
                label="Observações"
                error={fieldErrors?.obs}
              />
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem sm={12} md={4}>
          <div style={{ borderLeft: '1px solid #DDD', height: '100%', width: '100%', padding: '0 16px' }}>
            <h4>Histórico</h4>
            <table style={{ width: '100%' }}>
              <tr>
                <th>Evento</th>
                <th>Data</th>
              </tr>

              {value?.historico?.sort((a: any, b: any) => new Date(a.data_evento).getTime() - new Date(b.data_evento).getTime())?.map((it: any) => (
                <tr>
                  <td>{qEtapas.data?.find((etapa: any) => it.id_etapa === etapa.id)?.nome ?? it.id_etapa}</td>
                  <td>{it.data_evento ? format(new Date(it.data_evento), 'dd/MM/yyyy HH:mm') : ''}</td>
                </tr>
              ))}
            </table>
          </div>
        </GridItem>
      </Grid>
    </>
  );
};
