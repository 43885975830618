const re = /\{\{([^\}]+?)\}\}/g;

export const tpl = (s: string, inputs: Record<string, any>): string => {
  if (!s) return '';

  return s.replace(re, (match: string, p1: string) => {
    return inputs[p1] && typeof inputs[p1] === "string"
      ? inputs[p1]
      : `{{${p1}}}`;
  });
};
