import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "appointmentConfirm",
  label: "Confirmar",
  description: "Confirma um agendamento",
  category: "Agendas",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "number", name: "id_atividade", label: "ID atividade" },
    ];
  },
  outputs() {
    return [
      { type: "route", name: "route-true", label: "Sucesso" },
      { type: "route", name: "route-false", label: "Falha" }
    ];
  },
  async resolveOutputs() {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: "Confirmar agendamento", indent });

    const nextTrue = nextNodeId(nodeId, "route-true");
    if (nextTrue) {
      stack.push({ result: "(Se sucesso)", indent });
      stack.push({ id: nextTrue, indent: indent + 1 });
    }

    const nextFalse = nextNodeId(nodeId, "route-false");
    if (nextFalse) {
      stack.push({ result: "(Se falha)", indent });
      stack.push({ id: nextFalse, indent: indent + 1 });
    }

    stack.push({ result: "(Fim confirmação agendamento)", indent });
  },
};

export default instance;
