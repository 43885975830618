import { NodeType } from "@tera/shared/src/types/NodeType";

const instance: NodeType = {
  type: "step",
  label: "Etapa",
  description: "Etapa do funil de vendas",
  category: "Funil",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "string", name: "nome", label: "Nome", hidePort: true },
    ];
  },
  outputs() {
    return [
      { type: "route", name: "route", label: "Saída fluxo" },
    ];
  },
  async resolveOutputs(inputs) {
    return null;
  },

  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: `[Etapa] ${inputValues.nome}`, indent });
    const nextTrue = nextNodeId(nodeId, "route");
    if (nextTrue) {
      stack.push({ id: nextTrue, indent: indent + 1 });
    }
  },
};

export default instance;
