import { NodeType } from "@tera/shared/src/types/NodeType";

const instance: NodeType = {
  type: "endWon",
  label: "Ganho",
  description: "Etapa ganha",
  category: "Funil",
  inputs(inputValues) {
    const result = [
      { type: "route", name: "route", label: "Fluxo" },
      { type: "string", name: "nome", label: "Nome", hidePort: true },
    ];

    return result;
  },
  outputs() {
    return [];
  },
  async resolveOutputs() {
    return {};
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: `[GANHO] "${inputValues.nome}"`, indent });
  },
};

export default instance;
