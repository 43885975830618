import React, { useCallback, useEffect, useRef } from 'react';
import styles from './BotaoDropdown.module.css';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { BotaoVariants } from './Botao';
import btnStyles from './Botao.module.css';
import HBox from './layout/HBox';
import ReactDOM from 'react-dom';

type DropdownPosition =
  | 'top-right'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-left';

type BotaoDropdownProps = {
  variant?: BotaoVariants;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  type?: string;
  required?: boolean;
  options?: { id: string; label: React.ReactNode }[];
  disabled?: boolean;
  children?: React.ReactNode;
  text?: React.ReactNode;
  icon?: string;
  noArrow?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  position?: DropdownPosition | 'auto';
  [key: string]: any;
};

export default function BotaoDropdown({
  variant = 'primary',
  value,
  onChange,
  placeholder,
  type = 'text',
  required,
  options,
  disabled,
  children,
  text,
  icon,
  noArrow,
  onKeyDown,
  position = 'auto',
  ...props
}: Readonly<BotaoDropdownProps>) {
  const [open, setOpen] = React.useState<{
    isOpen: boolean;
    position: DropdownPosition;
  }>({ isOpen: false, position: 'bottom-right' });

  const handleContainerClick = (e: any) => {
    e.stopPropagation();

    if (disabled) return;

    toggleDropdown();
  };

  const toggleDropdown = useCallback(() => {
    console.log('toggle', open.isOpen);
    setOpen(prevState => ({ ...prevState, isOpen: !prevState.isOpen }));
  }, [open]);

  const openDropdown = () => {
    setOpen(prevState => ({ ...prevState, isOpen: true }));
  };

  const closeDropdown = () => {
    setOpen(prevState => ({ ...prevState, isOpen: false }));
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        e.target instanceof HTMLElement &&
        e.target.closest(`.${styles.container}`)
      ) {
        return;
      }
      closeDropdown();
    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open.isOpen) {
      if (optionsContainerRef.current) {
        console.log('position', position);
        if (position === 'auto') {
          const rect = optionsContainerRef.current.getBoundingClientRect();
          const availableSpaceBelow = window.innerHeight - rect.bottom;
          const availableSpaceAbove = rect.top;
          const shouldOpenAbove =
            availableSpaceBelow < rect.height &&
            availableSpaceAbove > availableSpaceBelow;

          const availableSpaceRight = window.innerWidth - rect.right;
          const availableSpaceLeft = rect.left;
          const shouldOpenLeft =
            availableSpaceRight < rect.width &&
            availableSpaceLeft > availableSpaceRight;

          const _position = shouldOpenAbove
            ? shouldOpenLeft
              ? 'top-left'
              : 'top-right'
            : shouldOpenLeft
              ? 'bottom-left'
              : 'bottom-right';

          console.log('position', _position);
          setOpen(prevState => ({ ...prevState, position: _position }));
        } else {
          setOpen(prevState => ({
            ...prevState,
            position: position as DropdownPosition,
          }));
        }
      }
    }
  }, [open.isOpen, position]);

  const [offset, setOffset] = React.useState({
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
  });
  useEffect(() => {
    const adjust = () => {
      if (open.isOpen) {
        const rect = containerRef.current!.getBoundingClientRect();
        const optRect = optionsContainerRef.current!.getBoundingClientRect();

        console.log(rect);
        console.log(
          window.innerHeight,
          optRect.height,
          rect.bottom + rect.height + window.scrollY,
        );

        setOffset({
          top: rect.bottom + window.scrollY,
          bottom: Math.min(
            window.innerHeight - optRect.height + window.scrollY,
            window.innerHeight - rect.bottom + rect.height + window.scrollY,
          ),
          left: rect.left + window.scrollX + 12,
          width: rect.width + 'px',
        });
      }
    };
    window.addEventListener('scroll', adjust);
    window.addEventListener('resize', adjust);
    adjust();

    return () => {
      window.removeEventListener('scroll', adjust);
      window.removeEventListener('resize', adjust);
    };
  }, [open]);

  return (
    <div>
      <div
        ref={containerRef}
        className={[
          btnStyles.button,
          styles.container,
          btnStyles[variant],
          disabled ? styles.disabled : null,
        ].join(' ')}
        tabIndex={disabled ? undefined : 0}
        onKeyDown={onKeyDown}
        onClick={handleContainerClick}
      >
        <HBox>
          {icon && <Icon path={icon} size="24px" />}
          {!!text && <div>{text}</div>}
          {!noArrow && (
            <div className={styles.arrow}>
              <Icon
                path={open.isOpen ? mdiChevronUp : mdiChevronDown}
                size={1}
              />
            </div>
          )}
        </HBox>

        {open.isOpen &&
          ReactDOM.createPortal(
            <div
              ref={optionsContainerRef}
              className={[
                styles.options,
                open.position == 'bottom-left' || open.position == 'bottom-right'
                  ? styles.optionsDown
                  : styles.optionsUp,
              ].join(' ')}
              style={{
                top: open.position == 'bottom-left' || open.position == 'bottom-right' ? offset.top : undefined,
                bottom: open.position == 'bottom-left' || open.position == 'bottom-right' ? undefined : offset.bottom,
                left: offset.left,
                width: offset.width,
              }}
            >
              {/* <CampoSeletorOptions
                value={options}
                onSelect={v => {
                  onChange?.(v);
                  setOpen(false);
                }}
                noSearch={noSearch}
                search={search}
                onChangeSearch={setSearch}
                selectedId={selectedId}
                onChangeSelectedId={setSelectedId}
              /> */}
              {children}
            </div>,
            document.querySelector('#root')!,
          )}
      </div>
    </div>
  );
}
