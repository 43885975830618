import React, { useEffect } from 'react';
import styles from './ConteudoAudio.module.css';
import WaveSurfer from 'wavesurfer.js';

import Botao from '../../../components/Botao';
import {
  mdiInformationBox,
  mdiMicrophoneMessage,
  mdiPause,
  mdiPlay,
} from '@mdi/js';
import VBox from '@/components/layout/VBox';
import HBox from '@/components/layout/HBox';
import { ChatMensagemDto } from '@tera/shared/src/dto/ChatMensagemDto';
import Icon from '@mdi/react';
import { Tooltip } from '@/components/layout/Tooltip';

type ConteudoAudioProps = {
  mime: string;
  value: ChatMensagemDto;
  onAudioPlaying?: (isPlaying: boolean) => void;
};

const ConteudoAudio = ({ mime, value, onAudioPlaying }: ConteudoAudioProps) => {
  const waveformRef = React.useRef<HTMLDivElement>(null);
  const wavesurfer = React.useRef<WaveSurfer | null>(null);

  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  useEffect(() => {
    onAudioPlaying?.(isPlaying);
  }, [isPlaying]);

  const handleClick = async () => {
    try {
      if (wavesurfer.current?.isPlaying()) {
        wavesurfer.current?.pause();
      } else {
        await wavesurfer.current?.play();
      }
    } catch (err) {
      console.warn('Error on play/pause', err);
    }
  };

  React.useEffect(() => {
    if (waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        url: `/api/assets${value.conteudo_ou_url}`,
        dragToSeek: true,
        normalize: true,
        waveColor: '#6f8090',
        progressColor: '#2f8719',
        cursorColor: '#2f8719',
        barWidth: 2,
        barGap: 4,
        barRadius: 2,
        height: 40,
        cursorWidth: 16,
      });
      wavesurfer.current.on('play', () => {
        setIsPlaying(true);
      });

      wavesurfer.current.on('pause', () => {
        setIsPlaying(false);
      });

      wavesurfer.current.on('finish', () => {
        setIsPlaying(false);
      });

      wavesurfer.current.on('destroy', () => {
        setIsPlaying(false);
      });
    }
    const wavesurferInstance = wavesurfer.current;
    return () => {
      wavesurferInstance?.destroy();
    };
  }, [value]);

  return (
    <div className={styles.container}>
      <VBox>
        <HBox>
          <div className={styles.controls}>
            <Botao
              variant="none-secondary"
              icon={isPlaying ? mdiPause : mdiPlay}
              onClick={handleClick}
            />
          </div>
          <div ref={waveformRef} className={styles.waveform}></div>
        </HBox>
        {value?.metadados?.transcricao && (
          <HBox
            stretch
            style={{ color: 'var(--tc-color-gray-500)', fontSize: '14px' }}
          >
            <Icon
              path={mdiMicrophoneMessage}
              size={0.8}
              color="var(--tc-color-gray-400)"
            />
            <span className='rr-mask'>{value.metadados.transcricao}</span>
            <Tooltip
              position="top"
              text="A transcrição do áudio foi gerada automaticamente e pode não ser exata."
            >
              <Icon
                path={mdiInformationBox}
                size={0.8}
                color="var(--tc-color-gray-400)"
              />
            </Tooltip>
          </HBox>
        )}
      </VBox>
    </div>
  );
};

export default ConteudoAudio;
