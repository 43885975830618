import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "appointmentSchedule",
  label: "Agendar",
  description: "Tenta realizar o agendamento no horário escolhido",
  category: "Agendas",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "number", name: "id_agenda", label: "ID agenda" },
      { type: "datetime", name: "data", label: "Data" },
    ];
  },
  outputs() {
    return [
      { type: "number", name: "id_atividade", label: "ID atividade" },
      { type: "route", name: "route-scheduled", label: "Agendado" },
      { type: "route", name: "route-unavailable", label: "Sem hor. disponível" },
      { type: "route", name: "route-fail", label: "Falha" }
    ];
  },
  async resolveOutputs() {
    return null;
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({ result: "Agendar", indent });

    const nextScheduled = nextNodeId(nodeId, "route-scheduled");
    if (nextScheduled) {
      stack.push({ result: "(Se agendado)", indent });
      stack.push({ id: nextScheduled, indent: indent + 1 });
    }

    const nextUnavailable = nextNodeId(nodeId, "route-unavailable");
    if (nextUnavailable) {
      stack.push({ result: "(Se sem horário disponível)", indent });
      stack.push({ id: nextUnavailable, indent: indent + 1 });
    }

    const nextFail = nextNodeId(nodeId, "route-fail");
    if (nextFail) {
      stack.push({ result: "(Se falha)", indent });
      stack.push({ id: nextFail, indent: indent + 1 });
    }

    stack.push({ result: "(Fim agendamento)", indent });
  },
};

export default instance;
