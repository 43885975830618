export const AgendaIntegracaoTipo = {
    GOOGLE: 'gogl',
    ZOHO: 'zoho',
}

export type AgendaIntegracaoTipoValue = typeof AgendaIntegracaoTipo[keyof typeof AgendaIntegracaoTipo];

export const AgendaIntegracaoTipoDescritor: Record<string, any> = {
    [AgendaIntegracaoTipo.GOOGLE]: 'Google Calendar',
    [AgendaIntegracaoTipo.ZOHO]: 'Zoho Calendar',
} as const

