import Titulo from '../../components/tipografia/Titulo';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import { mdiLoading, mdiMagnify } from '@mdi/js';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { FormPerfil } from '../../components/modelos/FormPerfil';
import { useAuth } from '../../lib/useAuth';
import { toast } from 'react-toastify';
import axios from 'axios';
import BotaoPerigoso from '@/components/BotaoPerigoso';

type PageProps = {};

const Page = ({ }: PageProps) => {
  const [model, setModel] = useState<any>(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [gcalSyncStatus, setGcalSyncStatus] = useState(false);
  const [zohoSyncStatus, setZohoSyncStatus] = useState(false);

  const { user } = useAuth();

  const goglStatus = useQuery({
    queryKey: ['agenda/gogl/status'],
    queryFn: async () => {
      const res = await axios.get(`/api/agenda/gogl/status`);
      return res.data;
    },
    onSuccess: (data) => {
      setGcalSyncStatus(data.result.token && data.result.webhook);
    }
  })

  const zohoStatus = useQuery({
    queryKey: ['agenda/zoho/status'],
    queryFn: async () => {
      const res = await axios.get(`/api/agenda/zoho/status`);
      return res.data;
    },
    onSuccess: (data) => {
      setZohoSyncStatus(data.result.token/* && data.result.webhook */);
    }
  })

  useEffect(() => {
    if (user) {
      setModel({ ...user });
    }
  }, [user]);

  const updateUserMutator = useMutation(
    async (data: any) => {
      setFieldErrors(prev => ({}));

      const {
        nome,
        /* email, */ senha,
        confirmar_senha,
        foto,
        foto_dados_crop,
        mostrar_notificacoes,
      } = data;

      const formData = new FormData();
      formData.append('nome', nome);
      //    formData.append('email', email)
      formData.append('foto_dados_crop', JSON.stringify(foto_dados_crop));
      formData.append('foto', foto);
      formData.append('mostrar_notificacoes', mostrar_notificacoes);

      if (senha?.length) {
        formData.append('senha', senha);
        formData.append('confirmar_senha', confirmar_senha);
      }

      const response = await fetch('/api/auth/me', {
        method: 'PUT',
        body: formData,
        credentials: 'same-origin',
      });
      const json = await response.json();
      return json;
    },
    {
      onSuccess: data => {
        if (data.ok) {
          const newModel = { ...(model ?? {}) };
          delete newModel.senha;
          delete newModel.confirmar_senha;
          setModel(newModel);

          toast('Perfil atualizado com sucesso!', {
            toastId: 'success',
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast('Ocorreu um erro ao tentar atualizar seu perfil.', {
            toastId: 'error',
            position: 'top-center',
            type: 'warning',
            autoClose: 5000,
          });
          setFieldErrors(data.fieldErrors);
        }
      },
      onSettled: data => {
        setTimeout(() => updateUserMutator.reset(), 3000);
      },
    },
  );

  const onSubmit = async (values: any) => {
    return updateUserMutator.mutateAsync(values);
  };

  return (
    <LayoutUsuario>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Meu perfil</Titulo>
            <Descricao>
              Altere seus dados aqui. Informe apenas os dados que queira
              compartilhar com a gente e seu time.
            </Descricao>
          </VBox>
        </HBox>

        {/* Filtros */}
        <HBox></HBox>



        {gcalSyncStatus && (
          <HBox style={{ justifyContent: 'flex-end' }}>
            <BotaoPerigoso
              variant='outline-danger'
              onClick={() => {
                axios.delete(`/api/agenda/gogl/token`).then(() => {
                  goglStatus.refetch();
                });
              }}
              confirmation={<>
                <p>As agendas não serão mais sincronizadas, e os eventos do Google agenda serão removidos.</p>
                <p>Tem certeza que deseja desconectar?</p>
              </>}
            >
              Desconectar do Google Agenda
            </BotaoPerigoso>
          </HBox>
        )}

        {zohoSyncStatus && (
          <HBox style={{ justifyContent: 'flex-end' }}>
            <BotaoPerigoso
              variant='outline-danger'
              onClick={() => {
                axios.delete(`/api/agenda/zoho/token`).then(() => {
                  zohoStatus.refetch();
                });
              }}
              confirmation={<>
                <p>As agendas não serão mais sincronizadas, e os eventos do Zoho agenda serão removidos.</p>
                <p>Tem certeza que deseja desconectar?</p>
              </>}
            >
              Desconectar do Zoho Agenda
            </BotaoPerigoso>
          </HBox>
        )}

        {/* Conteudo */}
        {model ? (
          <FormPerfil
            initialValues={model}
            onSubmit={onSubmit}
            fieldErrors={fieldErrors}
          />
        ) : (
          <p>
            <Icon path={mdiLoading} size={1} spin={1} />
            Carregando...
          </p>
        )}
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
