import Icon from '@mdi/react';
import styles from './Conversas.module.css';
import {
  mdiAccountAlert,
  mdiCheckAll,
  mdiCheckCircle,
  mdiHeadset,
  mdiLoading,
  mdiRobot,
  mdiTag,
} from '@mdi/js';
import React from 'react';
import Badge from '../../components/layout/Badge';
import ChatDto from '@tera/shared/src/dto/ChatDto';
import Markdown from './Markdown';
import TextoData from '../../components/tipografia/TextoData';
import InfiniteScroll from 'react-infinite-scroller';
import { BadgeMarcadores } from './BadgeMarcadores';
import { useQuery } from 'react-query';
import axios from 'axios';
import MarcadoresSm from '@/components/layout/MarcadoresSm';
import HBox from '@/components/layout/HBox';
import AlertasAtendimento from './AlertasAtendimento';

const iconeStatus: Record<string, React.ReactNode> = {
  chatbot: (
    <Icon path={mdiRobot} size="20px" color="var(--tc-color-gray-500)" />
  ),
  em_atendimento: (
    <Icon path={mdiHeadset} size="20px" color="var(--tc-color-gray-500)" />
  ),
  finalizado: (
    <Icon path={mdiCheckCircle} size="20px" color="var(--tc-color-gray-500)" />
  ),
};

type ConversasProps = {
  value: ChatDto[];
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  loadingChats: boolean;
  hasMoreChats: boolean;
  onLoadMoreChats: (page: number) => void;
  alertasAtendimento: any[];
  userInteracted: boolean;
  isPlayingAudio: boolean;
  onAtender: (chatId: number) => void;
};

const Conversas = ({
  value,
  loadingChats,
  hasMoreChats,
  selectedIndex,
  setSelectedIndex,
  onLoadMoreChats,
  alertasAtendimento,
  userInteracted,
  isPlayingAudio,
  onAtender,
}: ConversasProps) => {
  // const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const qTags = useQuery('tags', async () => {
    const result = await axios.get('/api/marcador/box');
    return result.data?.result;
  });

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    index: number,
  ) => {
    let hit = true;
    switch (e.code.toLowerCase()) {
      case 'space':
      case 'enter':
        setSelectedIndex(selectedIndex === index ? -1 : index);
        break;
      default:
        hit = false;
    }
    if (hit) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    setSelectedIndex(selectedIndex === index ? -1 : index);
  };

  // trata mensagens com o nome do atendente embutido
  const regexMensagemComAtendente = /^(\*[^\*]+\*\r?\n)?(.*)$/;
  const conteudo = (s: string) => {
    const m = regexMensagemComAtendente.exec(s);
    if (!m) return s.split('\n')[0];

    return m[2].split('\n')[0];
  };

  return (
    <div className={styles.container} data-testid="container-chats">
      <InfiniteScroll
        pageStart={0}
        loadMore={onLoadMoreChats}
        hasMore={hasMoreChats}
        useWindow={false}
      >
        <AlertasAtendimento
          value={alertasAtendimento}
          userInteracted={userInteracted}
          isPlayingAudio={isPlayingAudio}
          onAtender={onAtender}
        />
        {value.map((item: any, index) => (
          <div
            key={`conversa-${index}`}
            data-testid={`conversa`}
            className={[
              styles.conversa,
              selectedIndex === index ? styles.selected : null,
              item.__fila_accepted ? styles.accepted : null,
            ].join(' ')}
            tabIndex={0}
            onKeyDown={e => handleKeyDown(e, index)}
            onClick={e => handleClick(e, index)}
          >
            {/* <div>{JSON.stringify(item)}</div> */}
            <div className={[styles.coluna1, styles.nome].join(' ')}>
              <HBox style={{ alignItems: 'baseline' }} gap="3px">
                <span
                  className='rr-mask'
                  color={
                    item.cliente?.nome
                      ? 'undefined'
                      : 'var(--tc-color-gray-800)'
                  }
                >
                  {item.cliente?.nome ?? item.nome}
                </span>
                <MarcadoresSm ids={item.cliente?.marcadores} />
              </HBox>
            </div>
            <div className={[styles.coluna2, styles.data].join(' ')}>
              <TextoData tamanho="curto" value={item.ultmsg_data_criacao!} />
            </div>
            <div className={[styles.coluna1, styles.conteudo].join(' ')}>
              {item.ultmsg_tipo_mime === 'text/message' ? (
                <Markdown className='rr-mask' value={conteudo(item.ultmsg_conteudo_ou_url)} />
              ) : (
                '(mídia)'
              )}
            </div>
            <div className={[styles.coluna2, styles.estado].join(' ')}>
              {item.ultmsg_origem !== 'usuario' && iconeStatus[item.estado]}
              {item.ultmsg_origem === 'usuario' && (
                <div className={styles.naoRespondidoBg}>
                  <Icon
                    path={mdiAccountAlert}
                    size="20px"
                    color="var(--tc-color-danger-500)"
                  />
                </div>
              )}
            </div>
            <div className={styles.coluna}>
              {['chatbot'].includes(item.estado) && (
                <>
                  {item.usuario_responsavel_nome && (
                    <Badge variant="secondary" data-testid='badge-atendente'>
                      <Icon path={mdiHeadset} size="12px" />
                      {item.usuario_responsavel_nome}
                    </Badge>
                  )}
                  <Badge variant="info" data-testid="badge-chatbot">
                    <Icon path={mdiRobot} size="12px" />
                    Bot em ação
                  </Badge>
                </>
              )}
              {['atendimento'].includes(item.estado) && (
                <Badge variant="warning" data-testid='badge-atendente'>
                  <Icon path={mdiHeadset} size="12px" />
                  {item.usuario_responsavel_nome}
                </Badge>
              )}
              {['finalizado'].includes(item.estado) &&
                item.usuario_responsavel_nome && (
                  <Badge variant="secondary" data-testid='badge-finalizado'>
                    <Icon path={mdiHeadset} size="12px" />
                    {item.usuario_responsavel_nome}
                  </Badge>
                )}
              <BadgeMarcadores marcadores={item.marcadores} />
            </div>
          </div>
        ))}

        {loadingChats && (
          <div className={styles.loadStatus}>
            <Icon
              path={mdiLoading}
              size={1}
              color="var(--tc-color-gray-500)"
              spin={1}
            />
            <span>Obtendo lista...</span>
          </div>
        )}

        {!loadingChats && !hasMoreChats && (
          <div className={styles.loadStatus}>
            <Icon
              path={mdiCheckAll}
              size={1}
              color="var(--tc-color-gray-500)"
            />
            <span>Isso é tudo</span>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default Conversas;
