import { mdiAccountHeart, mdiAccountMultiple, mdiAccountMultipleCheck, mdiAccountStar, mdiCalendarCheck, mdiCheckCircle, mdiCreation, mdiFlag, mdiLightningBolt, mdiSpeedometer, mdiStarShooting } from "@mdi/js";
import { AchievementsKeys } from "@tera/shared/src/achievements/constants";

export const iconesConquistas: Record<string, string> = {
    [AchievementsKeys.FAST_TRIGGER]: mdiSpeedometer,
    [AchievementsKeys.IM_HERE]: mdiCalendarCheck,
    [AchievementsKeys.SOLUTION_EXPERT]: mdiCheckCircle,
    [AchievementsKeys.CHAT_EXPERT]: mdiAccountMultiple,
    [AchievementsKeys.CHAT_GURU]: mdiAccountMultipleCheck,
    [AchievementsKeys.PATRIOT_SAVIOR]: mdiFlag,
    [AchievementsKeys.CHAT_MARATHON]: mdiAccountMultiple,
    [AchievementsKeys.IMMEDIATE_ANSWER]: mdiLightningBolt,
    [AchievementsKeys.FIVE_STARS]: mdiStarShooting,
    [AchievementsKeys.GRADE_A_PLUS]: mdiAccountStar,
    [AchievementsKeys.CONSTELATION]: mdiCreation,
    [AchievementsKeys.POP_STAR]: mdiAccountHeart,
}