import ChatDto from '@tera/shared/src/dto/ChatDto';
import React, { useCallback, useEffect, useReducer } from 'react';
import { useRef } from 'react';
import { io } from 'socket.io-client';
import { ActionType, chatDispatchAction, chatReducer } from './chatReducer';
import { ChatMessagePayload } from '@tera/shared/src/types/ChatMessagePayload';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';

type useWebsocketProps = {
  userId?: number;
  userRole?: string;
  query: {
    search?: string;
    canalId?: number;
    chatStatus?: string;
    userId?: number;
  };
  onLoadingChats: (loading: boolean) => void;
  onChatsUpdated: (chats: ChatDto[]) => void;
  onConnected: () => void;
};

export type ChatState = {
  isConnected: boolean;
  chats: ChatDto[];
  selectedChatId: string | null;
  selectedChatClientId: string | null;
  selectedChatMessages: any;
  chatsCursor: string | null;
  hasMoreChats: boolean;
  hasMorePages: boolean;
  loadingChats: boolean;
  fila: any[];
  serverError: any;
  sendingMessage: boolean;
  params: Record<string, any>;
};

const chatInitialState: ChatState = {
  isConnected: false,
  chats: [],
  selectedChatId: null,
  selectedChatClientId: null,
  selectedChatMessages: null,
  chatsCursor: null,
  hasMoreChats: true,
  hasMorePages: true,
  loadingChats: false,
  fila: [],
  serverError: null,
  sendingMessage: false,
  params: {}
};

const useWebsocket = ({
  userId,
  userRole,
  query,
  onLoadingChats,
  onChatsUpdated,
  onConnected,
}: useWebsocketProps) => {
  const [wsState, setWsState] = React.useState<
    'disconnected' | 'connecting' | 'connected' | 'error'
  >('connecting');
  const [chatState, chatDispatch] = useReducer(chatReducer, chatInitialState);

  const wsInstance = useRef<any>(
    io('/wsinternal', {
      autoConnect: false,
      withCredentials: true,
      transports: ['websocket'],
    }),
  );

  const socket = wsInstance.current;

  const handleConnect = useCallback(() => {
    setWsState('connected');
    console.log('[WS] connected');

    console.log('reload by connect');
    onConnected();
  }, [onLoadingChats, socket]);

  const handleDisconnect = useCallback(() => {
    setWsState('disconnected');
    console.log('[WS] disconnected');
  }, []);

  const handleReconnectionAttempt = useCallback(() => {
    setWsState('connecting');
    console.log('[WS] attempting to reconnect');
  }, []);

  const handleReconnect = useCallback(() => {
    setWsState('connected');
    console.log('[WS] reconnected');
  }, []);

  const handleConnectError = useCallback((err: any) => {
    setWsState('error');
    console.log('[WS] error', err);
  }, []);

  const handleParams = useCallback((params: any) => {
    console.log('params', params);

    const { filas, ...rest } = params;

    chatDispatch({
      type: chatDispatchAction.SET_PARAMS as ActionType,
      payload: rest,
    });

    if (params?.filas) {
      chatDispatch({
        type: chatDispatchAction.SET_FILA as ActionType,
        payload: filas,
      });
    }
  }, [])

  const handleChats = useCallback(
    (data: any) => {
      if (!data) return;

      const { items, cursor, prevCursor } = data;
      chatDispatch({
        type: chatDispatchAction.SET_CHATS_CURSOR as ActionType,
        payload: cursor,
      });
      chatDispatch({
        type: chatDispatchAction.SET_CHATS_LIST_CURSOR as ActionType,
        payload: { items, prevCursor },
      });
      chatDispatch({
        type: chatDispatchAction.SET_HAS_MORE_CHATS as ActionType,
        payload: cursor != null,
      });
      chatDispatch({
        type: chatDispatchAction.SET_LOADING_CHATS as ActionType,
        payload: false,
      });

      console.log('SET_LOADING_CHATS FALSE');
    },
    [chatDispatch],
  );

  const handleChat = useCallback(
    (data: any) => {
      chatDispatch({
        type: chatDispatchAction.UPSERT_CHAT as ActionType,
        payload: { chat: { ...data, __fila_accepted: data.estado == 'atendimento' && data.usuario_responsavel_id == userId }, move: true },
      });
    },
    [chatDispatch],
  );

  const handleChatRemove = useCallback(
    (data: any) => {
      chatDispatch({
        type: chatDispatchAction.REMOVE_CHAT as ActionType,
        payload: data,
      });
    },
    [chatDispatch],
  );

  const handleChatMessageSent = useCallback(
    (/* data: any */) => {
      chatDispatch({
        type: chatDispatchAction.SET_SENDING_MESSAGE as ActionType,
        payload: false,
      });
    },
    [chatDispatch],
  );

  const handleChatMessageError = useCallback(
    (data: any) => {
      chatDispatch({
        type: chatDispatchAction.SET_SENDING_MESSAGE as ActionType,
        payload: false,
      });
      console.log('chat.message.error', data);
    },
    [chatDispatch],
  );

  const handleChatMessage = useCallback(
    (data: any) => {
      chatDispatch({
        type: chatDispatchAction.SET_SENDING_MESSAGE as ActionType,
        payload: false,
      });

      console.log('chat.message', data, chatState);

      const chat = data.chat ?? {};

      // const canalId = chat.canal?.id || chat.canal_id;
      // if (query.canalId && query.canalId !== canalId) {
      //   return;
      // }

      chatDispatch({
        type: chatDispatchAction.ADD_CHAT_MESSAGE as ActionType,
        payload: {
          chat,
          id: data.id,
          canal: data.canal,
          origem: data.origem,
          conteudo_ou_url: data.conteudo_ou_url,
          tipo_mime: data.tipo_mime,
          data_criacao: data.data_criacao,
          metadados: data.metadados,
          estado_atual: data.estado_atual,
        },
      });

      if (data.origem === 'sistema') {
        chatDispatch({
          type: chatDispatchAction.UPDATE_CHAT as ActionType,
          payload: {
            chat: {
              ...chat,
              __fila_accepted: chat.estado == 'atendimento' && chat.usuario_responsavel_id == userId
            },
            move: true,
          },
        });
      } else {
        chatDispatch({
          type: chatDispatchAction.UPDATE_CHAT as ActionType,
          payload: {
            chat: {
              ...chat,
              ultmsg_conteudo_ou_url: data.conteudo_ou_url,
              ultmsg_data_criacao: data.data_criacao,
              ultmsg_tipo_mime: data.tipo_mime,
              ultmsg_origem: data.origem,
            },
            move: true,
          },
        });
      }
    },
    [query.canalId, chatDispatch, chatState.chats, userId],
  );

  const handleChatMessageStatus = useCallback(
    (data: any) => {
      chatDispatch({
        type: chatDispatchAction.UPDATE_CHAT_MESSAGE as ActionType,
        payload: data,
      });
    },
    [chatDispatch],
  );

  const handleChatMessageReaction = useCallback(
    (data: any) => {
      chatDispatch({
        type: chatDispatchAction.UPDATE_CHAT_MESSAGE as ActionType,
        payload: data,
      });
    },
    [chatDispatch],
  );

  const handleChatMessagesReload = useCallback((data: any) => {
    chatDispatch({
      type: chatDispatchAction.SET_HAS_MORE_PAGES as ActionType,
      payload: data.length >= 50,
    });
    chatDispatch({
      type: chatDispatchAction.SET_CHAT_MESSAGES as ActionType,
      payload: data,
    });

    console.log('done fetching reload. Has more pages?', data.length >= 50);

    chatDispatch({
      type: chatDispatchAction.LOADING_MESSAGES as ActionType,
      payload: false,
    });
  }, []);

  const handleChatMessages = useCallback((data: any) => {
    chatDispatch({
      type: chatDispatchAction.SET_HAS_MORE_PAGES as ActionType,
      payload: data.length >= 50,
    });
    chatDispatch({
      type: chatDispatchAction.ADD_CHAT_MESSAGES as ActionType,
      payload: data,
    });

    console.log('done fetching. Has more pages?', data.length >= 50);
    chatDispatch({
      type: chatDispatchAction.LOADING_MESSAGES as ActionType,
      payload: false,
    });
  }, []);

  const handleFila = useCallback(
    (data: any) => {
      chatDispatch({
        type: chatDispatchAction.SET_FILA as ActionType,
        payload: data.list,
      });
    },
    [chatDispatch],
  );

  const handleFilaChamada = useCallback(
    (data: any) => {
      console.log('!!! fila.chamada !!!', data);
      chatDispatch({
        type: chatDispatchAction.ADD_FILA as ActionType,
        payload: data,
      });
    },
    [chatDispatch],
  );

  const handleFilaChamadaFim = useCallback(
    (data: any) => {
      const { chat_id, canal_cliente_id, usuario_responsavel_id } = data;

      if (
        userId &&
        data.reason === 'accepted' &&
        usuario_responsavel_id === userId
      ) {
        // fui eu! Colorir o chat
        chatDispatch({
          type: chatDispatchAction.UPSERT_CHAT as ActionType,
          payload: {
            chat: {
              id: chat_id,
              canal_cliente_id,
              __fila_accepted: true,
            },
            move: true,
          },
        });

        if (!chatState.selectedChatClientId) {
          chatDispatch({
            type: chatDispatchAction.SET_SELECTED_CHAT_ID as ActionType,
            payload: {
              id: chat_id,
              canal_cliente_id,
            },
          });
          socket.emit('chat.join', {
            chat_id,
            canal_cliente_id,
          });
        }
      } else {
        if (data.reason === 'accepted') {
          // não fui eu... notificar...
          toast('A solicitação foi aceita por outro atendente', {
            toastId: 'fila_chamada_fim_already_accepted',
            position: 'top-center',
            type: 'info',
            autoClose: 5000,
          });
        }
      }

      console.log('[=(] fila.chamada.fim [=(]', data);
      chatDispatch({
        type: chatDispatchAction.REMOVE_FILA as ActionType,
        payload: data,
      });
    },
    [chatDispatch],
  );

  useEffect(() => {
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);
    socket.on('reconnection_attempt', handleReconnectionAttempt);
    socket.on('reconnect', handleReconnect);
    socket.on('connect_error', handleConnectError);
    socket.on('chats', handleChats);
    socket.on('chat', handleChat);
    socket.on('chat.remove', handleChatRemove);
    socket.on('chat.message', handleChatMessage);
    socket.on('chat.message.sent', handleChatMessageSent);
    socket.on('chat.message.error', handleChatMessageError);
    socket.on('chat.message.status', handleChatMessageStatus);
    socket.on('chat.message.reaction', handleChatMessageReaction);
    socket.on('chat.messages.reload', handleChatMessagesReload);
    socket.on('chat.messages', handleChatMessages);

    socket.on('fila', handleFila);
    socket.on('fila.chamada', handleFilaChamada);
    socket.on('fila.chamada.fim', handleFilaChamadaFim);
    socket.on('params', handleParams);

    return () => {
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
      socket.off('reconnection_attempt', handleReconnectionAttempt);
      socket.off('reconnect', handleReconnect);
      socket.off('connect_error', handleConnectError);
      socket.off('chats', handleChats);
      socket.off('chat', handleChat);
      socket.off('chat.remove', handleChatRemove);
      socket.off('chat.message', handleChatMessage);
      socket.off('chat.message.sent', handleChatMessageSent);
      socket.off('chat.message.error', handleChatMessageError);
      socket.off('chat.message.status', handleChatMessageStatus);
      socket.off('chat.message.reaction', handleChatMessageReaction);
      socket.off('chat.messages.reload', handleChatMessagesReload);
      socket.off('chat.messages', handleChatMessages);

      socket.off('fila', handleFila);
      socket.off('fila.chamada', handleFilaChamada);
      socket.off('fila.chamada.fim', handleFilaChamadaFim);

      socket.off('params', handleParams);
    };
  }, [
    handleConnect,
    handleDisconnect,
    handleReconnectionAttempt,
    handleReconnect,
    handleConnectError,
    handleChats,
    handleChat,
    handleChatRemove,
    handleChatMessage,
    handleChatMessageSent,
    handleChatMessageError,
    handleChatMessageStatus,
    handleChatMessageReaction,
    handleChatMessagesReload,
    handleChatMessages,
    handleFila,
    handleFilaChamada,
    handleFilaChamadaFim,
    handleParams,
    socket,
  ]);

  const chatAddTag = useCallback(
    (chatId: number, tagId: number) => {
      socket.emit('chat.tag.add', { chat_id: chatId, tag_id: tagId });
    },
    [socket],
  );

  const reloadChat = useCallback(
    (chatId: number) => {
      if (!socket.connected) return;

      socket.emit('chat.reload', {
        chat_id: chatId,
        canal_cliente_id: chatState.selectedChatClientId,
      });
    },
    [socket, chatState.selectedChatClientId],
  );

  const reloadChats = useCallback(
    (options: any = null, force = false) => {
      if (!socket.connected) return;

      if (!chatState.loadingChats || force === true) {
        console.log('reloadChats emit before', chatState.loadingChats, force);
        chatDispatch({
          type: chatDispatchAction.SET_LOADING_CHATS as ActionType,
          payload: true,
        });
        console.log('reloadChats emit after', chatState.loadingChats, force);

        console.log('connected?', socket.connected);
        socket.emit('chats.reload', options);

        console.log('reloadChats done');
      }
    },
    [chatState.loadingChats, socket],
  );

  const loadMoreChats = (page: number) => {
    console.log('loadMoreChats');
    if (chatState.loadingChats || !chatState.hasMoreChats) return;

    console.log('reload by loadMoreChats', page);
    reloadChats(
      {
        pageParams: chatState.chatsCursor,
        search: query.search,
        chatStatus: query.chatStatus,
        canalId: query.canalId,
        userId: query.userId,
      },
      true,
    );
  };

  const selectChat = useCallback(
    (chat: ChatDto) => {
      if (!chat || chatState.selectedChatId == chat?.id) {
        chatDispatch({
          type: chatDispatchAction.SET_CHAT_MESSAGES as ActionType,
          payload: null,
        });
        return chatDispatch({
          type: chatDispatchAction.SET_SELECTED_CHAT_ID as ActionType,
          payload: null,
        });
      }
      chatDispatch({
        type: chatDispatchAction.SET_SELECTED_CHAT_ID as ActionType,
        payload: {
          chat_id: chat.id,
          canal_cliente_id: chat.canal_cliente_id,
        },
      });
      chatDispatch({
        type: chatDispatchAction.UPSERT_CHAT as ActionType,
        payload: {
          chat: {
            id: chat.id,
            canal_cliente_id: chat.canal_cliente_id,
            __fila_accepted: false,
          },
          move: false,
        },
      });
      socket.emit('chat.join', {
        chat_id: chat?.id ?? null,
        canal_cliente_id: chat?.canal_cliente_id ?? null,
      });
    },
    [chatState.selectedChatId, socket],
  );

  const sendMessage = useCallback(
    (message: ChatMessagePayload) => {
      chatDispatch({
        type: chatDispatchAction.SET_SENDING_MESSAGE as ActionType,
        payload: true,
      });
      socket.emit('chat.message.send', message);
    },
    [socket, chatDispatch],
  );

  const atenderFila = useCallback(
    (chatId: number) => {
      socket.emit('fila.atender', { chat_id: chatId });
    },
    [socket],
  );

  const sendNotification = (
    chatId: number,
    canal_cliente_id: string,
    mensagemProgramadaId: number,
  ) => {
    const msg = {
      chat_id: chatId,
      canal_cliente_id: canal_cliente_id,
      mensagem_programada_id: mensagemProgramadaId,
    };

    chatDispatch({
      type: chatDispatchAction.SET_SENDING_MESSAGE as ActionType,
      payload: true,
    });
    wsInstance.current?.emit('chat.message.notification', msg);
  };

  const sendAudio = (
    canal_cliente_id: string,
    canal_id: number,
    audio: Blob,
  ) => {
    const msg: ChatMessagePayload = {
      canal_cliente_id,
      canal_id,
      attachments: [
        {
          name: `audio-${nanoid()}.ogg`,
          type: 'audio/ogg',
          size: audio.size,
          buffer: audio,
        },
      ],
      __trace: 18,
    };

    chatDispatch({
      type: chatDispatchAction.SET_SENDING_MESSAGE as ActionType,
      payload: true,
    });
    wsInstance.current?.emit('chat.message.send', msg);
  };

  const loadMore = (dt: string) => {
    chatDispatch({
      type: chatDispatchAction.LOADING_MESSAGES as ActionType,
      payload: true,
    });

    wsInstance.current?.emit('chat.fetch', {
      chat_id: chatState.selectedChatId ?? null,
      canal_cliente_id: chatState.selectedChatClientId ?? null,
      data_criacao: dt,
    });
  };

  return {
    socket,
    wsState,
    chatState,
    reloadChat,
    chatAddTag,
    reloadChats,
    selectChat,
    loadMoreChats,
    sendMessage,
    sendNotification,
    atenderFila,
    sendAudio,
    loadMore,
  };
};

export default useWebsocket;
