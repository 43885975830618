import showdown from 'showdown';
import DOMPurify from 'dompurify';

const Markdown = ({ value, className }: { value: string, className?: string }) => {
  if (!value) {
    return null;
  }
  const converter = new showdown.Converter({
    simpleLineBreaks: true,
  });
  const mdHtml = converter.makeHtml(value);
  const purified = DOMPurify.sanitize(mdHtml);

  return <div className={className} dangerouslySetInnerHTML={{ __html: purified }} />;
};

export default Markdown;
