import { ConteudoTexto } from './ConteudoTexto';
import { ConteudoImagem } from './ConteudoImagem';
import ConteudoMidia from './ConteudoMidia';
import ConteudoVideo from './ConteudoVideo';
import ConteudoAudio from './ConteudoAudio';
import { ChatMensagemDto } from '@tera/shared/src/dto/ChatMensagemDto';
import Botao from '@/components/Botao';
import { mdiAccountMinus, mdiAccountRemove, mdiEye, mdiMenu, mdiNewBox, mdiOpenInNew } from '@mdi/js';
import Modal from '@/components/layout/Modal';
import { useState } from 'react';
import { useAuth } from '@/lib/useAuth';
import CampoCheckbox from '@/components/Formulario/CampoCheckbox';
import Icon from '@mdi/react';
import HBox from '@/components/layout/HBox';
import { useMutation } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';

export const Conteudo = ({
  mime,
  value,
  onBlockContact,
  onAudioPlaying,
}: {
  mime: string;
  value: ChatMensagemDto;
  onBlockContact?: () => void;
  onAudioPlaying?: (isPlaying: boolean) => void;
}) => {
  const { user } = useAuth();
  const [showViewWarningModal, setShowViewWarningModal] = useState(false);
  const [userConfirmed, setUserConfirmed] = useState(false);

  const blockContactMutator = useMutation(async ({ chatId, msgId }: { chatId: number, msgId: number }) => {
    const res = await axios.post(`/api/chat/${chatId}/bloquear_contato`, {
      mensagemId: msgId,
    });

    return res.data.ok;
  }, {
    onSuccess: () => {
      toast('Contato bloqueado com sucesso', {
        toastId: 'block-success',
        position: 'top-center',
        type: 'success',
        autoClose: 5000,
      });
      onBlockContact?.();
      setShowViewWarningModal(false);
    },
    onError: (err: any) => {
      toast(err.response?.data?.msg ?? 'Erro desconhecido', {
        toastId: 'block-error',
        position: 'top-center',
        type: 'warning',
        autoClose: 5000,
      });
    },
  })

  const logViewMutator = useMutation(async ({ chatId, msgId }: { chatId: number, msgId: number }) => {
    const res = await axios.post(`/api/chat/${chatId}/registrar_visualizacao`, {
      mensagemId: msgId,
    });

    return res.data.ok;
  })

  const handleViewContent = () => {
    logViewMutator.mutate({ chatId: value.chat?.id ?? value.chat_id, msgId: value.id! });

    window.open(`/api/assets${value.conteudo_ou_url}`, '_blank')
  }

  const handleBlockContact = () => {
    blockContactMutator.mutate({ chatId: value.chat?.id ?? value.chat_id, msgId: value.id! });
  }

  if (value?.metadados?.nsfwStatus == 'queued') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '8rem',
          width: '250px',
          backgroundColor: 'var(--tc-color-gray-100)',
          borderRadius: '8px',
        }}
      >
        {/* <img src="/look-msg-model-min.png" style={{ height: '5rem', opacity: .7 }} />
        <div style={{ color: 'var(--tc-color-gray-300', fontStyle: 'italic' }}>Analisando arquivo...</div> */}
      </div>
    )
  } else if (value?.metadados?.nsfwStatus == 'rejected') {
    return (
      <>
        <Modal
          show={showViewWarningModal}
          onClose={() => setShowViewWarningModal(false)}
          title="Conteúdo impróprio"
          hideControls
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src="/content-rejected-min.png" style={{ height: '7rem', opacity: .7 }} />


            <div style={{
              width: '100%',
              backgroundColor: 'var(--tc-color-gray-100)',
              borderRadius: '1rem',
              marginTop: '1rem',
              border: '1px solid var(--tc-color-gray-300)',
              padding: '2px'
            }}>
              <div style={{
                height: '1rem',
                width: `${value.metadados.nsfwScore * 100}%`,
                backgroundColor: 'var(--tc-color-warning)',
                borderRadius: '.5rem',
              }}
              />
            </div>

            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '.5rem' }}>
              <div style={{ color: 'var(--tc-color-success)' }}>Seguro</div>
              <div style={{ color: 'var(--tc-color-danger)' }}>Impróprio</div>
            </div>

            {user.papel !== 'ADMIN' && (
              <>
                <div style={{ color: 'var(--tc-color-gray-400)', fontStyle: 'italic' }}>Este conteúdo foi marcado como impróprio.</div>
                <div style={{ color: 'var(--tc-color-gray-400)', fontStyle: 'italic' }}>Devido a análise, este conteúdo não pode ser visualizado para evitar te expor a material sensível.</div>
              </>
            )}

            {user.papel === 'ADMIN' && (
              <>
                <div style={{ color: 'var(--tc-color-gray-400)', fontStyle: 'italic' }}>Este conteúdo foi marcado como impróprio.</div>
                <CampoCheckbox value={userConfirmed} onChange={setUserConfirmed} text="Estou ciente dos riscos e assumo a responsabilidade. Quero analisar o conteúdo." />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '1rem 0' }}>
                  <Botao disabled={!userConfirmed} icon={mdiEye} variant='outline-warning' size='sm' onClick={handleViewContent}>
                    <HBox>
                      Visualizar
                      <Icon path={mdiOpenInNew} size={.8} color='var(--tc-color-warning)' />
                    </HBox>
                  </Botao>
                  <Botao disabled={!userConfirmed} icon={mdiAccountRemove} variant='outline-danger' size='sm' onClick={handleBlockContact}>Bloquear contato</Botao>
                </div>
                <div style={{ color: 'var(--tc-color-warning-700)', fontStyle: 'italic', fontWeight: 'bolder' }}>Atenção: Visualizar pode te expor a material sensível.</div>
                <p style={{ color: 'var(--tc-color-gray-400)', fontStyle: 'italic', fontSize: '.8rem' }}>Bloquear o contato finalizará atendimentos em aberto deste contato.</p>
              </>
            )}
          </div>
        </Modal >

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '12rem',
            width: '100%',
          }}
        >
          <img src="/content-rejected-min.png" style={{ height: '7rem', opacity: .7 }} />
          <div style={{ color: 'var(--tc-color-gray-400', fontStyle: 'italic' }}>Arquivo com conteúdo impróprio.</div>

          {user.papel === 'ADMIN' && (
            <Botao icon={mdiMenu} variant='outline-secondary' size='sm' onClick={() => setShowViewWarningModal(true)}>Ações</Botao>
          )}
        </div>
      </>
    )
  }


  switch (mime) {
    case 'text/message':
      return <ConteudoTexto value={value.conteudo_ou_url} />;
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
    case 'image/gif':
    case 'image/webp':
    case 'image/svg+xml':
      return <ConteudoImagem value={value.conteudo_ou_url} />;
    case 'video/mp4':
      return <ConteudoVideo mime={mime} value={value.conteudo_ou_url} />;
    case 'audio/ogg':
    case 'audio/mp3':
    case 'audio/mpeg':
      return <ConteudoAudio mime={mime} value={value} onAudioPlaying={onAudioPlaying} />;
    default:
      return <ConteudoMidia mime={mime} value={value.conteudo_ou_url} />;
  }
};
