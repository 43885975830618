import appointmentsList from './appointmentsList';
import appointmentSchedule from './appointmentSchedule';
import appointmentConfirm from './appointmentConfirm';
import appointmentCancel from './appointmentCancel';

export default {
  appointmentsList,
  appointmentSchedule,
  appointmentConfirm,
  appointmentCancel
}