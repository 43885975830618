import styles from './CampoData.module.css';

type CampoDataProps = {
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  error?: string;
  type?: 'date' | 'datetime-local' | 'time';
  instanceId?: string;
  [key: string]: any;
};

export default function CampoData({
  value,
  onChange,
  label,
  type = 'date',
  placeholder,
  required,
  prepend,
  append,
  error,
  instanceId,
  ...props
}: Readonly<CampoDataProps>) {
  return (
    <div style={{ width: '100%' }}>
      <label>{label}</label>
      <div
        className={[styles.container, error ? styles.error : null].join(' ')}
      >
        {prepend && <div className={styles.prepend}>{prepend}</div>}
        <input
          {...props}
          id={instanceId}
          type={type}
          value={value}
          onChange={e => onChange?.(e.target.value)}
          placeholder={placeholder}
          required={required}
        />
        {append && <div className={styles.append}>{append}</div>}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}
