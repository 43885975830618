import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "stringCompare",
  label: "Condicional (String)",
  description: "Compara duas cadeias de caracteres",
  category: "String",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "string", name: "string1", label: "valor 1" },
      {
        type: "stringOperator",
        name: "operator",
        label: "operador",
        hidePort: true,
      },
      { type: "string", name: "string2", label: "valor 2" },
    ];
  },
  outputs() {
    return [
      { type: "route", name: "route-true", label: "Verdadeiro" },
      { type: "route", name: "route-false", label: "Falso" },
    ];
  },
  async resolveOutputs(inputs) {
    const v1: string = inputs.string1?.toString()?.toLowerCase() ?? "";
    const v2: string = inputs.string2?.toString()?.toLowerCase() ?? "";

    const op = inputs.operator ?? "equals";

    switch (op) {
      case "equals":
        if (v1.localeCompare(v2) == 0) return { route: "route-true" };
        break;
      case "notEquals":
        if (v1.localeCompare(v2) != 0) return { route: "route-true" };
        break;
      case "contains":
        if (v1.includes(v2)) return { route: "route-true" };
        break;
      case "notContains":
        if (!v1.includes(v2)) return { route: "route-true" };
        break;
      case "startsWith":
        if (v1.startsWith(v2)) return { route: "route-true" };
        break;
      case "endsWith":
        if (v1.endsWith(v2)) return { route: "route-true" };
        break;
    }
    return { route: "route-false" };
  },
  transcript(nodeId, inputValues, indent, nextNodeId, stack) {
    stack.push({
      result: `[Condicional (String)]:  Operador 1: ${inputValues.value1}, Operando: ${inputValues.operator}, Operador 2: ${inputValues.value2}`,
      indent,
    });

    const nextTrue = nextNodeId(nodeId, "route-true");
    if (nextTrue) {
      stack.push({ result: `(SE verdadeiro)`, indent });
      stack.push({ id: nextTrue, indent: indent + 1 });
    }

    const nextFalse = nextNodeId(nodeId, "route-false");
    if (nextFalse) {
      stack.push({ result: `(SE falso)`, indent });
      stack.push({ id: nextFalse, indent: indent + 1 });
    }

    stack.push({ result: `(Fim SE)`, indent });
  },
};

export default instance;
