import Botao from '@/components/Botao';
import BotaoDropdown from '@/components/BotaoDropdown';
import BotaoPerigoso from '@/components/BotaoPerigoso';
import CampoSeletor, { CampoSeletorOptions } from '@/components/Formulario/CampoSeletor';
import HBox from '@/components/layout/HBox';
import VBox from '@/components/layout/VBox';
import { mdiArrowDecision, mdiArrowDecisionAuto, mdiArrowDown, mdiArrowLeft, mdiArrowLeftBold, mdiArrowRight, mdiArrowRightBold, mdiArrowUp, mdiDrag, mdiFormatListBulleted, mdiFormatListBulletedType, mdiFormatListChecks, mdiFormatListGroup, mdiPen, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import { VendaDto } from '@tera/shared/src/types/VendaDto';
import React, { CSSProperties } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { onChange } from 'react-toastify/dist/core/store';

type CardVendaProps = {
  venda: VendaDto;
  index: number;
  isFirst: boolean;
  isLast: boolean;
  transicoesAvancar: any[];
  transicoesVoltar: any[];
  disableControls?: boolean;
  onChange: (venda: VendaDto) => void;
  onEdit: (venda: VendaDto) => void;
  onDelete: (venda: VendaDto) => void;
};

const numFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const CardVenda = ({
  venda,
  transicoesAvancar,
  transicoesVoltar,
  index,
  isFirst,
  isLast,
  disableControls,
  onChange,
  onEdit,
  onDelete,
}: CardVendaProps) => {
  const [showControls, setShowControls] = React.useState(false);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleMouseEnter = () => setShowControls(true);
    const handleMouseLeave = () => setShowControls(false);

    const handleClick = (e: MouseEvent) => {
      console.log('click', e.target, showControls);
      setShowControls(!showControls);
    };

    const container = containerRef.current;
    const content = contentRef.current;

    if (content) content.addEventListener('click', handleClick);

    if (container) {
      container.addEventListener('mouseenter', handleMouseEnter);
      container.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (content) content.removeEventListener('click', handleClick);

      if (container) {
        container.removeEventListener('click', handleClick);
        container.removeEventListener('mouseenter', handleMouseEnter);
        container.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [showControls]);

  const handleEdit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onEdit?.(venda);
  };

  const handleDelete = () => {
    onDelete?.(venda);
  };

  const handleMoveUp = () => {
    onChange?.({ ...venda, prioridade_vendedor: venda.prioridade_vendedor - 1 });
  }

  const handleMoveDown = () => {
    onChange?.({ ...venda, prioridade_vendedor: venda.prioridade_vendedor + 1 });
  }

  return (
    <div
    >
      <div
        ref={containerRef}
        style={{ width: '100%', height: '100%', cursor: 'pointer', border: '1px solid var(--tc-color-gray-300)', padding: '8px 8px', borderRadius: '8px' }}
      >
        <HBox>
          <div ref={contentRef} style={{ flex: 1, cursor: 'pointer' }}>
            <VBox>
              <div>
                <span>{venda.cliente?.nome}</span>
                <span style={{ color: 'var(--tc-color-gray-500)', fontSize: '0.8em', fontStyle: 'italic' }}>
                  &nbsp;{venda.cliente?.empresa}
                </span>
              </div>
              <div>{numFormatter.format(venda.valor_total)}</div>
              <div style={{ width: '100%', color: 'var(--tc-color-gray-500)', fontSize: '0.8em', fontStyle: 'italic', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '1rem' }}>
                {venda.obs}
              </div>
            </VBox>
          </div>
          <VBox style={{ flex: 0 }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'space-between', opacity: showControls ? 1 : 0.5, transition: 'opacity 0.15s' }}>
              <HBox style={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'flex-end' }}>
                <div style={{ width: '32px', height: '32px' }}>
                  {!isFirst && <Botao variant='none-info' style={{ width: '100%' }} icon={mdiArrowUp} onClick={handleMoveUp}></Botao>}
                </div>
                <div style={{ width: '32px', height: '32px' }}>
                  {!isLast && <Botao variant='none-info' style={{ width: '100%' }} icon={mdiArrowDown} onClick={handleMoveDown}></Botao>}
                </div>
              </HBox>
              <HBox style={{ justifyContent: 'flex-end' }}>
                <BotaoDropdown
                  noArrow
                  disabled={disableControls}
                  variant="none-primary"
                  icon={mdiArrowDecision}
                  onClick={handleEdit}
                  title='Transições'
                >
                  <CampoSeletorOptions
                    noSearch
                    selectedId={null}
                    onChangeSelectedId={() => { }}
                    value={[
                      ...(transicoesVoltar?.map(t => ({
                        id: t.id,
                        label: (<HBox><Icon path={mdiArrowLeftBold} size={1} color='var(--tc-color-gray-400)' />{t.label}</HBox>)
                      })) ?? []),
                      ...(transicoesAvancar?.map(t => ({
                        id: t.id,
                        label: (<HBox><Icon path={mdiArrowRightBold} size={1} color='var(--tc-color-gray-400)' />{t.label}</HBox>)
                      })) ?? [])
                    ]}
                    onSelect={(v) => onChange({ ...venda, id_etapa: +v! })}
                    search=''
                    onChangeSearch={() => { }}
                  />
                </BotaoDropdown>
                <Botao
                  disabled={disableControls}
                  variant="none-primary"
                  icon={mdiPen}
                  onClick={handleEdit}
                ></Botao>
                <BotaoPerigoso
                  disabled={disableControls}
                  variant="none-danger"
                  icon={mdiTrashCan}
                  onClick={handleDelete}
                ></BotaoPerigoso>
              </HBox>
            </div>
          </VBox>
        </HBox>
      </div>
    </div>
  );
};
