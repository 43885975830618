import Grid, { GridItem } from '../../components/layout/Grid';
import CampoTexto from '@/components/Formulario/CampoTexto';
import Subtitulo from '@/components/tipografia/Subtitulo';
import React, { useEffect, useMemo } from 'react';
// @ts-ignore
import { NodeContainer, ThemeProvider } from 'flow-castro/dist/esm/index.js';
import string from '../../fluxoConfig/portRenderers/string';
import route from '../../fluxoConfig/portRenderers/route';

import start from './fluxo/nodes/start';
import step from './fluxo/nodes/step';
import endWon from './fluxo/nodes/end-won';
import endLost from './fluxo/nodes/end-lost';
import Skeleton from '@/components/layout/Skeleton';
import fluxoThemes from '@tera/shared/src/fluxoConfig/themes';
import CampoCheckbox from '@/components/Formulario/CampoCheckbox';
import CampoSeletor from '@/components/Formulario/CampoSeletor';

type FunilVendaEtapaDto = any

export const FormFunilVenda = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  const [initialState, setInitialState] = React.useState<any>();
  const [stepsToMigrate, setStepsToMigrate] = React.useState<any>();

  const currentSteps = value?.conteudo ? Object.keys(value.conteudo.nodes).filter(k => ['step', 'endWon', 'endLost'].includes(value.conteudo.nodes[k].type)) : [];

  useEffect(() => {
    if (!value?.conteudo) {
      onChange({
        ...value, conteudo: {
          nodes: {
            'start': {
              id: 'start',
              type: 'start',
              name: 'Início',
              position: { x: 100, y: 100 },
              values: {},
              connections: {
                inputs: [],
                outputs: [],
              },
              root: true,
            },
          },
        }
      });
    } else if (!initialState) {
      setInitialState(value.conteudo);
    } else {
      const initialSteps = Object.keys(initialState.nodes).filter(k => ['step', 'endWon', 'endLost'].includes(initialState.nodes[k].type));

      const removedSteps = initialSteps.filter(k => !currentSteps.includes(k));

      if (removedSteps.length > 0) {
        setStepsToMigrate(removedSteps);
      }
    }
  }, [value?.conteudo, initialState]);

  const portTypes = useMemo(() => ({
    string,
    route
  }), []);

  const nodeTypes = useMemo(() => ({
    start,
    step,
    endWon,
    endLost
  }), []);

  return (
    <Grid>
      <div style={{ position: 'absolute', height: 0 }}>
        <input
          type="text"
          name="username"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
        <input
          type="password"
          name="password"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
      </div>

      <GridItem sm={3}>
        <CampoCheckbox
          instanceId="padrao"
          value={value?.padrao ?? false}
          onChange={v => onChange({ ...value, padrao: v })}
          label="Padrão"
          error={fieldErrors?.padrao}
          text='Marcar como padrão'
        />
      </GridItem>

      <GridItem sm={9}>
        <CampoTexto
          instanceId='nome'
          value={value?.nome ?? ''}
          onChange={v => onChange({ ...value, nome: v })}
          label="Nome"
          error={fieldErrors?.Nome}
        />
      </GridItem>

      <GridItem sm={12}>
        <div data-testid="fluxo-funil" style={{ minWidth: '800px', height: '400px', position: 'relative' }}>
          <ThemeProvider themes={fluxoThemes} theme="light">
            {!value?.conteudo && <Skeleton format="box" />}
            {value?.conteudo && <NodeContainer
              portTypes={portTypes}
              nodeTypes={nodeTypes}
              initialState={value.conteudo}
              i18n={{
                'contextMenu.search': 'Pesquisar...',
                'contextMenu.add': '{nodeType}',
                'contextMenu.addComment.label': 'Adicionar comentário',
                'contextMenu.addComment.description':
                  'Adiciona um comentário ao fluxo',
                'contextMenu.comment.label': 'comentário',
                'contextMenu.removeThisNode': 'Remover este nó',
                'contextMenu.removeThisComment': 'Remover este comentário',
                'contextMenu.removeSelectedNodes': 'Remover nós selecionados',
                'contextMenu.cloneThisNode': 'Clonar este nó',
                'contextMenu.cloneThisComment': 'Clonar este comentário',
                'contextMenu.removeThisConnection': 'Remover esta conexão',
              }}
              onChangeState={(v: any) => {
                onChange({ ...value, conteudo: v });
              }}
            />}
          </ThemeProvider>
        </div>
      </GridItem>
      {stepsToMigrate?.length > 0 && (<GridItem sm={12}>
        <Subtitulo>Passos removidos</Subtitulo>
        <p>Será necessário indicar para qual etapa as vendas deverão ser migradas</p>

        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Passo</th>
              <th>Migrar para</th>
            </tr>
          </thead>
          <tbody>
            {stepsToMigrate.map((step: string) => (
              <tr>
                <td>
                  {JSON.stringify(initialState.nodes[step].values.nome, null, 2)}
                </td>
                <td>
                  <CampoSeletor
                    placeholder='Selecione a etapa'
                    value={value?.actions?.migrate?.[step]}
                    options={value?.conteudo?.nodes ? Object.values(value?.conteudo?.nodes).filter((it: any) => ['step', 'endWon', 'endLost'].includes(it.type)).map((it: any) => ({ id: it.id, label: it.values.nome })) : []}
                    onChange={(v) => {
                      onChange({ ...(value ?? {}), actions: { ...(value?.actions ?? {}), migrate: { ...(value?.actions?.migrate ?? {}), [step]: v } } });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </GridItem>)}
    </Grid >
  );
};
